import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from "react-helmet";
import './index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import ConfigurationService from '../src/services/configuration-service';



let siteTheme = new ConfigurationService().defineTheme();
let site = 'labmedica'
let siteAlternateName = 'LabMedica'
if(siteTheme==='hmi'){
	 site = 'hospimedica';
	 siteAlternateName='HospiMedica'
}
ReactDOM.render(
	<React.StrictMode>
		<Helmet>
			<script type="application/ld+json">{`
      {
		"@context": "https://schema.org",
		  "@type": "WebSite",
		  "name": "${siteAlternateName} International",
		  "alternateName": "${siteAlternateName} International",
		  "url": "www.${site}.com"
      }
    `}</script>
			<script type="application/ld+json">
				{`{
		
		"@context": "https://schema.org",
			"@type": "Organization",
			"url": "http://www.${site}.com",
			"logo": "https://${site}.com/i/mag_logo_lmi.jpg",
			"contactPoint":
			[
				{ "@type": "ContactPoint", "telephone": "+1-954-893-0003",   "areaServed": "US",         "contactType": "sales" },
				{ "@type": "ContactPoint", "telephone": "+44-1753-892-791",  "areaServed": "UK",         "contactType": "sales" },
				{ "@type": "ContactPoint", "telephone": "+49-9771-3528",     "areaServed": "DE",         "contactType": "sales" },
				{ "@type": "ContactPoint", "telephone": "+39-010-570-4948",  "areaServed": "IT",         "contactType": "sales" },
				{ "@type": "ContactPoint", "telephone": "+32-11-224-397",    "areaServed": ["FR", "BE"], "contactType": "sales" },
				{ "@type": "ContactPoint", "telephone": "+81-3-5691-3335",   "areaServed": "JP",         "contactType": "sales" },
				{ "@type": "ContactPoint", "telephone": "+86-755-837-53877", "areaServed": "CN",         "contactType": "sales" },
				{ "@type": "ContactPoint", "telephone": "+82-2-7200-121",    "areaServed": "KR",         "contactType": "sales" }
			]

			, "sameAs": ["https://google.com/+${siteAlternateName}_International"] 	
	}`}
			</script>

			<script>{`
				!function (f, b, e, v, n, t, s) {
					if (f.fbq) return; n = f.fbq = function () {
						n.callMethod ?
							n.callMethod.apply(n, arguments) : n.queue.push(arguments)
					};
					if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
					n.queue = []; t = b.createElement(e); t.async = !0;
					t.src = v; s = b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t, s)
				}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '697548141322284');
				fbq('track', 'PageView');
			
			`}
			</script>

		

		</Helmet>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
