import React from 'react';
import parse from 'html-react-parser';
import ServerApi from '../../services/server-api-service';

import './news-modal-style.css';
class NewsModal extends React.Component {
    serverApi = new ServerApi();
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        }
    }

    openModal = (event) => {
        event.preventDefault();
        this.setState({
            isVisible: true
        })
    }

    closeModal = () => {
        this.setState({
            isVisible: false
        })
    }

    componentWillMount() {
        let { id } = this.props.item;
        this.serverApi.getNewsData(id)
            .then((data) => {
                console.log('ALERT', data.text);
                this.setState({
                    data: data,
                    text: data.text.toString()
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }
    renderLinks() {
        let { data } = this.state;
        if (data !== undefined && data.relatedLinks !== undefined && data.relatedLinks.length > 0) {
            return (
                <div className="relatedLinks">
                    <br />
                    <strong>Related Links:</strong>
                    {data.relatedLinks.map((link, index) => {
                        return (
                            <div className="link">
                                <a href={link.url}>{link.text}</a>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }


    rendeModal() {
        let { isVisible, data } = this.state;
        if (isVisible && data !== undefined) {
            console.log(data.text);
            let textParsed = parse(data.text.toString())

            return (
                <div className="modalShadow" onClick={() => this.closeModal()}>
                    <div className="modalWindow" id="newsModal"
                        onClick={(e) => { e.stopPropagation(); }}
                    >
                        <span className="closeButton" onClick={this.closeModal}>X</span>
                        <div className="body">
                            <h2>{data.name}</h2>
                            <div>
                                <span>{data.postedby}</span><br />
                                <span>{data.date}</span>
                            </div>
                            <br />
                            <div className="image">
                                <img src={data.image}></img>
                                <div className="imageText">
                                    {data.imageText}
                                </div>
                            </div>
                            {/* {data.text.split("\r\n\r\n").map((i, key) => {
                                if (i.includes("\r\n")) {
                                    return (i.split("\r\n").map((i, key) => {
                                        return <p key={key}>{i}</p>;
                                    }))
                                }
                                return <p key={key}>{i}</p>;
                            })} */}
                            {parse(data.text)}
                            {/* {this.renderLinks()} */}

                        </div>
                    </div>
                </div>

            )
        }
        return null
    }

    render() {
        let { item } = this.props;
        let parsedSummaryAnonce_description = null;
        if (item.anonce_description) {
            parsedSummaryAnonce_description = parse(item.anonce_description);
        }
        return (
            <>
                <div className="newsItem">
                    <div className="newsImage">
                        <img src={item.image}></img>
                    </div>
                    <div className="newsText">
                        <h4><a href={item.url} target="_blank" onClick={this.openModal}>{item.title}</a></h4>
                        <div className="newsText">{parsedSummaryAnonce_description}</div>
                        <div className="newsDate">{item.posted}</div>
                    </div>
                </div>
                {this.rendeModal()}
            </>
        )
    };
}

export default NewsModal;