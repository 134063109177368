import React from 'react';
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import StringProcessingService from '../../services/string-processing-service';

//custom copmonents
import './supplier-style.css';
//style
class Supplier extends React.Component {
  stringProcessingService = new StringProcessingService();



  render() {
    let supplier = this.props.supplier;
    let name = this.stringProcessingService.StringFormatUrl(this.props.supplier.company_name)
    return (
      <div className="supplierItem">
          <Link to={`/company/${supplier.id}/${name}/products`}><img className="imgLogo" src={supplier.company_logo_url}/></Link>
      </div>
    )
  }

}


export default Supplier;