import React from "react";
import StringProcessingService from "./string-processing-service";
import ConfigurationService from "./configuration-service";

const stringProcessingService = new StringProcessingService();
const configurationService = new ConfigurationService();

const langs = {
    en:{
        lang:'en',
        contries:['GB','US']
    },
    es: {
        lang:'es',
        contries:['AR','BO','CL','CO','CR','CU','DO','EC','ES','GI','GQ','GT','HN','LA','MX','NI','PA','PE','PR','PY','SV','US','UY','VE']
    },
};

let site;


if(configurationService.getSiteTheme()=='hmi') site='hosmipedica'
else site='labmedica' 

class SeoService {
    constructor(){

    }

    getCanonicalLink(url) {
        return (<link rel="canonical"  href={`https://${site}.com/expo${url}`} />)
    }

    insertHrefLangTags(props) {
        let {path='/',id = null,parsedNameForUrl = '',currentLang = null,translations = {en:{},es:{},pt:{}},params = null} = props;
        let result =[];
        for(let item in langs){
            let url = path
            if((translations&&translations.hasOwnProperty(item)) || (currentLang&&currentLang == item)){
                if(params){
                    for(let param in params){
                        url = url.replace(':'+param,params[param])
                    }
                }

                // let idUrl=id
                // let name =parsedNameForUrl
                // if(translations[item]&&currentLang != item){
                //     idUrl = translations[item].id;
                //     name =stringProcessingService.StringFormatUrl(translations[item].title);
                // }
                // url = path.replace(':id',idUrl)
                //               .replace(':name',parsedNameForUrl)
                
            }
            langs[item].contries.map((lang)=>{
                result.push( <link rel="alternate" href={`https://${site}.${langs[item].lang!='en'?langs[item].lang:'com'}/expo${url}`} hreflang={`${langs[item].lang}-${lang}`} />)
            })
        }
        return (result.map((link) => link))
    }
}

export default SeoService;