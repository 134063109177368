import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import HeaderMenu from '../header-menu';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import './top-menu-style.css';

class TopMenu extends React.Component {
  state = {
    debug: false,
    dropDownMenuIsVisible: false
  }


  showMenu() {
    this.setState({
      dropDownMenuIsVisible: !this.state.dropDownMenuIsVisible,
    })
  }

  // hideMenu = () => {
  //   this.setState({
  //     dropDownMenuIsVisible: false
  //   })
  // }
  
  topMenuBars = React.createRef();

  getMenuPosition = () => {
    const menuElement = this.topMenuBars.current.getBoundingClientRect();
    return menuElement.left - 123;
  }




  render() {
    let {isVisibleHeaderMenu} = this.props
    return (
      <div className="TopMenuDropDownMenu TopMenuMenuItem" ref={this.topMenuBars}>
          <FontAwesomeIcon
            icon={faBars}
            color="white"
            pull="right" 
            className="TopMenuIcons" 
            onClick={(() =>{
              //this.setState({ dropDownMenuIsVisible: !this.state.dropDownMenuIsVisible })
              document.dispatchEvent(new CustomEvent('openModal',{detail:{name:'isVisibleHeaderMenu',value:true}}))
            })}
          />
        <HeaderMenu
          isVisible = {isVisibleHeaderMenu}
          //isVisible={this.state.dropDownMenuIsVisible}
          //hideMenu = {this.hideMenu}
          getMenuPosition = {this.getMenuPosition}
          language={this.props.language}

        />
      </div>
    )
  }
}

export default TopMenu;