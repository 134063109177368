import ConfigurationService from '../services/configuration-service';

let debug =false;
const configurationService = new ConfigurationService();
let domain = configurationService.getCurrentServer().domainCookieManager;
if(debug){
    console.log(domain);
}
class Cookie{
    constructor(name,value){
        this.name=name;
        this.value=value;
    }

    getName(){
        return `${this.name}`;
    }

    getValue(){
        return `${this.value}`;
    }
}
export default class CookiesManager {
    //axis1
    //domain =".labmedica.com.axis1.net";
    //onserv
    //domain =".labmedica.com.onserv";
    get(name) {
        const cookies = this.getAll();

        return cookies[name];
    }

    getAll() {
        const pairs = document.cookie.split(';');
        const cookies = {};

        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split('=');
            cookies[(pair[0] + '').trim()] = decodeURIComponent(pair[1]);
        }

        return cookies;
    }
    // , value = '', days = 30
    set(name, value, days) {
        // const date = new Date();
        // date.setTime(date.getTime() + (days*24*60*60*1000));

        // const chunks = [
        //     `${name}=${value}`,
        //     `expires=${date.toUTCString()}`,
        //     'path=/'
        // ].join('; ');

        // document.cookie = chunks;
        var d = new Date;
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    clearAll() {
        //     const date = new Date();
        //     date.setTime(date.getTime() + (24*60*60*1000));
        //     const cookies = this.getAll();
        //     console.log("COOKIES: "+cookies['2db9ea54686f0e2209555fd11d3bffdc'] + ' typeof: ' + typeof(cookies));
        //     // let clearCookies = cookies.reduce(function(map, obj) {
        //     //     map[obj.key] = null;
        //     //     return map;
        //     // }, {});
        //     // let chunks;\
        //     console.log(Object.values(cookies));
        //    for(let i =0;i<cookies.length;i++){
        //         console.log(cookies[i]); 
        //    }

        //     document.cookie = null;
        var cookies = document.cookie.split("; ");

        var cookiesArray=[];
       
        for(let i=0;i<cookies.length;i++){
            let cookie = cookies[i];
            let name='';
            let value='';
            let flag = false;

            for(let j=0;j<cookie.length;j++){
                if(cookie[j]==='='){
                    flag = true;
                    continue;
                }
                if(!flag){
                    name+=cookie[j];
                }else{
                    value+=cookie[j];
                }
            }
            // console.log("Name: "+name);
            // console.log("Value: "+value );
            cookiesArray.push(new Cookie(name,value));
            
        }
        for (let i = 0; i < cookiesArray.length; i++) {
            //console.log("ALERT "+cookies[i].userName);
            let cookieName = cookiesArray[i].name;
            let cookieValue = cookiesArray[i].value;
           
            document.cookie = `${cookieName}=${cookieValue};domain=${domain};path=/;max-age=0`;
        }
    }
}