
export default class FormCheckService {

    showAlert = (title = '', text = '') => {
        alert(text);
    }

    checkEmptyField = (field = '', fieldName='All fields') => {
        if (field === ''|| field=== ' '||field ===null||field === undefined) {
            this.showAlert ('Alert','Please enter '+fieldName);
            return true;
        }
        else {
            return false;
        }
    }

    checkSwith = (field = '', alert = '') => {
        if (!field) {
            this.showAlert ('Alert','Please accept Privace Policy');
            return true;
        }
        else {
            return false;
        }
    }


    checkEqualFields = (field1 = '', field2 = '', alert = '') => {
        if (field1 !== field2) {
            this.showAlert ('alert',alert);
            return true;
        }
        else {
            return false;
        }
    }

    checkEmail = (field = '', alert = '') => {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(field)) {
            this.showAlert ('Alert','Please enter '+alert);
            return true;
        }
        else {
            return false;
        }
    }
    
    checkPassword = (field = '', alert = '') => {
        //let re = /^([a-z0-9]+$/i){};
        let re = /^([a-zA-Z0-9_-]){6,50}$/;
        if (!re.test(field)) {
            this.showAlert (alert);
            return true;
        }
        else {
            return false;
        }
    }

}