import React, { useState,useContext } from 'react';
import ReactDOM from 'react-dom';
import './categories-list-modal-style.css';
import ServerApi from '../../services/server-api-service';
import ConfigurationService from '../../services/configuration-service';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { withRouter } from "react-router";
import { ThemeContext } from '../../elements/themes/themeContext';
import {motion } from 'framer-motion';

const listTransition = {
  in: {
    opacity: 1,
    left:45
  },
  out: {
    opacity: 0,
    left:10
  }
}


function parseCategoryName(name) {
  var pattern = new RegExp("[^a-zA-Z0-9]+", "g");
  let parsedName = (name.replace(pattern, '-')).toLowerCase();
  return parsedName;
}


function CategoryItemModal(props) {
  let {category, index, closeModal} = props;
  const [isVisibleSubCategory, setIsVibibleSubCategory] = useState(false);
  let context = useContext(ThemeContext);
  let name = parseCategoryName(category.category_name);

  const categories_list = category.subcategories.map((item)=>{
    let name = parseCategoryName(item.category_name);
    return(
      <div className="categoryDivModal" key={index}>
          <Link onClick={closeModal} className="categoryTextModal" to={`/category/` + item.category_id + "/" + name} >{item.category_name}</Link>
      </div>
    )
  })

  const renderSubCategories = function (category) {
    if (context.siteTheme === 'hmi' && category.category_id.length) {
    let style = {}
      if(categories_list.length>14){
        style= {
          position:'fixed',
          top:'196px'
        }
      }
      return (
        <div className = "subCategoryDivModal">
          <div className="CategoriesListModal" style={style}>
              <div className="CategoriesListBlockModal">
                {categories_list}
              </div>
            </div>
        </div>
      )
    }
  }

  return (
    <div className="categoryDivModal" 
     onMouseEnter={() => { setIsVibibleSubCategory(true) }}
    onMouseLeave={() => { setIsVibibleSubCategory(false) }} key={index}>
      <Link
        onClick={closeModal}
        className="categoryTextModal"
        to={`/category/` + category.category_id + "/" + name}>
        {category.category_name}
      </Link>
      {isVisibleSubCategory?(renderSubCategories(category)):(null)}
    </div>
  )
}

class CategoriesListModal extends React.Component {
  serverApi = new ServerApi();
  configurationService = new ConfigurationService();
  state = {
    categories: []
  }

  static contextType = ThemeContext;

  getCategories = () => {
    let categoriesId = 7;
    if (this.configurationService.getSiteTheme() === 'hmi') categoriesId = 0;
    this.serverApi.getCategories(categoriesId).
      then((res) => {
        //console.log(res);
        this.setState({
          categories: res.categories
        })
      })
      .catch((error) => {
        console.log(error);
      })
  }



  componentDidMount() {
    this.getCategories();

    // const category_url = "https://testtrademed.s3.eu-central-1.amazonaws.com/cat.json";
    // axios.get(category_url)
    //   .then(res => {
    //     const categories = res.data;
    //     this.setState({ categories });
    //   })
  }

  render() {
    const categories_list = this.state.categories.map((category, index) => {
      let name = parseCategoryName(category.category_name);
      if (this.props.currentCategory !== category.category_id) {
        return (
          // <div className="categoryDivModal" key={index}>
          //   <Link onClick={this.props.closeModal} className="categoryTextModal" to={`/category/` + category.category_id + "/" + name} >{category.category_name}</Link>
          // </div>
          <CategoryItemModal
            category={category}
            index={index}
            closeModal={this.props.closeModal}
          />
        );
      } else {
        return (
          <div className="categoryCurrentDivModal" key={index}>
            <Link onClick={this.props.closeModal} className="categoryCurrentTextModal" to={`/category/` + category.category_id + "/" + name} >{category.category_name}</Link>
          </div>
        );
      }
    });

    let body = null;
    if (this.props.isVisible) {
      body = (
        <>
          <div style={{ backgroundColor: 'rgba(0,0,0,0.0)', zIndex: "0" }} className={this.props.isVisible ? "modalShadow modalShadowLarge" : ''} onClick={this.props.closeModal}>
          </div>

          <motion.div 
          variants={listTransition}
          exit="out"
          animate="in"
          initial="out"
          className="CategoriesListLineModal">
            <div className="CategoriesListModal">
              <div className="CategoriesListBlockModal">
                {categories_list}
              </div>
            </div>
          </motion.div>
        </>
      )
    } else {
      body = null;
    }

    return (
      <div
        
      >
        {body}
      </div>
    )
  }
}

export default withRouter(CategoriesListModal);