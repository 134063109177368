import React from 'react';
import Slider from "react-slick";

import './product-page-company-menu-style.css';
import SupplierStatus from '../supplier-status';
import NewsModal from '../news-modal';
import { withRouter } from 'react-router-dom';
import { faRunning, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import StringProcessingService from '../../services/string-processing-service';


import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';
import { motion } from 'framer-motion';
import COMPANY_MENU_TRANSITION from '../../configuration/framer-configs/company-menu-transition';

const PAGESIZE = 3;
class ProductPageCompanyMenu extends React.Component {
    stringProcessingService = new StringProcessingService();
    constructor(props) {
        super(props);
        this.state = {

            debug: false,
            isVisibleSummary: false,
            isVisibleVideos: false,
            isVisibleNews: false,
            isVisibleEvents: false,
            isVisibleWhitepappers: false,
            currentVideo: null,
            newsCurrentPage: 1,
            newsTotalPages: 0
        }
        this.onClickProducts = this.onClickProducts.bind(this)
        this.changeIsVisibleSummary = this.changeIsVisibleSummary.bind(this);
        this.changeIsVisibleVideos = this.changeIsVisibleVideos.bind(this);
        this.onSwitchVideo = this.onSwitchVideo.bind(this);
        this.changeIsVisibleNews = this.changeIsVisibleNews.bind(this);
        this.changeIsVisibleWhiteppapers = this.changeIsVisibleWhiteppapers.bind(this);
        this.changeIsVisibleEvents = this.changeIsVisibleEvents.bind(this);
    }


    componentDidMount() {
        if (this.state.debug) {
            console.log("componentDidMount: ProductPageCompanyMenu component...");
        }
    }

    componentDidUpdate() {
        if (this.state.currentVideo == null && this.props.companyData != undefined) {
            let { companyData } = this.props;
            if (companyData.videos !== false && companyData.videos !== undefined) {
                this.setState({
                    currentVideo: companyData.videos[0]
                })
            }
            if (companyData.news != null && companyData.news) {
                if (this.state.newsTotalPages === 0) {
                    let total_pages = 0;
                    let counterTotalNewsToRender = 0;
                    companyData.news.forEach(element => {
                        if (element.url) counterTotalNewsToRender++;
                    });
                    console.log(counterTotalNewsToRender)

                    if (counterTotalNewsToRender % PAGESIZE !== 0) {
                        total_pages = parseInt(counterTotalNewsToRender / PAGESIZE + 1);
                    } else {
                        total_pages = counterTotalNewsToRender / PAGESIZE;
                    }
                    this.setState((prevState) => {
                        if (prevState.newsTotalPages != total_pages) return { newsTotalPages: total_pages }
                    })

                }
            }
        }

        if (this.state.debug) {
            console.log("componentDidUpdate: ProductPageCompanyMenu component...");
        }
        // console.log(this.props.productData.images.length);
        // console.log(this.state.imagesList.length);
    }

    changeIsVisibleSummary() {
        this.setState({
            ...this.state,
            isVisibleNews: false,
            isVisibleVideos: false,
            isVisibleEvents: false,
            isVisibleWhitepappers: false,
            isVisibleSummary: !this.state.isVisibleSummary
        })
    }

    changeIsVisibleNews() {
        this.setState({
            ...this.state,
            isVisibleNews: !this.state.isVisibleNews,
            isVisibleEvents: false,
            isVisibleVideos: false,
            isVisibleWhitepappers: false,
            isVisibleSummary: false
        })
    }


    changeIsVisibleVideos() {
        this.setState({
            ...this.state,
            isVisibleNews: false,
            isVisibleSummary: false,
            isVisibleEvents: false,
            isVisibleWhitepappers: false,
            isVisibleVideos: !this.state.isVisibleVideos
        })
    }

    changeIsVisibleWhiteppapers() {
        this.setState({
            ...this.state,
            isVisibleNews: false,
            isVisibleSummary: false,
            isVisibleEvents: false,
            isVisibleVideos: false,
            isVisibleWhitepappers: !this.state.isVisibleWhitepappers
        })
    }

    changeIsVisibleEvents() {
        this.setState({
            ...this.state,
            isVisibleEvents: !this.state.isVisibleEvents,
            isVisibleNews: false,
            isVisibleSummary: false,
            isVisibleVideos: false,
            isVisibleWhitepappers: false
        })
    }


    onClickProducts(event) {
        let parsedName = this.stringProcessingService.StringFormatUrl(this.props.companyData.company_name);
        this.props.history.push(`/company/${this.props.companyData.id}/${parsedName}/products`);
    }

    onSwitchVideo(newVideo) {
        this.setState({
            currentVideo: newVideo
        })
    }

    handleChangeNewsPage = (index) => {
        this.setState({
            newsCurrentPage: index
        })
    }

    renderPagination() {
        let { news } = this.props.companyData;
        let { newsCurrentPage, newsTotalPages } = this.state;
        if (news) {
            let arrayOfIndex = [];
            for (let i = 1; i <= newsTotalPages; i++) {
                arrayOfIndex.push(i);
            }

            let paginationItems;

            paginationItems = arrayOfIndex.map((item, index) => {
                if (newsTotalPages < 10) {
                    if (newsCurrentPage - 1 === index) {
                        return (
                            <div
                                key={index}
                                className="pagination current"
                                onClick={this.handleChangeNewsPage.bind(this, item)}
                            >
                                {item}
                            </div>
                        )
                    } else {
                        return (
                            <div
                                key={index}
                                className="pagination page"
                                onClick={this.handleChangeNewsPage.bind(this, item)}
                            >
                                {item}
                            </div>
                        )
                    }
                }
                else {
                    //if newsTotalPages > 10
                    //at start
                    if (((newsCurrentPage - index) <= 6) && (newsCurrentPage > index)) {
                        if ((newsCurrentPage - index) == 6) {
                            return (
                                <div
                                    key={index}
                                    className="pagination page"
                                    onClick={this.handleChangeNewsPage.bind(this, 1)}
                                >{'<<'}
                                </div>
                            );
                        }
                        if (newsCurrentPage == item) {
                            return (
                                <div
                                    key={index}
                                    className="pagination current"
                                    onClick={this.handleChangeNewsPage.bind(this, item)}
                                >{item}
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={index}
                                    className="pagination page"
                                    onClick={this.handleChangeNewsPage.bind(this, item)}
                                >{item}
                                </div>
                            );
                        }
                    } else
                        //redner after current 
                        if (((newsCurrentPage - index) >= -4) && (newsCurrentPage <= index)) {
                            if ((newsCurrentPage - index) == -4) {
                                return (
                                    <div
                                        key={index}
                                        className="pagination page"
                                        onClick={this.handleChangeNewsPage.bind(this, newsTotalPages)}
                                    >{'>>'}</div>
                                );
                            }
                            if (newsCurrentPage == item) {
                                return (
                                    <div
                                        key={index}
                                        onClick={this.handleChangeNewsPage.bind(this, item)}
                                        className="pagination current"
                                    >{item}
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        key={index}
                                        onClick={this.handleChangeNewsPage.bind(this, item)}
                                        className="pagination page"
                                    >{item}
                                    </div>
                                );
                            }
                        }
                }

            })

            if (news.length > PAGESIZE) {
                return (
                    <div className="paginationLine">
                        <div className="paginationBody">
                            <div className="pagination">
                                {paginationItems}
                            </div>
                        </div>
                    </div>
                )
            }

        }

    }

    renderNews() {
        let { isVisibleNews, newsCurrentPage } = this.state;
        let { news } = this.props.companyData || '';
        let startIndex, endIndex;
        startIndex = (newsCurrentPage - 1) * PAGESIZE;
        endIndex = (newsCurrentPage - 1) * PAGESIZE + PAGESIZE;


        if (news && isVisibleNews && news.length > 0) {
            if (endIndex > news.length) endIndex = news.length;
            return (
                <motion.div
                    variants={COMPANY_MENU_TRANSITION}
                    exit="out"
                    animate="in"
                    initial="out"
                >
                    {news.map((item, index) => {
                        if (((index < endIndex && index >= startIndex) || (news.length <= PAGESIZE)) && item.url !== false) {
                            let parsedSummaryAnonce_description = null;
                            if (item.anonce_description) {
                                parsedSummaryAnonce_description = parse(item.anonce_description);
                            }
                            {/* return (
                                <div key={index} className="newsItem">
                                    <div className="newsImage">
                                        <img src={item.image}></img>
                                    </div>
                                    <div className="newsText">
                                        <h4><a href={item.url} target="_blank">{item.title}</a></h4>
                                        <div className="newsText">{parsedSummaryAnonce_description}</div>
                                        <div className="newsDate">{item.posted}</div>
                                    </div>
                                </div>
                            ) */}
                            return (
                                <NewsModal item={item} key={index} />
                            )
                        }

                    })}
                    {this.renderPagination()}
                </motion.div>
            )
        }
        // if (news) {
        //     if (this.state.isVisibleNews) {
        //         return (
        //             <div>
        //                 {news.map((item, index) => {
        //                     let parsedSummaryAnonce_description = null;
        //                     if (item.anonce_description) {
        //                         parsedSummaryAnonce_description = parse(item.anonce_description);
        //                     }
        //                     if (item.url != false) {
        //                         return (
        //                             <div key={index} className="newsItem">
        //                                 <div className="newsImage">
        //                                     <img src={item.image}></img>
        //                                 </div>
        //                                 <div className="newsText">
        //                                     <h4><a href={item.url} target="_blank">{item.title}</a></h4>
        //                                     <div className="newsText">{parsedSummaryAnonce_description}</div>
        //                                     <div className="newsDate">{item.posted}</div>
        //                                 </div>
        //                             </div>
        //                         )
        //                     } else {
        //                         return null;
        //                     }

        //                 })}
        //             </div>
        //         )
        //     } else {
        //         return null;
        //     }
        // }
    }


    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 2,
            rows: 2,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        };


        const { company_name, company_rank_id, company_logo_url, id, company_rank,
            company_summary, company_description, company_year, employees, news, videos, whitepappers
            , certificates, sales, markets, events } = this.props.companyData || "";
        let language = this.props.language;
        let parsedSummary = '';
        if (company_summary || company_description) {
            parsedSummary = parse(company_summary || company_description);
        }

        let { newsTotalPages } = this.state;

        //summary

        let certificatesList = null;
        if (certificates) {
            certificatesList = certificates.map((item, index) => {
                if (item.cert_url) {
                    return (
                        <div className="certificateItem" key={index}>
                            <img src={item.cert_url} />
                            <div>{item.name}</div>
                        </div>
                    )
                } else {
                    return (
                        null
                    )
                }

            })
        }

        let marketsList = null;
        if (markets !== undefined && markets.length !== 0) {
            let strList = '';
            markets.map((item, index) => {
                strList += item.name + ', ';
            })
            strList = strList.slice(0, -2);

            marketsList = (
                <div className="markets">
                    <h4>{language.EXPORT_MARKETS}</h4>
                    {strList}
                </div>
            )
        }

        let summary = null;
        if (this.state.isVisibleSummary) {
            summary = (
                <motion.div
                    variants={COMPANY_MENU_TRANSITION}
                    exit="out"
                    animate="in"
                    initial="out"
                    className="summary">
                    <h2> {language.ABOUT} {company_name}</h2>
                    <div className="summaryInfo">
                        {sales || employees || company_year ?
                            //exist
                            <div className="summaryAbout">
                                {sales ? (`${language.SALES}: ${sales}`) : ``}
                                {sales ? <br /> : ``}

                                {employees ? `${language.NUMBER_OF_EMPLOYEES}: ${employees}` : ''}
                                {employees ? <br /> : ``}

                                {company_year ? `${language.YEAR_OF_ESTABLISHMENT}: ${company_year}` : ''}
                                {company_year ? <br /> : ``}

                                {markets.length !== 0 ? <br /> : ``}
                                {marketsList}
                                {markets.length !== 0 ? <br /> : ``}

                            </div>
                            :
                            null
                        }

                        <div className="summaryText">
                            {parsedSummary}
                            <div className="cerftificateList">
                                {certificatesList}
                            </div>
                        </div>
                    </div>
                </motion.div>
            );
        } else {
            summary = (null);
        }




        //videos
        let othersVideos = null;

        if (videos !== undefined && videos.length > 1) {
            othersVideos = (
                <motion.div
                    variants={COMPANY_MENU_TRANSITION}
                    exit="out"
                    animate="in"
                    initial="out"
                    className="othersVideo">
                    <Slider {...settings} className="sliderVideo">
                        {
                            videos.map((item, index) => {
                                return (
                                    <div key={index} className="otherVideoItem" onClick={() => { this.onSwitchVideo(item) }}>
                                        <img src={`http://img.youtube.com/vi/${item.youtube_id}/mqdefault.jpg`}></img>
                                        <div>{item.title}</div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </motion.div>
            )
        }

        let videosRender = null;
        if (videos) {
            if (this.state.isVisibleVideos) {
                let video = videos[0];
                videosRender = (
                    <motion.div
                        className="videos"
                        variants={COMPANY_MENU_TRANSITION}
                        exit="out"
                        animate="in"
                        initial="out" >
                        <div className="mainVideo">
                            <iframe width="420" height="315"
                                src={`https://www.youtube.com/embed/${video.youtube_id}`}>
                            </iframe>
                            <h3>{video.title}</h3>
                            <div>{video.describe}</div>
                        </div>
                        {othersVideos}
                    </motion.div>
                )
            } else {
                videosRender = null;
            }
        }



        //news
        // let newsRender = null;
        // if (news) {
        //     if (this.state.isVisibleNews) {
        //         newsRender = (
        //             <motion.div
        //                 variants={COMPANY_MENU_TRANSITION}
        //                 exit="out"
        //                 animate="in"
        //                 initial="out"
        //             >
        //                 {news.map((item, index) => {
        //                     let parsedSummaryAnonce_description = null;
        //                     if (item.anonce_description) {
        //                         parsedSummaryAnonce_description = parse(item.anonce_description);
        //                     }
        //                     if (item.url != false) {
        //                         return (
        //                             <div key={index} className="newsItem">
        //                                 <div className="newsImage">
        //                                     <img src={item.image}></img>
        //                                 </div>
        //                                 <div className="newsText">
        //                                     <h4><a href={item.url} target="_blank">{item.title}</a></h4>
        //                                     <div className="newsText">{parsedSummaryAnonce_description}</div>
        //                                     <div className="newsDate">{item.posted}</div>
        //                                 </div>
        //                             </div>
        //                         )
        //                     } else {
        //                         return null;
        //                     }

        //                 })}
        //             </motion.div>
        //         )
        //     } else {
        //         newsRender = null;
        //     }
        // }
        //whitepappers
        let imagePdf = 'https://img.trademed.com/images/pgf_logo.jpg';
        let whitepappersRender = null;
        if (whitepappers) {
            if (this.state.isVisibleWhitepappers) {

               // НЕ ЗАБЫТЬ УБРАТЬ ДАННЫЕ
                whitepappersRender = (
                    <motion.div
                        variants={COMPANY_MENU_TRANSITION}
                        exit="out"
                        animate="in"
                        initial="out"
                        className="whitepappers">
                        {
                            whitepappers.map((item, index) => {
                                return (
                                    <div key={index} className="whitepappersItem">
                        
                                            <a href={item.url_to_file} target="_blank">
                                            <svg className='pdfIcon' xmlns="http://www.w3.org/2000/svg" height="4.25em" viewBox="0 0 512 512"><path d="M64 464H96v48H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V288H336V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"/></svg>                                            {/* <img className='pdfIcon' src={imagePdf}></img> */}
                                            </a>
                                            <div className='info'>
                                                <a className='title' href={item.url_to_file} target="_blank">{item.title}</a>
                                                <br/>
                                                {item.description}
                                                <br/>
                                                <a className='download' href={item.url_to_file} target="_blank">{`[${language.DOWNLOAD}]`}</a>
                                                
                                            </div>
                                    </div>
                                )
                            })
                        }
                    </motion.div>
                )
            } else {
                whitepappersRender = null;
            }
        }


        let eventRender = null;
        if (events) {
            const locationIcon = require('../../elements/images/map-marker-solid.svg');
            if (this.state.isVisibleEvents) {
                eventRender = (
                    <motion.div
                        variants={COMPANY_MENU_TRANSITION}
                        exit="out"
                        animate="in"
                        initial="out">
                        {events.map((item, index) => {
                            let date = null;
                            console.log(item.event_begin.replace(' 00:00:00', ''))
                            let eventStart = new Date(item.event_begin.replace(' 00:00:00', ''));
                            let eventEnd = new Date(item.event_end.replace(' 00:00:00', ''));
                            if (eventStart.getMonth() === eventEnd.getMonth()) {
                                date = `${eventStart.toLocaleString('en-us', { month: 'long' })} ${eventStart.getUTCDate() + 1} - ${eventEnd.getUTCDate() + 1}, ${eventStart.getFullYear()}`;
                            } else {
                                date = `${eventStart.toLocaleString('en-us', { month: 'long' })} ${eventStart.getUTCDate() + 1} - ${eventEnd.toLocaleString('en-us', { month: 'long' })} ${eventEnd.getUTCDate() + 1}, ${eventStart.getFullYear()}`;
                            }
                            return (
                                <div key={index} className="eventItem">
                                    <h2 className='title'>{item.title}</h2>
                                    <div className='date'>{date}</div>
                                    <br />
                                    <div className='description'>
                                        {parse(item.description)}
                                    </div>

                                    <div className='location'>  <a href={`https://www.google.com/maps/place/${item.location}`}> <img className='locationIcon' src={locationIcon} />{item.location}</a></div>

                                    <br />
                                    <div className='stand'>Stand #: {item.company_stand}</div>
                                    <br />
                                    <div className='websiteLink'><a href={item.website} target="_blank">Website</a></div>
                                </div>
                            )

                        })}
                    </motion.div>
                )
            } else {
                eventRender = null;
            }
        }



        return (

            <div className="companyMenuLine">
                <div className="companyMenuBody" id="companyMenuBody">
                    <div className="companyMenuItems">
                        <div className="companyMenuLogoCont" ><img className="companyMenuLogo" onClick={this.onClickProducts} src={company_logo_url} /></div>
                        <div className={"companyMenuItem " + (this.state.isVisibleSummary ? 'currentItem' : '')} onClick={this.changeIsVisibleSummary}>{language.SUMMARY}</div>
                        <div className="companyMenuItemSep">|</div>
                        <div className={"companyMenuItem " + (this.props.currentItem == 'products' ? 'currentItem' : '')} onClick={this.onClickProducts} >{language.PRODUCTS}</div>

                        <div className={"companyMenuItemSep" + ((news && newsTotalPages !== 0) ? '' : ' hidden')}>|</div>
                        <div className={"companyMenuItem " + (this.state.isVisibleNews ? 'currentItem' : '') + ((news && newsTotalPages !== 0) ? '' : ' hidden')} onClick={this.changeIsVisibleNews}>{language.NEWS}</div>
                        <div className={"companyMenuItemSep" + (videos ? '' : ' hidden')}>|</div>
                        <div className={"companyMenuItem " + (this.state.isVisibleVideos ? 'currentItem' : '') + (videos ? '' : ' hidden')} onClick={this.changeIsVisibleVideos}>{language.VIDEOS}</div>
                        <div className={"companyMenuItemSep" + (whitepappers ? '' : ' hidden')}>|</div>
                        <div className={"companyMenuItem " + (this.state.isVisibleWhitepappers ? 'currentItem' : '') + (whitepappers ? '' : ' hidden')} onClick={this.changeIsVisibleWhiteppapers}>{language.WHITEPAPPERS}</div>
                        <div className={"companyMenuItemSep" + (events ? '' : ' hidden')}>|</div>
                        <div className={"companyMenuItem " + (this.state.isVisibleEvents ? 'currentItem' : '') + (events ? '' : ' hidden')} onClick={this.changeIsVisibleEvents}>{language.EVENTS}</div>
                        <div className="companyName">
                            {company_name}
                        </div>
                    </div>

                    <SupplierStatus rang={company_rank_id} origin="company"
                        language={language}
                    />

                    {summary}
                    {videosRender}
                    {this.renderNews()}
                    {whitepappersRender}
                    {eventRender}
                </div>

            </div>
        )
    }
}

export default withRouter(ProductPageCompanyMenu);