import React from 'react';
import ProductsGrid from '../products-grid';
import as from './thank-you-page-style.css';
import Search from '../search';
import SuppliersBar from '../suppliers-bar';
import Header from '../header';
import TopSlider from '../top-slider';
import ServerApi from '../..//services/server-api-service'
import { withRouter } from "react-router";

import './thank-you-page-style.css';
import { ThemeContext } from '../../elements/themes/themeContext';
import { motion } from 'framer-motion';
import PAGE_TRANSITION from '../../configuration/framer-configs/page-transition';

class ThankYouPage extends React.Component {
  serverApi = new ServerApi();
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
    }

  }

  getCategoryProducts(categoryId, id, company) {

    this.serverApi.getCategoryProducts(categoryId, 1, 8, id, company)
      .then((data) => {
        this.setState({
          products: data.category_products
        })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  componentWillMount() {
    let data = this.props.location.state;
    let categoryId;
    let companyRank;
    if (this.props.location.state == undefined) {
      this.props.history.push('/');
    } else {
      if (data.categories[1] != undefined) {
        categoryId = data.categories[1].category_id;
      } else {
        categoryId = data.categories[0].category_id;
      }

      let company = { companyId: data.companyId, companyRank: data.companyRank, productId: data.id }
      this.serverApi.search(7, data.name, 1, 8, company)
        .then((data) => {
          if (data.statistic.found_items != 0) {
            this.setState({
              products: data.found_products
            })
          } else {
            if (this.context.siteTheme === 'hmi') company = 0;
            this.getCategoryProducts(categoryId, data.id, company)
          }
        })
        .catch((error) => {
          console.log(error);
        })

    }
  }

  static contextType = ThemeContext;
  render() {
    //http://www.labmedica.com.onserv/?option=com_mobile_app&task=get_tm_categories&lang=en&parent_id=714&type=products&page=1&limit=40&lang=en
    let userData = this.props.userData;
    let language = this.props.language;
    let name = '';
    let model = '';
    if (this.props.location.state != undefined) {
      name = this.props.location.state.name;
      model = this.props.location.state.model;
    }
    let title = `${language.WHO_INQUIRED} <b>${name}</b> - <b>${model}</b> ${language.INTERESTED_IN}:`;
    return (
      <motion.div
        variants={PAGE_TRANSITION}
        exit="out"
        animate="in"
        initial="out"
        className="thanksPageMain">
        {/* <Header userData={userData} language={language} /> */}
        <TopSlider
          billboards={this.state.billboards}
        />
        <h2>{language.THANKS_FOR_INQUIRY}</h2>
        <ProductsGrid
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          isFeatured={false}
          userData={userData}
          products={this.state.products}
          page={1}
          limit={8}
          nameOfList={title}
          language={language}
          handleOnLogin={this.props.handleOnLogin}
        />
      </motion.div>
    )
  }
}

export default withRouter(ThankYouPage);