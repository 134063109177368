import React from 'react';
//custom copmonents
import './recently-viewed-products-style.css';
import Product from '../product';
import ServerApi from '../..//services/server-api-service';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
//style

const productTransition = {
  in: {
    opacity: 1,
    left:45
  },
  out: {
    opacity: 0,
    left:10
  }
}


class RecentlyViewedProducts extends React.Component {
  serverApi = new ServerApi();

  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      products: [
        // {
        //   anonce_description: "The CareGene N-CoV RT-PCR Kit is an in vitro diagnostic medical device for the qualitative detection...",
        //   company: {
        //     advertno: "980109323",
        //     company_logo_url: "https://img.trademed.com/members/8620/logo/access-bio.jpg",
        //     company_name: "Access Bio Inc",
        //     company_rank: "Regular",
        //     company_rank_id: "1",
        //     id: "8620",
        //   },
        //   conditions: "1",
        //   is_newly_created: "1",
        //   product_id: "9153",
        //   product_image_url: "https://img.trademed.com/products/9428/CareGene™ N-CoV RT-PCR Kit.jpg",
        //   product_model: "CareGene N-CoV RT-PCR Kit",
        //   product_title: "SARS-CoV-2 RT-PCR Test",
        // },
        // {
        //   anonce_description: "The CareGene N-CoV RT-PCR Kit is an in vitro diagnostic medical device for the qualitative detection...",
        //   company: {
        //     advertno: "980109323",
        //     company_logo_url: "https://img.trademed.com/members/8620/logo/access-bio.jpg",
        //     company_name: "Access Bio Inc",
        //     company_rank: "Regular",
        //     company_rank_id: "1",
        //     id: "8620",
        //   },
        //   conditions: "1",
        //   is_newly_created: "1",
        //   product_id: "9152",
        //   product_image_url: "https://img.trademed.com/products/9428/CareGene™ N-CoV RT-PCR Kit.jpg",
        //   product_model: "CareGene N-CoV RT-PCR Kit",
        //   product_title: "SARS-CoV-2 RT-PCR Test",
        // }
        // ,
        // {
        //   anonce_description: "The CareGene N-CoV RT-PCR Kit is an in vitro diagnostic medical device for the qualitative detection...",
        //   company: {
        //     advertno: "980109323",
        //     company_logo_url: "https://img.trademed.com/members/8620/logo/access-bio.jpg",
        //     company_name: "Access Bio Inc",
        //     company_rank: "Regular",
        //     company_rank_id: "1",
        //     id: "8620",
        //   },
        //   conditions: "1",
        //   is_newly_created: "1",
        //   product_id: "9154",
        //   product_image_url: "https://img.trademed.com/products/9428/CareGene™ N-CoV RT-PCR Kit.jpg",
        //   product_model: "CareGene N-CoV RT-PCR Kit",
        //   product_title: "SARS-CoV-2 RT-PCR Test",
        // }



      ]
    }
  }

  // getProducts() {
  //   this.serverApi.getRecentlyViewed()
  //     .then((data) => {
  //       this.setState({
  //         recentlyViewedProducts: data.recently_viewed
  //       })
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  // }

  componentWillMount() {
    // this.getProducts()
  }

  componentDidMount() {
    if (this.state.debug) {
      console.log("componentDidMount: RecentlyViewedProducts component...");
    }
  }

  componentWillReceiveProps() {
  }

  componentWillUnmount = () => {
    if (this.state.debug) {
      console.log("componentWillUnmount: RecentlyViewedProducts component...");
    }
    this.isComponentMounted = false;
  };

  render() {
    const { showLogo } = this.props;
    let products_list
    if (this.props.recentlyViewedProducts !== undefined && this.props.recentlyViewedProducts.length > 0) {
      products_list = this.props.recentlyViewedProducts.map((product) => {
        if (product != null&& product.company !=null) {
          return (
            <div onClick={this.props.closeModal} className="RecentlyProductsItem">
              <Product
                isRecently={true}
                country={this.props.country}
                establishmentTypes={this.props.establishmentTypes}
                companyData={product.company}
                userData={this.props.userData}
                isFeatured={false}
                key={product.product_id}
                data={product}
                showLogo={showLogo}
                language={this.props.language}
                handleOnLogin={this.props.handleOnLogin}
              />
            </div>
          );
        }
      });
    } else {
      products_list = null;
    }
    let body;
    if (this.props.isVisible) {
      body = (
        <motion.div 
         variants={productTransition}
        exit="out"
        animate="in"
        initial="out"
        className="RecentlyBodyStyle">
          <div className="RecentlyBodyStyleItems" onClick={(e)=>{e.stopPropagation()}}>
            <div className="RecentlyProductsTitle">
              <h2>{this.props.language.RECENTLY_VIEWED}</h2>
                <FontAwesomeIcon
                  icon={faTimes}
                  color="black"
                  pull="right"
                  inverse className="Icon"
                  onClick={this.props.closeModal}
                />
            </div>
            <div className="RecentlyProductsList">
              {products_list}
            </div>
          </div>
        </motion.div>
      )
    } else {
      body = null;
    }
    return (
      <>
        {body}
      </>
    )
  }

}


export default RecentlyViewedProducts;