import React from 'react';
import { withRouter } from 'react-router-dom';
import './header-search-styles.css';
import ConfigurationService from '../../services/configuration-service';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {motion } from 'framer-motion';
import SEARCH_TRANSITION from '../../configuration/framer-configs/search-transition';

class HeaderSearch extends React.Component {

  configurationService = new ConfigurationService();
  constructor(props){
    super(props);
    this.state = {
      debug: false,
      // userHeight: 0,
      userWidth:window.innerWidth,
      showMobileSearchBar: false
    }
  }
  // handleScroll = this.handleScroll.bind(this);
  handleChange = this.handleChange.bind(this);
  handleSubmit = this.handleSubmit.bind(this);
  handleResize = this.handleResize.bind(this);
  


  componentDidMount() {
    if (this.state.debug) {
      console.log("componentDidMount: header component...");
    }
    // window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }


  componentWillUnmount() {
    if (this.state.debug) {
      console.log("componentWillUnmount : header component...");
    }
    // window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  // updateScroll(newScroll) {
  //   this.setState({
  //     userHeight: newScroll
  //   });
  //   if (this.state.debug) {
  //     console.log(newScroll);
  //   }
  // }

  // handleScroll(event) {
  //   let scrollTop = window.pageYOffset;
  //   this.updateScroll(scrollTop);
  //   // this.setState({
  //   //   userHeight
  //   // })
  // }

  handleResize(event) {
    this.setState({
      userWidth: window.innerWidth
    })
  }

  handleChange(event) {
    this.setState({ search: event.target.value });
  }

  handleSubmit(event) {
    let mainCategoryId = '7';
    if(this.configurationService.getSiteTheme() === 'hmi' ) mainCategoryId = '0';
    
    if (this.state.search != null && this.state.search != "" && this.state.search != undefined) {
      this.props.history.push(`/search/${mainCategoryId}/${this.state.search}/1`);
    } else {
      event.preventDefault();
    }
  }
  handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        this.handleSubmit(e); 
      }
  }

  renderHeaderSearchMobileBar = () => {
    if (this.props.showMobileSearchBar) {
      return (
        <motion.div 
        variants={SEARCH_TRANSITION}
        exit="out"
        animate="in"
        initial="out"
        className="HeaderSearchMobileBar">
          <div className="HeaderSearchMobile">
            <input onChange={this.handleChange} onKeyDown={this.handleKeyDown} className="HeaderSeacrhInput" type="text" placeholder={this.props.language.SEARCH_PLACEHOLDER} />
            <button onClick={this.handleSubmit}><FontAwesomeIcon icon={faSearch} color="white" /></button>
          </div>
        </motion.div>
      );

    }
    else {
      return null;
    }
  }

  openModal(){
    if(this.props.showMobileSearchBar)
      document.dispatchEvent(new CustomEvent('closeAllModals',{}));
    else 
      document.dispatchEvent(new CustomEvent('openModal',{detail:{name:"showMobileSearchBar",value:true}}))
  }

  render() {
    // if ((this.state.userHeight > 460) || (window.location.href.includes("product"))) {
    if (this.state.userWidth > 900 && (window.location.href.includes("product")||window.location.href.includes("thank_you"))) {
      return(
        <div >
          <div className="HeaderSearchMobile">
            <input onChange={this.handleChange} onKeyDown={this.handleKeyDown} className="HeaderSeacrhInput" type="text" placeholder={this.props.language.SEARCH_PLACEHOLDER} />
            <button onClick={this.handleSubmit}><FontAwesomeIcon icon={faSearch} color="white" /></button>
          </div>
        </div>
      )
    } else {
      return (
        //<div className="HeaderSearch">
        //<input onChange={this.handleChange} className="HeaderSeacrhInput" type="text" placeholder="Products, Suppliers, Keywords..." />
        //<button onClick={this.handleSubmit}><FontAwesomeIcon icon={faSearch} color="white" /></button>
        <div>
        
            <div className="HeaderSearchButton">
              <FontAwesomeIcon
                icon={faSearch}
                color="white"
                pull="right"
                className="TopMenuIcons TopMenuIconsSearch"
                //onClick={(() => this.setState({ showMobileSearchBar: !this.state.showMobileSearchBar }))}
                onClick={(() => this.openModal())}
              />

            </div>
            {this.renderHeaderSearchMobileBar()}
        </div>
      );
    }
    // }
    // else return null;
  }
}


export default withRouter(HeaderSearch);