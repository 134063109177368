import React from 'react';
//import {withRouter} from 'react-router-dom';
import TopMenu from '../top-menu';
import Login from '../login';
import HeaderSearch from '../header-search';
import {ThemeContext} from '../../elements/themes/themeContext.js'
import './header-style.css';
import {
  Link
} from "react-router-dom";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

class Header extends React.Component {

  state = {
      debug: false
    }
 
  componentDidMount() {
    if (this.state.debug) {
      console.log("componentDidMount: header component...");
    }
  }

  componentWillUnmount() {
    if (this.state.debug) {
      console.log("componentWillUnmount : header component...");
    }
  }

  static contextType = ThemeContext;
  render() {
    let language =this.props.language;
    const context = this.context;
    let expoLogo;
    if(context.siteTheme ==='hmi') expoLogo = require('../../elements/images/expo-logo-hmi.png');
    else expoLogo = require('../../elements/images/expo-logo.png');

    return (
      <div className="HeaderStyle">
        <div className="HeaderBodyStyle">

          <div className="HeaderLogo">
            <Link to={`/`}><img className="HeaderLogoImg" src={expoLogo} alt="Labmedica Expo" /> </Link>
          </div>

          <div className="HeaderMenu">
          
            {/* <div className="HeaderAdvertiseWithUs">
              <a href="https://www.labmedica.com/advertising">{language.ADVERTISE}</a>
            </div> */}
            <HeaderSearch 
              language ={language}
              showMobileSearchBar = {this.props.showMobileSearchBar}
            />
            <Login userData={this.props.userData} 
              language ={language}
              handleOnLogin={this.props.handleOnLogin}
              isVisibleLoginModal = {this.props.isVisibleLoginModal}
              isVisibleRegisterModal = {this.props.isVisibleRegisterModal}
            />

            <TopMenu 
               language ={language}
              isVisibleHeaderMenu = {this.props.isVisibleHeaderMenu}

            />
          </div>
        </div>
      </div>
    )
  }

}

export default Header;