import React from 'react';
import './header-menu-style.css';
import ConfigurationService from '../../services/configuration-service'
import { ThemeContext } from '../../elements/themes/themeContext';
import { motion } from 'framer-motion';
import HEADER_TRANSITION from '../../configuration/framer-configs/header-transitions'

class HeaderMenu extends React.Component {
    configurationService = new ConfigurationService();
    state = {
        debug: false,
        leftMenuPosition: 0
    }

    updateMenuPositionOnResize = () => {
        this.setState({ leftMenuPosition: this.props.getMenuPosition() });
    };

    componentDidMount() {
        if (this.state.debug) {
            console.log("componentDidMount: HeaderMenu component...");
        }
        window.addEventListener('resize', this.updateMenuPositionOnResize);
    }

    componentDidUpdate() {
        if (this.state.debug) {
            console.log("componentDidUpdate: HeaderMenu component...");
        }

        if ((this.state.leftMenuPosition !== this.props.getMenuPosition())) {
            this.setState({
                leftMenuPosition: this.props.getMenuPosition()
            })
        }
    }


    componentWillUnmount() {
        if (this.state.debug) {
            console.log("componentWillUnmount : HeaderMenu component...");
        }
        window.removeEventListener('resize', this.updateMenuPositionOnResize);
    }


    static contextType = ThemeContext;
    render() {
        const context = this.context;
        let language = this.props.language;
        let menu;
        let { isVisible, hideMenu } = this.props;
        let { leftMenuPosition } = this.state;
        let siteUrlLabmedica = 'https://www.labmedica.com/';
        let siteUrlTradeMed = 'https://www.trademed.com/';
        let siteUrlHospimedica = 'https://www.hospimedica.com/'
        if (this.configurationService.getCurrentLanguagePrefix() === 'es') {
            siteUrlLabmedica = 'https://www.labmedica.es/';
            siteUrlTradeMed = 'https://es.trademed.com/';
            siteUrlHospimedica = 'https://www.hospimedica.es/'
        }
        let siteUrl = siteUrlLabmedica;
        if (context.siteTheme === 'hmi') siteUrl = siteUrlHospimedica;

        if (isVisible) {
            menu = (
                <motion.div
                    variants={HEADER_TRANSITION}
                    exit="out"
                    animate="in"
                    initial="out"
                    className="dropDawnMenuBgr"
                    onClick={() => document.dispatchEvent(new CustomEvent('closeAllModals'))}
                >

                    <div
                        id="dropDawnMenu"
                        style={{ left: leftMenuPosition }}
                        onClick={(e) => { e.stopPropagation(); }}
                    >
                        <a className="menuItem" href={`${siteUrl}`}>{language.DAILY_CLINICAL_LAB_NEWS}</a>
                        <a className="menuItem" href={`${siteUrl}linkxpress`}>LinkXpress</a>
                        <a className="menuItem" href={`${siteUrl}calendar`}>{language.EVENTS}</a>
                        {/* <a className="menuItem" href="https://www.labmedica.com/product-showcase">{language.PRODUCT_SHOWCASE}</a> */}
                        <a className="menuItem" href={`${siteUrl}whitepaper-archive`}>{language.WHITEPAPERS_ARCHIVE}</a>
                        <a className="menuItem" href={`${siteUrl}video-library`}>{language.VIDEO_LIBRARY}</a>
                        <a className="menuItem" href={`${siteUrl}about`}>{language.ABOUT_US}</a>
                        <a className="menuItem" href={`${siteUrl}about`}>{language.ADVERTISE_WITH_US}</a>
                        <a className="menuItem" href={`${siteUrl}contact`}>{language.CONTACT_US}</a>

                        <h3>{language.PARTNERS}</h3>
                        <a className="menuItem" href={siteUrlTradeMed}>TradeMed.com</a>
                        <a className="menuItem" href={siteUrlHospimedica}>HospiMedica.com</a>

                    </div>
                </motion.div>
            )
        } else {
            menu = null;
        }

        return (menu)
    }
}

export default HeaderMenu;