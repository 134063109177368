
const HEADER_TRANSITION = {
    in: {
      opacity: 1,
      top:1,
      transition: { delay: 0.1 }
    },
    out: {
      opacity: 0,
      top:-20,
      transition: { delay: 0.1 }
    }
}
export default HEADER_TRANSITION