import React, { useRef, useEffect } from 'react';
import CategoriesList from '../categories-list';
import ProductsGrid from '../products-grid';
// import RecentlyViewedProducts from '../recently-viewed-products';
// import Trending from '../trending';
// import FeaturedProducts from '../featured-products';
import Search from '../search';
import SuppliersBar from '../suppliers-bar';
import TopSlider from '../top-slider';
import ServerApi from '../..//services/server-api-service'
import ConfigurationService from "../../services/configuration-service";
import './category-style.css';
import {
  BrowserRouter as Router,
  Link,
  useLocation,
} from "react-router-dom";
import { withRouter } from "react-router";
import { ThemeContext } from '../../elements/themes/themeContext';
import { motion } from 'framer-motion';
import PAGE_TRANSITION from '../../configuration/framer-configs/page-transition';

import { Helmet } from 'react-helmet';
import SeoService from '../../services/seo-service';

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      products: [],
      statistic: {
        total_pages: 0
      }
    }
    this.scrollRef = React.createRef();
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  configurationService = new ConfigurationService();
  seoService = new SeoService();
  serverApi = new ServerApi();
  static contextType = ThemeContext;
  componentDidMount() {

    if (this.state.debug) {
      console.log("componentDidMount: Category component...");
    }

    this.getProducts(this.props.match.params.id, this.props.match.params.page || 1);
  }

  componentWillUpdate() {
    if (this.state.debug) {
      console.log("componentWillUpdate: Category component...");
    }
  }

  componentWillUnmount = () => {
    if (this.state.debug) {
      console.log("componentWillUnmount: Category component...");
    }
    this.isComponentMounted = false;
  };


  scrollToTop() {
    window.scrollTo({
      top: this.scrollRef.current.offsetTop - 55,
      left: 0,
      behavior: 'smooth'
    });
  }

  // getBillboards() {
  //   this.serverApi.getBillboards()
  //     .then((res) => {
  //       console.log(res)
  //       this.setState({
  //         ...this.state,
  //         billboards: res.billboards
  //       })
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  // }

  componentDidUpdate(prevProps) {
    if (this.state.debug) {
      console.log("componentDidUpdate: Category component...");
    }
    if (this.props.location !== prevProps.location) {
      this.getProducts(this.props.match.params.id, this.props.match.params.page || 1);

    }
  }

  componentWillMount() {
    if (this.state.debug) {
      console.log("componentWillMount: Category component...");
    }
    let name = '';
    if (this.state.category_info !== undefined) {
      name = this.state.category_info.category_name;
    }

  }


  // componentWillReceiveProps(nextProps){
  //   if(nextProps.match.params.id==this.props.match.params.id){
  //     this.getProducts(this.props.match.params.id,this.props.match.params.page||1);
  //   }
  //   console.log("ALERT" + this.props.match.params.page);

  // }

  parseCategoryName(name) {
    var pattern = new RegExp("[^a-zA-Z0-9]+", "g");
    let parsedName = (name.replace(pattern, '-')).toLowerCase();
    return parsedName;
  }

  getProducts(categoryId, pageNumber) {
    let page;
    if (pageNumber < 1) {
      page = 1;
    } else {
      page = pageNumber
    }
    let covid = 0;
    if (categoryId == 7) {
      covid = 1;
    }
    this.serverApi.getCategoryProducts(categoryId, page, 40, null, covid)
      .then((res) => {
        this.setState({
          products: res.category_products,
          statistic: res.statistic,
          billboards: res.billboards,
          category_info: res.category_info
        });

        let siteName = 'LabMedica'
        if (this.context.siteTheme === 'hmi') {
          siteName = 'HospiMedica'
        }

        window.document.title = `${res.category_info.category_name} | ${siteName} Expo`;

        let id = this.props.match.params.id;
        let parsedName = this.parseCategoryName(res.category_info.category_name);
        let total_pages = res.statistic.total_pages;
        if (page == 1 && total_pages > 1) {
          this.configurationService.insertLinksToHead(undefined, `/category/${id}/${parsedName}/2`);
        } else if (page > 0 && page < total_pages) {
          this.configurationService.insertLinksToHead(`/category/${id}/${parsedName}/${(parseInt(page) - 1)}`, `/category/${id}/${parsedName}/${(parseInt(page) + 1)}`);
        } else if (page == total_pages && page != 1) {
          this.configurationService.insertLinksToHead(`/category/${id}/${parsedName}/${parseInt(page) - 1}`, undefined);
        }

      })
      .catch((error) => {
        console.log(error);
      })
  }

  componentWillUnmount() {

  }

  render() {
    let { language, userData, width } = this.props;
    let id = this.props.match.params.id;
    let page = parseInt(this.props.match.params.page || 1);
    let propsName = this.props.match.params.name;

    let name = '', covid = false;
    if (this.state.category_info !== undefined && this.state.category_info.category_id !== "7") {
      name = this.state.category_info.category_name;
    } else if (this.state.category_info !== undefined) {
      name = language.COVID;
      covid = true;
    }

    let paginationIndexArray = [];
    // let categoryName =this.props.location.state.name;
    for (let counter = 1; counter <= this.state.statistic.total_pages; counter++) {
      paginationIndexArray.push(counter);
    }

    let pagination = paginationIndexArray.map((element, index) => {

      let parsedName = this.parseCategoryName(name);
      if (this.state.statistic.total_pages > 10) {
        //render before current
        if (((page - index) <= 6) && (page > index)) {
          if ((page - index) == 6) {
            return (
              <Link
                key={index}
                className="pagination page"
                to={{ pathname: `/category/${id}/${parsedName}` }}
                onClick={this.scrollToTop}>{'<<'}
              </Link>
            );
          }
          if (page == element) {
            return (
              <Link
                key={index}
                className="pagination current"
                to={{
                  pathname: element != 1 ? `/category/${id}/${parsedName}/${element}` : `/category/${id}/${parsedName}`,

                }}
                onClick={this.scrollToTop}>{element}
              </Link>
            );
          } else {
            return (
              <Link
                key={index}
                className="pagination page"
                to={{
                  pathname: element != 1 ? `/category/${id}/${parsedName}/${element}` : `/category/${id}/${parsedName}`,

                }}
                onClick={this.scrollToTop}>{element}
              </Link>
            );
          }
        } else
          //redner after current 
          if (((page - index) >= -4) && (page <= index)) {
            if ((page - index) == -4) {
              return (
                <Link key={index} className="pagination page" to={`/category/` + id + "/" + parsedName + "/" + this.state.statistic.total_pages} onClick={this.scrollToTop}>{'>>'}</Link>
              );
            }
            if (page == element) {
              return (
                <Link
                  key={index}
                  className="pagination current"
                  to={{
                    pathname: element != 1 ? `/category/${id}/${parsedName}/${element}` : `/category/${id}/${parsedName}`,
                  }}
                  onClick={this.scrollToTop}>{element}
                </Link>
              );
            } else {
              return (
                <Link
                  key={index}
                  className="pagination page"
                  to={{
                    pathname: element != 1 ? `/category/${id}/${parsedName}/${element}` : `/category/${id}/${parsedName}`,
                  }}
                  onClick={this.scrollToTop}>{element}
                </Link>
              );
            }
          }

      } else if (this.state.statistic.total_pages != 1) {

        if (page == element) {
          return (
            <Link
              key={index}
              className="pagination current"
              to={{
                pathname: element != 1 ? `/category/${id}/${parsedName}/${element}` : `/category/${id}/${parsedName}`,
              }}
              onClick={this.scrollToTop}>{element}
            </Link>
          );
        } else {
          return (
            <Link
              key={index}
              className="pagination page"
              to={{
                pathname: element != 1 ? `/category/${id}/${parsedName}/${element}` : `/category/${id}/${parsedName}`,
              }}
              onClick={this.scrollToTop}>{element}
            </Link>
          );
        }

      } else {
        return null;
      }

    });
    //console.log(paginationIndexArray);
    return (
      <motion.div
        variants={PAGE_TRANSITION}
        exit='out'
        animate='in'
        initial='out'
        className="backgroundStyle"
      >
        <Helmet>
        {this.seoService.getCanonicalLink(`/category/${id}/${propsName}`)}
          {this.seoService.insertHrefLangTags(
            {
              path: `/category/:id/:name`,
              params: {
                id: id,
                name:propsName,
              }

            })
          }
        </Helmet>
        {/* <Header userData={userData} language={language} /> */}
        <TopSlider
          billboards={this.state.billboards}
        />
        <Search
          currentCategory={id}
          language={language}
          covidCategory={covid}
        />
        {/*    */}
        {/*<Breadcrumbs*/}
        {/*  isProductPage={false}*/}
        {/*/>*/}
        {/*<CategoriesList */}
        {/*currentCategory={id}*/}
        {/*categoryName = {name}*/}
        {/* />*/}
        {/* <FeaturedProducts categoryId={id} /> */}

        <CategoriesList
          language={language}
          currentCategory={id}
          width={width}
        />
        <div ref={this.scrollRef}></div>
        <ProductsGrid
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          isFeatured={false}
          userData={userData}
          products={this.state.products}
          page={page}
          limit={40}
          nameOfList={name}
          language={language}
          handleOnLogin={this.props.handleOnLogin}
        />
        {/* <RecentlyViewedProducts categoryId={id}/>
        <Trending categoryId={id}/> */}
        {/* pagination */}
        <div className="paginationLine">
          <div className="paginationBody">
            <div className="pagination">
              {pagination}
            </div>
          </div>
        </div>
        <SuppliersBar language={language} />
      </motion.div>

    )
  }
}

export default withRouter(Category);