import React from 'react';

import './product-company-logo-style.css';  
class ProductCompanyLogo extends React.Component {
    render() {
        const imgLogo= require('../../elements/images/Screenshot_2.png');
        return(
            <div className="productLogo"
            style={{  
              backgroundImage: "url(" + imgLogo + ")"
            }}
            >
              {/* <img src= {imgLogo} className = "imgLogo"></img> */}
            </div>
          )
    }
}

export default ProductCompanyLogo;