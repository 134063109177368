import React from 'react';
import ProductsGrid from '../products-grid';
import Search from '../search';
import SuppliersBar from '../suppliers-bar';
import ServerApi from '../..//services/server-api-service'
import ProductPageCompanyMenu from '../product-page-company-menu';
import TopSlider from '../top-slider';

import ConfigurationService from "../../services/configuration-service";
import './company-products-page-style.css';
import { withRouter } from "react-router";
import { prod } from '../../configuration/servers';
import { ThemeContext } from '../../elements/themes/themeContext';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import SeoService from '../../services/seo-service';


import PAGE_TRANSITION from '../../configuration/framer-configs/page-transition';


class CompanyProductsPage extends React.Component {
  state = {
    debug: false,
    company: {},
    products: [],
    // statistic: {
    //   total_pages: 0
    // }
  }
  serverApi = new ServerApi();
  configurationService = new ConfigurationService();
  seoService = new SeoService();


  static contextType = ThemeContext;
  componentDidMount() {
    if (this.state.debug) {
      console.log("componentDidMount: CompanyProductsPage component...");
    }
    this.getProducts(this.props.match.params.id);
    // this.getBillboards(this.props.match.params.id,false,6);
  }

  componentWillUnmount = () => {
    if (this.state.debug) {
      console.log("componentWillUnmount: CompanyProductsPage component...");
    }
    this.isComponentMounted = false;
  };

  componentDidUpdate(prevProps) {
    if (this.state.debug) {
      console.log("componentDidUpdate: CompanyProductsPage component...");
    }
    if (this.props.location !== prevProps.location) {
      this.getProducts(this.props.match.params.id);
      // this.getBillboards(this.props.match.params.id,false,6);
    }
  }

  componentWillMount() {
    if (this.state.debug) {
      console.log("componentWillMount: CompanyProductsPage component...");
    }
    this.configurationService.insertLinksToHead();
  }

  componentWillUpdate() {
    if (this.state.debug) {
      console.log("componentWillUpdate: CompanyProductsPage component...");
    }
    this.configurationService.insertLinksToHead();
  }


  // componentWillReceiveProps(nextProps){
  //   if(nextProps.match.params.id==this.props.match.params.id){
  //     this.getProducts(this.props.match.params.id,this.props.match.params.page);
  //   }
  //   console.log("ALERT" + this.props.match.params.page);

  // }
  getProducts(id) {
    //let page;
    // if(pageNumber<1){
    //   page = 1;
    // }else{
    //   page = pageNumber
    // }
    this.serverApi.getCompanyProducts(id)
      .then((res) => {
        this.setState({
          company: res.company,
          //products: res.company_products, 
          products: {
            company_products: res.company_products,
            company_products_by_categories: res.company_products_by_categories,
            company_products_from_lm: res.company_products_from_lm
          },
          billboards: res.billboards
        });
        let siteName = 'LabMedica';
        if (this.context.siteTheme === 'hmi') siteName = 'HospiMedica';
        window.document.title = `${res.company.company_name} | ${siteName} Expo`;
      })
      .catch((error) => {
        console.log(error);
        this.props.history.replace(document.location.pathname, '/not_found')
      })
  }

  // getBillboards(id,rand,limit){
  //   this.serverApi.getBillboards(id,rand,limit)
  //   .then((res)=>{
  //     if(res.billboards.length===0){
  //       this.serverApi.getBillboards()
  //       .then((res)=>{
  //         console.log(res)
  //         this.setState({
  //           ...this.state,
  //           billboards:res.billboards
  //         })
  //       })
  //       .catch((error)=>{
  //         console.log(error);
  //       })
  //     }else{
  //       this.setState({
  //         ...this.state,
  //         billboards:res.billboards
  //       })
  //     }

  //   })
  //   .catch((error)=>{
  //     console.log(error);
  //   })
  // }

  renderProducts() {
    let { products } = this.state;
    let { language, userData } = this.props;
    let res = [];
    if (products.company_products && !products.company_products_by_categories) {
      res.push(
        <ProductsGrid
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          nameOfList={this.state.company.company_name || ' ' + language.PRODUCTS}
          userData={userData}
          companyData={this.state.company}
          isFeatured={false}
          products={products.company_products}
          limit={40}
          showLogo={false}
          language={language}
          handleOnLogin={this.props.handleOnLogin}
        />
      )
    } else if (!products.company_products && products.company_products_by_categories) {
      for (const [key, value] of Object.entries(products.company_products_by_categories)) {
        res.push(
          <ProductsGrid
            country={this.props.country}
            establishmentTypes={this.props.establishmentTypes}
            nameOfList={value.title}
            userData={userData}
            companyData={this.state.company}
            isFeatured={false}
            products={value.products}
            limit={40}
            showLogo={false}
            language={language}
            handleOnLogin={this.props.handleOnLogin}
          />
        )
      }

    }

    // if (products.company_products_from_lm && this.configurationService.getSiteTheme() === 'hmi' ) {
    //   const labmedicaExpoLogo = require('../../elements/images/lm-expo-invert.png')
    //   let titleTag = <a href={`${document.location.href.replace('hospimedica','labmedica')}`}><img src={`${labmedicaExpoLogo}`} alt="LabMedica Expo"/></a>
    //   res.push(
    //     <ProductsGrid
    //       country={this.props.country}
    //       establishmentTypes={this.props.establishmentTypes}
    //       nameOfList={titleTag}
    //       userData={userData}
    //       companyData={this.state.company}
    //       isFeatured={false}
    //       products={products.company_products_from_lm}
    //       lmProducts={true}
    //       limit={40}
    //       showLogo={false}
    //       language={language}
    //       handleOnLogin={this.props.handleOnLogin}
    //     />
    //   )
    // }
    return (res)
  }

  render() {
    let {name,id} = this.props.match.params;
    let { language, userData, pageScroll } = this.props;
    let { products } = this.state;

    let mainCategoryId = 7;
    if (this.configurationService.getSiteTheme() === 'hmi') mainCategoryId = 0;
    // const { id, company_name, company_logo_url, company_rank, company_rank_id, company_description,products } = this.state.company;

    return (
      <motion.div
        variants={PAGE_TRANSITION}
        exit="out"
        animate="in"
        initial="out"
        className="backgroundStyle">
        <Helmet>
          {this.seoService.getCanonicalLink(`/company/${id}/${name}/products`)}
          {this.seoService.insertHrefLangTags(
            {
              path: `/company/:id/:name/products`,
              params: {
                id: id,
                name: name,
              }

            })
          }
        </Helmet>
        {/* <Header userData={userData} language={language} /> */}
        <TopSlider
          billboards={this.state.billboards}
        />
        <Search
          currentCategory={mainCategoryId}
          language={language} />
        {/*    */}
        {/* <Breadcrumbs
          page={'companyProducts'}
          companyData = {this.state.company}
        /> */}

        <ProductPageCompanyMenu
          pageScroll={pageScroll}
          currentItem={"products"}
          companyData={this.state.company}
          language={language}
        />


        {/* <ProductsGrid
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          nameOfList={this.state.company.company_name || ' ' +language.PRODUCTS}
          userData={userData}
          companyData={this.state.company}
          isFeatured={false}
          products={this.state.products}
          limit={40}
          showLogo={false}
          language={language}
          handleOnLogin = {this.props.handleOnLogin}
        /> */}
        {this.renderProducts()}
        <SuppliersBar language={language} />
      </motion.div>

    )
  }
}

export default withRouter(CompanyProductsPage);