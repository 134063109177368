import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons';

//style
import as from './product-overlay-style.css';
class ProductOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      modalIsVisible: false,
      // make_tm_request: {
      //   tm_product_id: 0,
      //   tm_user_message: ``,
      //   tm_request_type: 'product_info',
      // }
    }
  }

//   componentWillReceiveProps() {
//     this.setState({

//       make_tm_request: {
//         ...this.state.make_tm_request,
//         tm_product_id: this.props.productData.id || this.props.productData.product_id,
//         tm_user_message: `${this.props.language.SEND_ME_MORE_ABOUT}
// ${this.props.language.PRODUCT}: ${this.props.productData.title}
// ${this.props.language.MODEL}: ${this.props.productData.model}
//             `,
//       }
//     })
//   }


  // requestInformation = () => {
  //   this.serverApi.requestInformation(this.state.make_tm_request)
  //     .then((res) => {
  //       this.setState({ modalIsVisible: !this.state.modalIsVisible })
  //       return res.json();
  //     })
  // }


  render() {
    let { companyData, productData, language, userData } = this.props;
    let { modalIsVisible } = this.state;
    const parse = require('html-react-parser');

    let parsedAnonceDescription;
    if (productData.anonce_description) {
      parsedAnonceDescription = parse(productData.anonce_description);
    }

    let body;
    if (this.props.isVisible) {
      if (this.props.featured) {
        body = (
          <div className="overlayModal" >
            <div className="overlay" >
              <div className="featuredOverlay overlayBody" >
                <div className="overlayContactUs">
                  {/* <div className="contactUsbutton" onClick={this.props.showRequestInformation}>
                    {language.QUICK_INQUIRY}
                  </div> */}

                  {userData.id !== null ?
                    (
                      <div id='overlayQuickInquiry' className="descriptionReqButton contactUsbutton" onClick={this.props.requestInformation}
                      >
                        <div className="leftLine"></div>
                        <FontAwesomeIcon
                          className="descriptionReqIcon"
                          icon={faBolt}
                          color="white"
                        />
                        {language.FIRST_CLICK_INQUIRY}
                      </div>
                    ) :
                    <div id='overlayQuickInquiry' className="descriptionReqButton contactUsbutton" onClick={(e)=>{
                      e.preventDefault()
                      this.props.showRequestInformation(e)
                      }}>
                      <div className="leftLine"></div>

                      <FontAwesomeIcon
                        className="descriptionReqIcon"
                        icon={faBolt}
                        color="white"
                      />  {language.QUICK_INQUIRY}
                    </div>
                  }


                </div>
                <div className="overlayDescribe">
                  <div className="overlayProductTitle">
                    {productData.product_title}
                  </div>
                  {/* <div className="separator"></div> */}
                  <div>
                    {parsedAnonceDescription}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (this.props.recently) {
        body = (
          <div className="overlayModal" onClick={((event) => { event.stopPropagation() })}>
            <div className="overlay">
              <div className="recentlyOverlay overlayBody">
                <div className="overlayContactUs">
                  {/* <div className="contactUsbutton" onClick={this.props.showRequestInformation}>
                    {this.props.language.QUICK_INQUIRY}
                   </div> */}
                   {userData.id !== null ?
                    (
                      <div id='overlayQuickInquiry' className="descriptionReqButton contactUsbutton" onClick={(this.props.requestInformation)}
                      >
                        <div className="leftLine"></div>
                        <FontAwesomeIcon
                          className="descriptionReqIcon"
                          icon={faBolt}
                          color="white"
                        />
                        {language.FIRST_CLICK_INQUIRY}
                      </div>
                    ) :
                    <div id='overlayQuickInquiry' className="descriptionReqButton contactUsbutton" onClick={(e)=>{
                      e.preventDefault()
                      this.props.showRequestInformation(e)
                      }}>
                      <div className="leftLine"></div>

                      <FontAwesomeIcon
                        className="descriptionReqIcon"
                        icon={faBolt}
                        color="white"
                      />  {language.QUICK_INQUIRY}
                    </div>
                  }

                </div>
                <div className="overlayDescribe">
                  <div className="overlayProductTitle">
                    {productData.product_title}
                  </div>
                  {/* <div className="separator"></div> */}
                  <div>
                    {parsedAnonceDescription}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      else {
        body = (
          <div className="overlayModal">
            <div className="overlay">
              <div className="productOverlay overlayBody">
                <div className="overlayContactUs">
                  {/* <div className="contactUsbutton" onClick={this.props.showRequestInformation}>
                    {this.props.language.QUICK_INQUIRY}
                  </div> */}
                  {userData.id !== null ?
                    (
                      <div id='overlayQuickInquiry' className="descriptionReqButton contactUsbutton" onClick={(this.props.requestInformation)}
                      >
                        <div className="leftLine"></div>
                        <FontAwesomeIcon
                          className="descriptionReqIcon"
                          icon={faBolt}
                          color="white"
                        />
                        {language.FIRST_CLICK_INQUIRY}
                      </div>
                    ) :
                    <div id='overlayQuickInquiry' className="descriptionReqButton contactUsbutton" onClick={(e)=>{
                      e.preventDefault()
                      this.props.showRequestInformation(e)
                      }}>
                      <div className="leftLine"></div>

                      <FontAwesomeIcon
                        className="descriptionReqIcon"
                        icon={faBolt}
                        color="white"
                      />  {language.QUICK_INQUIRY}
                    </div>
                  }

                </div>
                <div className="overlayDescribe">
                  <div className="overlayProductTitle">
                    {productData.product_title}
                  </div>
                  {/* <div className="separator"></div> */}
                  <div>
                    {parsedAnonceDescription}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      body = (
        null
      )
    }
    return (
      <>
        {body}
       
      </>
    )
  }
}

export default ProductOverlay;