import React from 'react';
import { withRouter } from 'react-router'

import ServerApi from '../../services/server-api-service'
import FormCheckService from '../../services/form-check-service';
import RegisterModal from '../register-modal';
import LoginModal from '../login-modal';
import './request-price-style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion';
import MODAL_TRANSITION from '../../configuration/framer-configs/modal-transitions';

class RequestPrice extends React.Component {
    serverApi = new ServerApi();
    formCheckService = new FormCheckService();
    constructor(props) {
        super(props);

        this.state = {
            debug: false,
            loginFromRequest: false,
            needToLogin: false,
            needToRegister: false,
            loginForm: {
                email: '',
                passw: ''
            },
            make_tm_request: {
                tm_standard_message:true,
                tm_product_id: 0,
                tm_user_message: '',
                tm_request_price_value: null,
                tm_request_type: 'price_info',
                tm_user_phone:false
            }
        }

        this.closeModal = this.closeModal.bind(this);
        this.loginUser = this.loginUser.bind(this);
        this.requestPrice = this.requestPrice.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChangeLoginForm = this.handleInputChangeLoginForm.bind(this)
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let message_changes = false;
        if(name == 'tm_user_message') message_changes = true;
        this.setState({
            make_tm_request: {
                ...this.state.make_tm_request,
                tm_standard_message:!message_changes,
                [name]: value
            }
        });
    }
    handleInputChangeLoginForm(event) {
        this.setState({
            loginForm: {
                ...this.state.loginForm,
                [event.target.name]: event.target.value
            }
        });
        if (event.target.name == 'email') {
            this.serverApi.checkDbEmail(event.target.value)
                .then((res) => {
                    if (!res.status) {
                        this.setState({
                            needToLogin: true
                        })
                    } else {
                        this.setState({
                            needToLogin: false
                        })
                    }
                })
                .catch((error)=>{
                    console.log('Error fetch with checkDbEmail: ',error)
                  })
        }
    }

    closeModal() {
        this.props.сhangeIsVisible();
        this.setState({
            ...this.state,
            needToRegister: false
        })
    }

    componentDidMount() {
        if (this.state.debug) {
            console.log("componentDidMount: RequestPrice component...");
        }

    }

    componentWillUnmount() {
        if (this.state.debug) {
            console.log("componentWillUnmount : RequestPrice component...");
        }
    }

    componentWillReceiveProps() {
        let language = this.props.language;
        this.setState({
            make_tm_request: {
                ...this.state.make_tm_request,
                tm_product_id: this.props.productData.product_id,
                tm_user_message: `${language.REQUEST_PRICE_DEFAULT} 
${language.PRODUCT}: ${this.props.productData.title}
${language.MODEL}: ${this.props.productData.model}`
            }
        })
    }

    requestPrice() {
        this.serverApi.requestInformation(this.state.make_tm_request)
            .then((res) => {
                return res.json();
            }
            )
            .then((data) => {
                if (data.status === 'success') {
                    this.props.history.push({
                        pathname:`/thank_you/`,
                        state:{
                            id:this.props.productData.product_id,
                            name:this.props.productData.title,
                            model:this.props.productData.model,
                            companyId: this.props.companyData.id,
                            companyRank: this.props.companyData.company_rank_id,
                            categories: this.props.productData.product_categories
                        }
                    });
                } else {
                    alert(data.reason);
                }
            })
            .catch((error)=>{
                console.log('Error fetch with requestInformation: ',error)
              })
    }

    async loginUser() {

        let response = { status: false, message: '' };
        await this.serverApi.loginUser(this.state.loginForm)
            .then((response) =>
                response.json()
            )
            .then((result) => {
                console.log(result);
                if (result.state) {
                    this.setState({
                        ...this.state,
                        loginFromRequest: true,
                    })
                    response.status = true;
                    this.props.handleOnLogin();
                } else {
                    response.status = false;
                    response.message = result.error
                }
            })
            .catch((error)=>{
                console.log('Error fetch with loginUser: ',error)
              })
        return response;
    }

    submit = () => {
        let login = false;
        if (this.props.userData.id != null) {
            login = true;
        } else {
            login = false;
        }

        if (
            // !this.formCheckService.checkEmptyField(this.state.make_tm_request.tm_request_price_value, 'status.') &&
            !this.formCheckService.checkEmptyField(this.state.make_tm_request.tm_user_message, 'message.')) {
            if (login) {
                this.requestPrice();
            } else {
                if (!this.formCheckService.checkEmail(this.state.loginForm.email, 'correct email.')) {
                    this.serverApi.checkDbEmail(this.state.loginForm.email)
                        .then((res) => {
                            if (res.status === undefined) {
                                //block with login
                                this.loginUser()
                                    .then((data) => {
                                        if (data.status) {
                                            this.requestPrice();
                                        } else {
                                            alert(data.message);
                                        }
                                    })
                                //this.props.сhangeIsVisible();
                            } else {
                                //block of regisration
                                this.setState({
                                    ...this.state,
                                    needToRegister: true,
                                })
                            }
                        })
                        .catch((error)=>{
                            console.log('Error fetch with checkDbEmail: ',error)
                        })
                }
            }
        }
    }

    render() {
        const { company_name, logo_url, company_id, rang_company } = this.props.companyData || "";
        const {userData} = this.props;
        let language = this.props.language;
        let login = false;
        if (this.props.userData.id != null) {
            login = true;
        } else {
            login = false;
        }
        let modalForm;
        if (this.props.modalIsVisible) {

            let phoneBlock = null;
            if(!userData.phone){
                phoneBlock =(
                    <>
                        <div>
                            <label>{language.PHONE}:</label>
                            <input placeholder="phone" name='tm_user_phone' onChange={this.handleInputChange} />
                        </div>
                    </>
                )
            }

            if (!login) {
                modalForm = (
                    <>
                       <div>
                            <label>{language.EMAIL}</label>
                            <input placeholder="email@email.com" name="email" value={this.state.loginForm.email} onChange={this.handleInputChangeLoginForm} />
                        </div>
                        {phoneBlock}
                    </>
                )
            }
            else {
                modalForm = (
                    <>
                        {phoneBlock}
                    </>
                    //     <div>
                    //             <label>E-mail:</label>
                    //             <input placeholder="email@email.com" name="email" onChange={this.handleInputChangeLoginForm} value={this.props.userData.email} disabled />
                    // </div>

                )
            }
        } else {
            return modalForm = null;
        }

        let renderLoginsInputs;
        if (!this.state.loginFromRequest) {
            renderLoginsInputs = (
                <>
                    {modalForm}
                    <div className={this.state.needToLogin ? '' : 'inputHidden'}>
                        <span className="formMessage">{language.ALREADY_REGISTERED}</span>
                    </div>
                    <div className={this.state.needToLogin ? '' : 'inputHidden'}>
                        <label>{language.PASSWORD}:</label>
                        <input name="passw" type="password" value={this.state.loginForm.passw} onChange={this.handleInputChangeLoginForm} onBlur={this.loginUser} autocomplete="off"/>
                    </div>
                </>
            )
        } else {
            renderLoginsInputs = (
                <>
                    <tr>
                        <td></td>
                        <td>{language.LOGGED_IN_AS} {this.state.loginForm.email}</td>
                    </tr>
                </>
            )
        }

        let renderBody;
        if (!this.state.needToRegister) {
            //request
            renderBody = (
                <motion.div 
                    variants={MODAL_TRANSITION}
                exit="out"
                animate="in"
                initial="out"
                className="modalWindow modalRequest" 
                onClick={((event) => { event.stopPropagation() })}>
                    <div className="modalClose" onClick={() => this.closeModal()}><span><FontAwesomeIcon icon={faWindowClose} color="white" /></span></div>
                    <h2 className="modalHeader">{language.REQUEST_PRICE}</h2>
                    <div className="formBlock">
                        {renderLoginsInputs}
                        {/* <div>

                            <label> {language.STATUS} *: </label>
                            <select onChange={this.handleInputChange} value={this.state.make_tm_request.tm_request_price_value} name="tm_request_price_value" size="5" >
                                <option disabled>{language.SELECT_ONE}</option>
                                <option value={language.EXPANDING_MY_KNOWLEDGE}>{language.EXPANDING_MY_KNOWLEDGE}</option>
                                <option value={language.INVESTIGATING_TECHNOLOGIES}>{language.INVESTIGATING_TECHNOLOGIES}</option>
                                <option value={language.LOOKING_TO_BUY_WITHIN_1_YEAR}>{language.LOOKING_TO_BUY_WITHIN_1_YEAR}</option>
                                <option value={language.ACTIVELY_IN_THE_BUYING_PROCESS}>{language.ACTIVELY_IN_THE_BUYING_PROCESS}</option>
                            </select>
                        </div> */}
                        <div>
                            <label>{language.MESSAGE} *: </label>
                            <textarea cols="50" name='tm_user_message' onChange={this.handleInputChange} type="text" value={this.state.make_tm_request.tm_user_message}></textarea>
                        </div>
                        <input type="hidden" value="typeOfRequest" value="price_info"></input>
                        <input className="sendButton" type="submit" value={language.SEND_MESSAGE} onClick={() => { this.submit() }} />
                    </div>
                </motion.div>
            )
        } else {
            //register
            renderBody = null
        }

        return (
            <>
                <div className="modalShadow" onClick={(() => {
                    this.props.сhangeIsVisible();
                    this.setState({
                        ...this.state,
                        needToRegister: false
                    })
                })}>
                    {renderBody}

                </div>
                {this.state.needToRegister?(
                    <RegisterModal
                    handleOnLogin = { this.props.handleOnLogin}
                    request={true}
                    title={language.REQUEST_REGISTER}
                    email={this.state.loginForm.email}
                    country={this.props.country}
                    establishmentTypes={this.props.establishmentTypes}
                    isVisible={this.state.needToRegister}
                    closeModal={this.closeModal}
                    funcAtferRegister={this.requestPrice}
                    language= {language}
                />
                ):null}
            </>
        );
    }
}

export default withRouter(RequestPrice);