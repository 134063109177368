// Experemetal component
// Maybe we should reomove it in future

import React from "react";

export default class SelectAutosize extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selected: "default"
    };
  }
  setWidth = () => {
    const optionEle = document.getElementById("selectedOptionHidden");
    const width = optionEle.offsetWidth + 20; // padding width or arrows
    this.setState({ width: `${width}px` });
  };

  handleSelect = e => {
    this.setState({ selected: e.target.value }, () => {
      this.setWidth();
    });
  };

  findOption = (items, value) => {
    let foundItem;
    (items || []).map((item, index) => {
      if (item.value === value) {
        foundItem = item;
      }
    });
    return foundItem;
  };



  render() {
    const items = this.props.items;
    const selectedObj = this.findOption(items, this.state.selected);
    return (
      <React.Fragment>
        <select
          style={{ width: this.state.width || "auto" }}
          onChange={this.handleSelect}
          value={this.state.selected}
//          name={Options}
        >
          {items.map((item, index) => {
            return (
              <option key={`option:${item.value}`} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
        <div>
          <span style={{ visibility: "hidden", position: "absolute" }} id="selectedOptionHidden">
            {selectedObj.label}
          </span>
        </div>
      </React.Fragment>
    );
  }
}

