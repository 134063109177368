import React from 'react';
import ServerApi from '../../services/server-api-service'
import FormCheckService from '../../services/form-check-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import RegisterModal from '../register-modal';
import LoginModal from '../login-modal';

import { withRouter } from 'react-router'
import './first-click-inquiry-style.css';
class FirstClickInquiry extends React.Component {
    formCheckService = new FormCheckService();
    serverApi = new ServerApi();
    constructor(props) {
        super(props)
        this.state = {
            debug: false,
            modalIsVisible: false,
            make_tm_request: {
                tm_product_id: 0,
                tm_user_message: ``,
                tm_request_type: 'product_info',
            }
        }
        this.closeModal = this.closeModal.bind(this);
        this.requestInformation = this.requestInformation.bind(this);
    }

    componentWillReceiveProps() {
        this.setState({
            
            make_tm_request: {
                ...this.state.make_tm_request,
                tm_standard_message:true,
                tm_product_id: this.props.productData.id || this.props.productData.product_id,
                tm_user_message: `${this.props.language.SEND_ME_MORE_ABOUT}
${this.props.language.PRODUCT}: ${this.props.productData.title}
${this.props.language.MODEL}: ${this.props.productData.model}
                `,
            }
        })
    }

    closeModal() {
        this.props.сhangeIsVisible();
        this.setState({
            ...this.state,
            needToRegister: false
        })
    }

    requestInformation() {
        this.serverApi.requestInformation(this.state.make_tm_request)
            .then((res) => {
                this.setState({ modalIsVisible: !this.state.modalIsVisible })
                return res.json();
            })
    }

    render() {
        const { userData } = this.props;
        let { modalIsVisible } = this.state;
        let language = this.props.language;

        return (
            <>
                {userData.id !== null ?
                    (
                        <div className="descriptionReqButton" onClick={(() => { 
                            this.requestInformation();
                             })}>
                            <div className="leftLine"></div>
                            <FontAwesomeIcon
                                className="descriptionReqIcon"
                                icon={faBolt}
                                color="white"
                            />
                            {language.FIRST_CLICK_INQUIRY}
                        </div>
                    ) : null}
                {modalIsVisible &&
                    <div className="modalShadow" onClick={() => {
                        this.setState({
                            modalIsVisible: false
                        })
                    }}>
                        <div id="firstClickInquiry" className='modalWindow modalRequest '>
                            <div className="modalClose" onClick={(() => { this.props.сhangeIsVisible() })}><span><FontAwesomeIcon icon={faWindowClose} color="white" /></span></div>
                            <h2 className="modalHeader"> {language.REQUEST_INFORMATION}</h2>
                            <div className="formBlock">
                                {language.THANKS_FOR_INQUIRY}
                            </div>
                        </div>
                    </div>
                }

            </>
        );
    }
}

export default withRouter(FirstClickInquiry);