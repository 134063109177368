import React from 'react';
import './left-menu-style.css';

import CategoriesListModal from '../categories-list-modal';
import RecentlyViewedProducts from '../recently-viewed-products';
import ConfigurationService from '../../services/configuration-service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { ThemeContext } from '../../elements/themes/themeContext';
import { motion } from 'framer-motion';


const PAGE_TRANSITION = {
  in: {
    opacity: 1,
    transition: { delay: 0.5 }
  },
  out: {
    opacity: 0,
    transition: { delay: 0.5 }
  }
}

class LeftMenu extends React.Component {
  configurationService = new ConfigurationService()
  constructor(props) {
    super(props);
    this.state = {
      isVisibleCategories: false,
      isVisibleRecentlyProducts: false,
      isVisibleModal: false,
    }
    this.handleOnRecentlyProducts = this.handleOnRecentlyProducts.bind(this);
    this.handleOnCategories = this.handleOnCategories.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleOnCategories(event) {
    // this.setState({
    //   isVisibleCategories: !this.state.isVisibleCategories,
    //   isVisibleRecentlyProducts: false
    // })
    // event.preventDefault();
    this.closeModal();
    document.dispatchEvent(new CustomEvent('openModal', { detail: { name: 'isVisibleLeftMenuCategory', value: true } }))
  }

  handleOnRecentlyProducts(event) {
    // this.setState({
    //   isVisibleCategories: false,
    //   isVisibleRecentlyProducts: !this.state.isVisibleRecentlyProducts
    // })
    // event.preventDefault();
    this.closeModal();
    document.dispatchEvent(new CustomEvent('openModal', { detail: { name: 'isVisibleRecentlyProductsModal', value: true } }))

  }

  closeModal() {
    document.dispatchEvent(new CustomEvent('closeAllModals', {}))
    // this.setState({
    //   isVisibleCategories: false,
    //   isVisibleRecentlyProducts: false
    // })
  }



  renderLanguageSwitch() {
    if (document.location.href.includes('.com')) {
      return (
        <div className="MenuReturn MenuItem LeftMenuIconsLanguage" onClick={() => document.location.href = document.location.href.replace('.com', '.es')}>
          ES
        </div>
      )
    } else if (document.location.href.includes('.es')) {
      return (
        <div className="MenuReturn MenuItem LeftMenuIconsLanguage" onClick={() => document.location.href = document.location.href.replace('.es', '.com')}>
          EN
        </div>
      )
    }
  }

  static contextType = ThemeContext;
  render() {
    //const imgIcon= require('../../elements/images/Screenshot_3.png');

    // component turned off...
    // if (true) return null;

    const context = this.context;
    let siteUrl = window.location.protocol + "//" + window.location.hostname + "/";

    // if (this.configurationService.getCurrentLanguagePrefix() === 'es') {
    //   siteUrl = 'https://www.labmedica.es/';
    // }

    if (siteUrl.includes('onserv')) siteUrl = 'https://labmedica.com/'

    let recentlyButtonRender = null;
    //this.props.recentlyViewedProducts !== undefined && this.props.recentlyViewedProducts.length > 0
    if (this.props.recentlyViewedProducts !== undefined && this.props.recentlyViewedProducts.length > 0) {
      recentlyButtonRender = (
        <div className="MenuRecentlyViewed MenuItem" onClick={((event) => { event.stopPropagation() })}>
          <div>
            <FontAwesomeIcon icon={faHistory} onClick={this.handleOnRecentlyProducts} className="LeftMenuIcons" size="1x" color="white" />

          </div>
        </div>
      )
    } else {
      recentlyButtonRender = null;
    }

    return (
      <>
        {/* <div className={this.state.isVisibleCategories ? "modalShadow modalShadowLarge" : ''} onClick={this.closeModal}>

          <div className="MenuDiv" onClick={((event)=>{event.stopPropagation()})}>11
              <FontAwesomeIcon icon={faHistory} onClick ={this.handleOnRecentlyProducts} className="LeftMenuIcons" size="2x" color="white" />

              <RecentlyViewedProducts
                 isVisible={this.state.isVisibleRecentlyProducts}
                closeModal={this.closeModal}
                userData = {this.props.userData}
                language={this.props.language}
                handleOnLogin = {this.props.handleOnLogin}
              />

          </div>

        </div> */}

        <motion.div
          variants={PAGE_TRANSITION}
          exit="out"
          animate="in"
          initial="out"
          style={{ display: 'block' }}
          className={this.props.isVisibleRecentlyProductsModal ? "modalShadow modalShadowLarge" : ''}
          onClick={this.closeModal}>
          <div className="Menus">
            <div className="MenuDivSecond MenuItem" onClick={((event) => { event.stopPropagation(); })}>
              {/* <FontAwesomeIcon icon={faHistory} onClick ={this.handleOnRecentlyProducts} className="LeftMenuIcons" size="2x" color="white" /> */}

              <div className="MenuReturnText ReturnTextFirst" onClick={this.handleOnCategories}>{this.props.language.CATEGORIES}
                {/* <FontAwesomeIcon icon={faList} onClick={this.handleOnCategories} className="LeftMenuIcons" color="white" /> */}
              </div>
              {/* <img className="CatImage" src={catImage} width="21" height="123" onClick={this.handleOnCategories}></img> */}
              {/* <FontAwesomeIcon icon={faFile} className="LeftMenuIcons" size="2x" color="white" /> */}
              {/* <CategoriesListModal 
                isVisible={this.state.isVisibleCategories}
                closeModal={this.closeModal}
              /> */}
              <CategoriesListModal
                isVisible={this.props.isVisibleLeftMenuCategory}
                closeModal={this.closeModal}
              />
            </div>

            <div className="MenuReturn MenuItem">
              <a className="MenuReturnText" href={siteUrl}>{this.props.language.CLINICAL_LAB_NEWS}
                {/* <FontAwesomeIcon icon={faUndo} onClick={this.handleOnCategories} className="LeftMenuIcons" color="white" /> */}
              </a>
            </div>


            {recentlyButtonRender}
            <RecentlyViewedProducts
              isVisible={this.props.isVisibleRecentlyProductsModal}
              closeModal={this.closeModal}
              userData={this.props.userData}
              language={this.props.language}
              recentlyViewedProducts={this.props.recentlyViewedProducts}
              handleOnLogin={this.props.handleOnLogin}
            />

            {this.renderLanguageSwitch()}


          </div>


        </motion.div>

      </>
    )
  }

}

export default LeftMenu;