import React from 'react';
import Header from '../header';
import ProductPageDescription from '../product-page-description';
import ProductPageCompanyMenu from '../product-page-company-menu';
import CompanyProducts from '../company-products';
import { withRouter } from "react-router";
import ServerApi from '../../services/server-api-service';
import './product-page-style.css';
import TopSlider from '../top-slider';
import { Helmet } from "react-helmet";

import ConfigurationService from "../../services/configuration-service";
import StringProcessingService from '../../services/string-processing-service';
import { ThemeContext } from '../../elements/themes/themeContext';
import {motion} from 'framer-motion';
import PAGE_TRANSITION from '../../configuration/framer-configs/page-transition';
import SeoService from '../../services/seo-service';

class ProductPage extends React.Component {
  serverApi = new ServerApi();
  configurationService = new ConfigurationService();
  stringProcessingService = new StringProcessingService();
  seoService = new SeoService();

  static contextType = ThemeContext
  state = {
    debug: false,
    productData: null
  }
  componentDidUpdate(prevProps) {
    if(this.state.debug){
      console.log("componentDidUpdate: ProductPage component...");
    }
    if (this.props.location !== prevProps.location) {
      this.getInfo();
      this.props.getRecentlyProducts();
    }
  }

  componentDidMount(){
    if(this.state.debug){
      console.log("componentDidMount: ProductPage component...");
    }
    this.props.getRecentlyProducts();
    
  }

  componentWillUnmount = () => {
    if(this.state.debug){
      console.log("componentWillUnmount: ProductPage component...");
    }
    this.isComponentMounted = false;
  };
  
  insertSocialTags(){
      if(this.state.productData&&this.state.productData.images!=undefined){
        let product = this.state.productData;
        let parsedDescription = product.description.replace(new RegExp('"','g'),'\\"');
        parsedDescription= parsedDescription.replace(new RegExp('\n','g'),'')
        let {id,name} = this.props.match.params;
        let siteName = this.configurationService.getFullSiteName();
        return(
          <Helmet>
          {this.seoService.getCanonicalLink(`/product/${id}/${name}`)}
          {this.seoService.insertHrefLangTags(
            {
              path: `/product/:id/:name`,
              params: {
                id: id,
                name: name,
              }

            })
          }
            {/* twitter */}
            <meta name="twitter:creator" content="@TradeMedMarket"></meta>
            <meta name="twitter:title" content={`${product.title}| Medical Equipment and devices for hospitals or institutions | TradeMed`}></meta>
            <meta name="twitter:card" content="product"></meta>
            <meta name="twitter:description" content={`${parsedDescription}`}></meta>
            <meta name="twitter:image:src" content={`${product.images[0]}`}></meta>
          
            <meta name="twitter:data1" content={`${product.model}`}></meta>
            <meta name="twitter:label1" content="Model"></meta>
            <meta name="twitter:data2" content={product.is_newly_created==1?'New':''}></meta>
            <meta name="twitter:label2" content="Condition"></meta>
            <meta name="twitter:domain" content={`www.${siteName.charAt(0).toLowerCase()}${siteName.slice(1)}.com`}></meta>



            {/* facebook */}
            <meta property="og:title" content={`${product.title}| Medical Equipment and devices for hospitals or institutions | TradeMed`}></meta>
            <meta property="og:type" content="article"></meta>
            <meta property="og:url" content={document.URL}></meta>
            <meta property="og:image" content={`${product.images[0]}`}></meta>
            <meta property="og:description" content={`${parsedDescription}`}></meta>
            <meta property="og:site_name" content={`${siteName}`}></meta>

            {/* Google */}
            <link rel="amphtml" href={`${document.location.origin}/expo-amp/product/${this.props.match.params.id}/${this.props.match.params.name}`}/>
          </Helmet>
        )
      }
     
  }

  getInfo(){
    let id= this.props.match.params.id;
    this.serverApi.getProductInfo(id)
    .then((res)=>{
      if(this.state.debug){
        console.log(res);
      }
      if(res==null){this.props.history.push('/not_found')}
      this.setState({
        productData:res,
        billboards:res.billboards
      });
      window.document.title = `${res.title} | ${this.configurationService.getFullSiteName()} Expo`;
      //
      if(document.getElementById('productApplactionLd')!==null){
        document.getElementById('productApplactionLd').remove();
      }
      const jsonldScript = document.createElement('script');
      jsonldScript.setAttribute('type', 'application/ld+json'); 
      jsonldScript.setAttribute('id', 'productApplactionLd'); 
      let parsedDescription = res.description.replace(new RegExp('"','g'),'\\"');
      parsedDescription= parsedDescription.replace(new RegExp('\n','g'),'')
      const data= `{
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": "${res.title}",
        "image": "${res.images[0]}",
        "description": "${parsedDescription}",
    
        "brand":
        {
        "@type": "Thing",
        "name": "${res.company.company_name}"
        }
      }`
  
      jsonldScript.textContent = data;
      document.head.appendChild(jsonldScript);
    })
    .catch((error)=>{
      console.log(error);
      console.log(this.props.history)
      this.props.history.replace(document.location.pathname,'/not_found')
    })
  }
  componentWillMount() {
    if(this.state.debug){
      console.log("componentDidMount: productPage component...");
    }
    // const category_url = "http://www.labmedica.com.onserv/expo/test_json/productInfo.json";
    // //const category_url = "https://www.labmedica.com.axis1.net/expo/test_json/productInfo.json";
    // axios.get(category_url)
    //   .then(res => {
    //     //console.log(res.data);
    //     this.setState({ productData: res.data});
    //   })
    this.getInfo();
    this.configurationService.insertLinksToHead();
   
  }

  componentWillUpdate(){
    if(this.state.debug){
      console.log("componentWillUpdate: productPage component...");
    }
    this.configurationService.insertLinksToHead();
  }

  render() {

    let {language, pageScroll} = this.props;
    let productData = this.state.productData;

    if(productData == null) {
      return null;
    }
    return (
      <motion.div
        variants={PAGE_TRANSITION}
        exit="out"
        animate="in"
        initial="out"
      >
      
        {this.insertSocialTags()}
        {/* <Header userData={this.props.userData} language={language}/> */}
        <TopSlider
          billboards = {this.state.billboards}
        />
        {/* <Breadcrumbs
           companyData ={this.state.productData.company}
           productData ={this.state.productData}
           page={'productPage'}
        /> */}
        <ProductPageCompanyMenu
          companyData ={productData.company}
          language={language}
          pageScroll={pageScroll}
        />
        
        <ProductPageDescription
          userData = {this.props.userData}
          productData ={productData}
          companyData ={productData.company}
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          language={language}
          handleOnLogin = {this.props.handleOnLogin}
        />

        <CompanyProducts
          products={productData.more_products}
          companyData={productData.company}
          userData = {this.props.userData}
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          language={language}
          handleOnLogin = {this.props.handleOnLogin}
         />
      </motion.div>

    )
  }
}

export default  withRouter(ProductPage);