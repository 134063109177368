import React from 'react';
import ProductCompanyLogo from '../product-company-logo';
import ProductStatus from '../product-status';
import RequestInformation from '../request-information';
import ProductOverlay from '../product-overlay';
import StringProcessingService from '../../services/string-processing-service';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
//import ReactDOM from 'react-dom';
//custom copmonents
import SupplierStatus from '../supplier-status';
import ServerApi from '../../services/server-api-service';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFile,faWindowClose } from '@fortawesome/free-solid-svg-icons';
//style
import './product-style.css';
class Product extends React.Component {
  stringProcessingService = new StringProcessingService();
  serverApi = new ServerApi();

  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      isVisibleOveralay: false,
      modalIsVisibleFirstClick:false,
      make_tm_request: {
        tm_product_id: 0,
        tm_user_message: ``,
        tm_request_type: 'product_info',
      }
    }
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.hadleMouserOut = this.hadleMouserOut.bind(this);
    this.сhangeIsVisible = this.сhangeIsVisible.bind(this);
    this.showRequestInformation = this.showRequestInformation.bind(this);
    this.requestInformation = this.requestInformation.bind(this);
  }


  сhangeIsVisible(event) {
    this.setState({
      modalIsVisible: false,
    })
  }

  componentWillReceiveProps() {
    this.setState({

      make_tm_request: {
        tm_standard_message:true,
        ...this.state.make_tm_request,
        tm_product_id: this.props.data.id || this.props.data.product_id,
        tm_user_message: `${this.props.language.SEND_ME_MORE_ABOUT}
${this.props.language.PRODUCT}: ${this.props.data.title}
${this.props.language.MODEL}: ${this.props.data.model}
            `,
      }
    })
  }


  showRequestInformation(event) {
    event.preventDefault();
  //   document.dispatchEvent(new CustomEvent('openModal',{detail:{name:"isVisibleRequestInfo",value:true,
  // companyData:this.props.companyData,data:this.props.data}}))
    this.setState({
      modalIsVisible: true,
    })
  }
  requestInformation (event) {
    console.log(event)
    event.preventDefault();
    this.serverApi.requestInformation(this.state.make_tm_request)
      .then((res) => {
        this.setState({ modalIsVisibleFirstClick: !this.state.modalIsVisibleFirstClick })
        return res.json();
      })
      .catch((error)=>{
        console.log('Error fetch with requestInformation: ',error)
      })
  }

  getLinkToProduct(){
    const {data, companyData, userData,lmProduct = false} = this.props;
    let titleForUrl = this.stringProcessingService.StringFormatUrl(data.title);
    let titleForUrlModel = this.stringProcessingService.StringFormatUrl(data.model);
    if(lmProduct){
      let originLink = document.location.origin;
      return(`${originLink.replace('hospimedica','labmedica')}/expo/product/${data.product_id}/${titleForUrl.toLowerCase()}-model-${titleForUrlModel.toLowerCase()}`)
      //`/product/` + data.product_id+'/'+titleForUrl.toLowerCase()+'-model-'+titleForUrlModel.toLowerCase()
    }else{
      return(`product/` + data.product_id+'/'+titleForUrl.toLowerCase()+'-model-'+titleForUrlModel.toLowerCase())
    }
  }

  handleMouseOver() {
    //console.log("mouseover");
    this.setState({
      isVisibleOveralay: true
    })
  }

  hadleMouserOut() {
    this.setState({
      isVisibleOveralay: false
    })
  }
  render() {
    let language = this.props.language;
    const { showLogo = true, data, companyData, userData,lmProduct} = this.props;
    let {modalIsVisible,modalIsVisibleFirstClick} =this.state;
    let product;

    let status = () => {
      if (data.company != undefined) {
        return (
          <SupplierStatus
            rang={data.company.company_rank_id}
            language={language}
          />
        );
      } else {
        return null;
      }
    }

    let companyLogo = () => {
      if (showLogo) {
        if (data.company != undefined) {
          return (
            <div className="productLogo"
            // style={{
            //   backgroundImage: 'url(' + '"' + data.company.company_logo_url + '"' + ')'
            // }}
            >
              <img src={data.company.company_logo_url} alt={data.company.company_logo_url} />
            </div>
          );
        } else {
          return (
            <div className="productLogo"
            // style={{
            //   backgroundImage: 'url(' + '"' + companyData.company_logo_url + '"' + ')'
            // }}
            >
              <img src={companyData.company_logo_url} alt={companyData.company_name} />
            </div>
          )
        }
      }
      else return null;
    }

    let add_class_to_top = '';

    if (data.company != undefined) {
      if ((data.company.company_rank_id == 4) || (data.company.company_rank_id == 3)) {
        add_class_to_top = 'topBlockWsStatus';
      } else {
        add_class_to_top = '';
      }
    }

    if (this.state.debug) {
      console.log("add_class_to_top: " + add_class_to_top);
    }

    let newTitle = '';
    if (data.title) {
      newTitle = data.title.replace(/\//g, '/&#8203;');
    }

    const parse = require('html-react-parser');

    let parsedTitle = '';
    if (newTitle !== '') {
      parsedTitle = parse(newTitle);
    }
    let parsedModel='';
    if(data.model){
      parsedModel=parse(data.model);
    }

    // var pattern = new RegExp("[^a-zA-Z0-9]+", "g");
    // let titleForUrl = data.title.replace(pattern,'-');
    // let titleForUrlModel = data.model.replace(pattern,'-');
    let titleForUrl = this.stringProcessingService.StringFormatUrl(data.title);
    let titleForUrlModel = this.stringProcessingService.StringFormatUrl(data.model);
    let icons;
    if (data.documents || data.certificates || data.videos) {
      if (data.documents || data.certificates) {
        icons = (
          <React.Fragment>
            <FontAwesomeIcon
              className="descriptionNameIcon"
              icon={faFile}
              color="#494949"
            />
            {data.videos ?
              <FontAwesomeIcon
                className="descriptionNameIcon"
                icon={faVideo}
                color="#494949"
              />
              : null
            }
          </React.Fragment>
        )
      }

     
    }

    if (this.props.isFeatured) {
      product = (
        //`/product/` + data.product_id+'/'+titleForUrl.toLowerCase() +'-model-'+titleForUrlModel.toLowerCase()
        <Link to={this.getLinkToProduct()} className="productFeaturedBox">

          <div onMouseEnter={this.handleMouseOver} onMouseLeave={this.hadleMouserOut} className={`topBlock ${add_class_to_top}`}
          // style={{
          //   backgroundImage: 'url(' + '"' + data.product_image_url + '"' + ')'
          // }}
          >
            <ProductOverlay
              isVisible={this.state.isVisibleOveralay}
              requestInformation={this.requestInformation}
              showRequestInformation={this.showRequestInformation}
              featured={true}
              productData={data}
              companyData={companyData}
              userData={userData}
              language={language}
            />
            <img className="productImg" src={data.product_image_url || data.images[0]} />
            {/* <SupplierStatus
                rang={data.company.company_rank_id} /> */}
            {status()}
            <ProductStatus
              conditions={data.conditions}
              isFeatured={false}
              isNewlyCreated={data.is_newly_created}
              language={language}
            />
          </div>

          <div className="productData">
            {/* <div className="productLogo"
                style={{
                  backgroundImage: 'url(' + '"' + data.company.company_logo_url + '"' + ')'
                }}
              >
              </div> */}
            {companyLogo()}
            <div className="productText">
              {parsedTitle} {icons}
              <div className="productModel">{parsedModel} </div>
            </div>
          </div>

        </Link>
      )


    } else if (this.props.isRecently) {
      product = (
        //`/product/` + data.product_id+'/'+titleForUrl.toLowerCase()+'-model-'+titleForUrlModel.toLowerCase()
        <Link to={this.getLinkToProduct()} className="productRecentlyBox">
          <div onMouseEnter={this.handleMouseOver} onMouseLeave={this.hadleMouserOut} className={`topBlock ${add_class_to_top}`}
          // style={{
          //   backgroundImage: 'url(' + '"' + data.product_image_url + '"' + ')'
          // }}
          >

            <ProductOverlay
              isVisible={this.state.isVisibleOveralay}
              showRequestInformation={this.showRequestInformation}
              featured={false}
              requestInformation={this.requestInformation}
              recently={this.props.isRecently}
              productData={data}
              companyData={companyData}
              userData={userData}
              language={language}
            />
            <img className="productImg" src={data.product_image_url || data.images[0]} />
            {/* <SupplierStatus
                rang={data.company.company_rank_id} /> */}
            {status()}
            <ProductStatus
              conditions={data.conditions}
              isFeatured={false}
              isNewlyCreated={data.is_newly_created}
              language={language}
            />
          </div>

          <div className="productData">
            {/* <div className="productLogo"
                style={{
                  backgroundImage: 'url(' + '"' + data.company.company_logo_url + '"' + ')'
                }}
              >
              </div>  */}
            {companyLogo()}
            <div className="productText">
              {parsedTitle} {icons}
              <div className="productModel">{parsedModel} </div>
            </div>
          </div>

        </Link>
      )
    }
    else {
      product = (
        <a href={this.getLinkToProduct()} className="productBox" target={lmProduct&&'_blank'}>{showLogo}
          <div onMouseEnter={this.handleMouseOver} onMouseLeave={this.hadleMouserOut} className={`topBlock ${add_class_to_top}`}
          // style={{
          //   backgroundImage: 'url(' + '"' + data.product_image_url + '"' + ')'
          // }}
          >
            <ProductOverlay
              isVisible={this.state.isVisibleOveralay}
              showRequestInformation={this.showRequestInformation}
              featured={false}
              requestInformation={this.requestInformation}
              productData={data}
              companyData={companyData}
              userData={userData}
              language={language}

            />
            <img className="productImg" src={data.product_image_url || data.images[0]} />
            {/* <SupplierStatus
                rang={data.company.company_rank_id} /> */}
            {status()}
            <ProductStatus
              conditions={data.conditions}
              isFeatured={false}
              isNewlyCreated={data.is_newly_created}
              language={language}
            />
          </div>
          <div className="productData">
            {/* <div className="productLogo"
                style={{
                  backgroundImage: 'url(' + '"' + data.company.company_logo_url + '"' + ')'
                }}
              >
              </div> */}
            {companyLogo()}
            <div className="productText">
              {parsedTitle} {icons}
              <div className="productModel">{parsedModel} </div>
            </div>
          </div>
        </a>
      )
    }
    return (
      <>
        {product}
        <RequestInformation
          quickInquiry ={true}
          productData={data}
          companyData={companyData}
          userData={userData}
          modalIsVisible={this.state.modalIsVisible}
          сhangeIsVisible={this.сhangeIsVisible}
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          language={this.props.language}
          handleOnLogin={this.props.handleOnLogin}
        />
         {modalIsVisibleFirstClick &&
          <div className="modalShadow" onClick={() => {
            this.setState({
              modalIsVisibleFirstClick: false
            })
          }}>
            <div id="firstClickInquiry" className='modalWindow modalRequest '>
              <div className="modalClose" onClick={(() => { this.setState({ modalIsVisibleFirstClick: false }) })}><span><FontAwesomeIcon icon={faWindowClose} color="white" /></span></div>
              <h2 className="modalHeader"> {language.REQUEST_INFORMATION}</h2>
              <div className="formBlock">
                {language.THANKS_FOR_INQUIRY}
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default Product;