import React from 'react';
import ServerApi from '../../services/server-api-service'
import FormCheckService from '../../services/form-check-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

import RegisterModal from '../register-modal';
import LoginModal from '../login-modal';

import { withRouter } from 'react-router'
import './request-information-style.css';
import { motion } from 'framer-motion';
import MODAL_TRANSITION from '../../configuration/framer-configs/modal-transitions';

class RequestInformation extends React.Component {
    formCheckService = new FormCheckService();
    serverApi = new ServerApi();
    constructor(props) {
        super(props)
        this.state = {
            debug: false,
            loginForm: {
                email: '',
                passw: ''
            },
            // registerForm: {
            //     task: "subscribe",
            //     user_gt_firstname: '',
            //     user_gt_lastname: ' ',
            //     user_gt_company: '',
            //     user_gt_country: 'Afghanistan',
            //     user_email: '',
            //     user_email_repeat: '',
            //     subs_establishment_type: 'Hospital Laboratory',
            //     user_username: '',
            //     user_password: '',
            //     password_repeat: '',
            //     user_newsletters: false,
            //     //user_newsletters_switch: true,
            // },
            loginFromRequest: false,
            needToLogin: false,
            needToRegister: false,
            modalRegisterIsVisible: false,
            modalLoginIsVisible: false,
            make_tm_request: {
                tm_standard_message:true,
                tm_product_id: 0,
                tm_user_message: ``,
                tm_request_type: 'product_info',
                tm_user_phone:false
            }
        }
        this.closeModal = this.closeModal.bind(this);
        this.loginUser = this.loginUser.bind(this);
        this.requestInformation = this.requestInformation.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeLoginForm = this.handleInputChangeLoginForm.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let message_changes = false;
        if(name == 'tm_user_message') message_changes = true;
        this.setState({
            make_tm_request: {
                ...this.state.make_tm_request,
                tm_standard_message:!message_changes,
                [name]: value
            }
        });
    }

    handleInputChangeLoginForm(event) {
        this.setState({
            loginForm: {
                ...this.state.loginForm,
                [event.target.name]: event.target.value
            }
        });
        if (event.target.name == 'email') {
            this.serverApi.checkDbEmail(event.target.value)
                .then((res) => {
                    if (!res.status) {
                        this.setState({
                            needToLogin: true
                        })
                    } else {
                        this.setState({
                            needToLogin: false
                        })
                    }
                })
                .catch((error)=>{
                    console.log('Error fetch with checkDbEmail: ',error)
                  })
        }
    }

    componentDidMount() {
        if (this.state.debug) {
            console.log("componentDidMount: RequestInformation component...");
        }

    }

    componentWillUnmount() {
        if (this.state.debug) {
            console.log("componentWillUnmount : RequestInformation component...");
        }
    }


    componentWillReceiveProps() {
        this.setState({

            make_tm_request: {
                ...this.state.make_tm_request,
                tm_standard_message:true,
                tm_product_id: this.props.productData.id || this.props.productData.product_id,
                tm_user_message: `${this.props.language.SEND_ME_MORE_ABOUT}
${this.props.language.PRODUCT}: ${this.props.productData.title}
${this.props.language.MODEL}: ${this.props.productData.model}
                `,
            }
        })
    }

    closeModal() {
        this.props.сhangeIsVisible();
        this.setState({
            ...this.state,
            needToRegister: false
        })
    }

    requestInformation() {
        this.serverApi.requestInformation(this.state.make_tm_request)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data.status === 'success') {
                    if (this.props.quickInquiry) {
                        alert(this.props.language.MESSAGE_SENT);
                        window.location.reload(false);
                    } else {
                        this.props.history.push({
                            pathname: `/thank_you/`,
                            state: {
                                id: this.props.productData.product_id,
                                name: this.props.productData.title,
                                model: this.props.productData.model,
                                companyId: this.props.companyData.id,
                                companyRank: this.props.companyData.company_rank_id,
                                categories: this.props.productData.product_categories
                            }
                        });
                    }

                } else {
                    alert(data.reason);
                }
            })
    }

    async loginUser() {
        let response = { status: false, message: '' };
        await this.serverApi.loginUser(this.state.loginForm)
            .then((response) =>
                response.json()
            )
            .then((result) => {
                console.log(result);
                if (result.state) {
                    this.setState({
                        ...this.state,
                        loginFromRequest: true,
                    })
                    response.status = true;
                    this.props.handleOnLogin();
                } else {
                    response.status = false;
                    response.message = result.error
                }
            })
            .catch((error)=>{
                console.log('Error fetch with loginUser: ',error)
              })
        return response;
    }


    submit = (login) => {

        if (//!this.formCheckService.checkEmail(this.state.email, 'correct email.') &&
            !this.formCheckService.checkEmptyField(this.state.make_tm_request.tm_user_message, 'message.')) {
            if (login) {
                this.requestInformation();
            } else {
                if (!this.formCheckService.checkEmail(this.state.loginForm.email, 'correct email.')) {
                    this.serverApi.checkDbEmail(this.state.loginForm.email)
                        .then((res) => {
                            if (res.status === undefined) {
                                //block with login
                                this.loginUser()
                                    .then((data) => {
                                        if (data.status) {
                                            this.requestInformation();
                                        } else {
                                            alert(data.message);
                                        }
                                    })
                                //event.preventDefault();
                            } else {
                                //block of regisration
                                this.setState({
                                    ...this.state,
                                    needToRegister: true,
                                })
                            }
                        })
                        .catch((error)=>{
                            console.log('Error fetch with checkDbEmail: ',error)
                          })
                }
            }
        }


    }

    render() {
        const { company_name, logo_url, company_id, rang_company } = this.props.companyData || "";
        const {userData} = this.props;
        let language = this.props.language;

        let login = false;
        if (this.props.userData.id != null) {
            login = true;
        } else {
            login = false;
        }

        let countryList;
        if (this.props.country) {
            countryList = this.props.country.map((element, index) => {
                return (
                    <option key={index}>{element.name}</option>
                )
            })
        }
        let establishmentTypes
        if (this.props.establishmentTypes) {
            establishmentTypes = this.props.establishmentTypes.map((element, index) => {
                return (
                    <option key={index}>{element.desc}</option>
                )
            })
        }

        let modalForm;
        if (this.props.modalIsVisible) {

            let phoneBlock = null;
            if(!userData.phone){
                phoneBlock =(
                    <>
                        <div>
                            <label>{language.PHONE}:</label>
                            <input placeholder="phone" name='tm_user_phone' onChange={this.handleInputChange} />
                        </div>
                    </>
                )
            }

            if (!login) {
                modalForm = (
                    <>
                        <div>
                            <label>{language.EMAIL}:</label>
                            <input placeholder="email" name='email' onChange={this.handleInputChangeLoginForm} />
                        </div>
                        {phoneBlock}
                    </>
                )
            }
            else {
                modalForm = (
                    <>
                        {phoneBlock}
                    </>
                )
            }
        } else {
            return modalForm = null;
        }

        let renderBody;
        if (!this.state.needToRegister) {
            //request

            let renderLoginsInputs;
            if (!this.state.loginFromRequest) {
                renderLoginsInputs = (
                    <>
                        {modalForm}
                        <div className={this.state.needToLogin ? '' : 'inputHidden'}>
                            <span className="formMessage">{language.ALREADY_REGISTERED}</span>
                        </div>

                        <div className={this.state.needToLogin ? '' : 'inputHidden'}>
                            <label>{language.PASSWORD} *:</label>
                            <input name="passw" type="password" value={this.state.loginForm.passw} onChange={this.handleInputChangeLoginForm} onBlur={this.loginUser} autoComplete="off" />
                        </div>
                    </>
                )
            } else {
                renderLoginsInputs = (
                    <div>{language.LOGGED_IN_AS} {this.state.loginForm.email}</div>
                )
            }
            renderBody = (
                <motion.div 
                variants={MODAL_TRANSITION}
                exit="out"
                animate="in"
                initial="out"
                className="modalWindow modalRequest modalInfo" 
                onClick={((event) => { event.stopPropagation() })}>
                    <div className="modalClose" onClick={(() => { this.props.сhangeIsVisible() })}><span><FontAwesomeIcon icon={faWindowClose} color="white" /></span></div>
                    <h2 className="modalHeader"> {language.REQUEST_INFORMATION}</h2>
                    <div className="formBlock">
                        {renderLoginsInputs}
                        <div className="textLine">
                            {language.TO}: &nbsp;<span>{company_name}</span>
                        </div>
                        <div>
                            <label>{language.MESSAGE} *:</label>
                            <textarea name='tm_user_message' onChange={this.handleInputChange} type="text" value={this.state.make_tm_request.tm_user_message}></textarea>
                        </div>
                        <input className="sendButton" type="submit" value={language.SEND_MESSAGE} form="register" onClick={() => { this.submit(login) }} />
                    </div>


                    {/*<button className="sendButton" onClick={() => { this.submit(login) }}>*/}
                    {/*    send message to supplier*/}
                    {/*</button>*/}
                </motion.div>
            )
        }


        return (
            <>
                <div className="modalShadow" onClick={() => {
                    this.props.сhangeIsVisible();
                    this.setState({
                        ...this.state,
                        needToRegister: false
                    })
                }}>
                    {renderBody}
                </div>
                {this.state.needToRegister ?
                    <RegisterModal
                        handleOnLogin={this.props.handleOnLogin}
                        request={true}
                        title={language.REQUEST_REGISTER}
                        email={this.state.loginForm.email}
                        country={this.props.country}
                        establishmentTypes={this.props.establishmentTypes}
                        isVisible={this.state.needToRegister}
                        closeModal={this.closeModal}
                        funcAtferRegister={this.requestInformation}
                        language={language}
                    /> : (null)}

            </>
        );
    }
}

export default withRouter(RequestInformation);