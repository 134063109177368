import React from 'react';
import CategoriesList from '../categories-list';
import ProductsGrid from '../products-grid';
import TopSlider from '../top-slider';
import Search from '../search';
import SuppliersBar from '../suppliers-bar';
import ServerApi from '../..//services/server-api-service'
import './home-style.css';
import { Helmet } from 'react-helmet';
import SeoService from '../../services/seo-service';

import ConfigurationService from "../../services/configuration-service";
import { ThemeContext } from '../../elements/themes/themeContext';
import { motion } from 'framer-motion';

const PAGE_TRANSITION = {
  in: {
    opacity: 1,
    transition: { delay: 0.3 }
  },
  out: {
    opacity: 0,
    transition: { delay: 0.3 }
  }
}

class Home extends React.Component {
  state = {
    debug: false
  }
  configurationService = new ConfigurationService();
  serverApi = new ServerApi();
  seoService = new SeoService();


  getProductsForMain = () => {
    this.serverApi.getProductsForMain()
      .then((res) => {
        if (this.state.debug) {
          console.log(res);
        }
        this.setState({
          // covidProducts: res.covid_19_products,
          pocProducts: res.poc_products,
          featuredProducts: res.featured_products,
          trendingProducts: res.trending_products,
          billboards: res.billboards,
        });
      })
      .catch((error) => {
        console.log(error);
      })
  }

  componentDidMount() {

    if (this.state.debug) {
      console.log("componentDidMount: Home component...");
    }
    if (this.context.siteTheme === 'hmi') window.document.title = "HospiMedica Expo";
    else window.document.title = "LabMedica Expo";
    this.getProductsForMain();

    this.configurationService.insertLinksToHead();

  }

  componentWillUpdate() {
    if (this.state.debug) {
      console.log("componentWillUpdate: Home component...");
    }
    this.configurationService.insertLinksToHead();
  }
  componentWillMount() {
    if (this.state.debug) {
      console.log("componentWillMount: Home component...");
    }
    // this.serverApi.getBillboards()
    // .then((res)=>{
    //   console.log(res)
    //   this.setState({
    //     ...this.state,
    //     billboards:res.billboards
    //   })
    // })
    // .catch((error)=>{
    //   console.log(error);
    // })
  }

  componentWillUnmount = () => {
    if (this.state.debug) {
      console.log("componentWillUnmount: Home component...");
    }
    this.isComponentMounted = false;
  };
  // componentWillReceiveProps(nextProps){
  //   if(nextProps.match.params.id==this.props.match.params.id){
  //     this.getProducts(this.props.match.params.id,this.props.match.params.page);
  //   }
  //   console.log("ALERT" + this.props.match.params.page);

  // }
  static contextType = ThemeContext
  render() {
    

    let {language,handleOnLogin,country,establishmentTypes,userData} = this.props;
    let {pocProducts,billboards,featuredProducts,trendingProducts} = this.state;

    let mainCategoryId = 7;
    if (this.configurationService.getSiteTheme() === 'hmi') mainCategoryId = 0;
    const context = this.context;
    return (

      <motion.div
        variants={PAGE_TRANSITION}
        exit="out"
        animate="in"
        initial="out"
        className="backgroundStyle"
      >
        <Helmet>
          {this.seoService.getCanonicalLink('/')}
          {this.seoService.insertHrefLangTags(
            {
              path: `/`,

            })
          }
        </Helmet>
        {/* <Header 
        userData={this.props.userData} 
        language={language}
        isVisibleHeaderMenu = {this.props.isVisibleHeaderMenu}
        /> */}
        <TopSlider
          billboards={billboards}
        />
        <Search
          currentCategory={mainCategoryId}
          language={language}
        />
        <CategoriesList
          language={language}
        />
        <ProductsGrid
          userData={userData}
          isFeatured={true}
          nameOfList={language.FEATUDERED_PRODUCTS}
          products={featuredProducts}
          country={country}
          establishmentTypes={establishmentTypes}
          language={language}
          handleOnLogin={handleOnLogin}
        />
       <ProductsGrid
            userData={userData}
            isFeatured={false}
            nameOfList={language.POINT_OF_CARE_TESTING}
            products={pocProducts}
            country={country}
            establishmentTypes={establishmentTypes}
            language={language}
            handleOnLogin={handleOnLogin}
          />


        <ProductsGrid
          userData={userData}
          isFeatured={false}
          nameOfList={language.TRENDING}
          products={trendingProducts}
          country={country}
          establishmentTypes={establishmentTypes}
          language={language}
          handleOnLogin={handleOnLogin}
        />
        {/* <RecentlyViewedProducts categoryId={2} />
              <Trending categoryId={3}/> */}
        <SuppliersBar language={language} />
      </motion.div>

    )
  }
}

export default Home;