import React from 'react';
import ProductsGrid from '../products-grid';
import Search from '../search';
import SuppliersBar from '../suppliers-bar';
import Header from '../header';
import TopSlider from '../top-slider';
import ServerApi from '../..//services/server-api-service'

import ConfigurationService from "../../services/configuration-service";
import StringProcessingService from '../../services/string-processing-service';
import './search-page-style.css';
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import { withRouter } from "react-router";
import { ThemeContext } from '../../elements/themes/themeContext';
import { toHaveStyle } from '@testing-library/jest-dom';
import { motion } from 'framer-motion';
import PAGE_TRANSITION from '../../configuration/framer-configs/page-transition';
import { Helmet } from 'react-helmet';
import SeoService from '../../services/seo-service';

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      products: [],
      statistic: {
        total_pages: 0
      }
    }
    this.scrollRef = React.createRef();
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  serverApi = new ServerApi();
  configurationService = new ConfigurationService();
  stringProcessingService = new StringProcessingService();
  seoService = new SeoService();

  static contextType = ThemeContext;
  componentDidMount() {
    if (this.state.debug) {
      console.log("componentDidMount: Category component...");
    }
    this.searchItems(this.props.match.params.categoryId, this.props.match.params.request, this.props.match.params.page || 1);
    //this.getBillboards();
  }
  componentWillMount() {
    if (this.state.debug) {
      console.log("componentWillMount: Category component...");
    }
    let siteName = 'LabMedica'
    if (this.context.siteTheme === 'hmi') siteName = 'HospiMedica'
    window.document.title = `${siteName} Expo`;
    this.configurationService.insertLinksToHead();

  }

  componentWillUnmount = () => {
    if (this.state.debug) {
      console.log("componentWillUnmount: Category component...");
    }
    this.isComponentMounted = false;
  };

  componentDidUpdate(prevProps) {
    if (this.state.debug) {
      console.log("componentDidUpdate: Category component...");
    }
    if (this.props.location !== prevProps.location) {
      this.searchItems(this.props.match.params.categoryId, this.props.match.params.request, this.props.match.params.page || 1);
      //this.getBillboards();
    }
  }

  componentWillUpdate() {
    if (this.state.debug) {
      console.log("componentWillUpdate: Category component...");
    }
    this.configurationService.insertLinksToHead();
  }

  // componentWillReceiveProps(nextProps){
  //   if(nextProps.match.params.id==this.props.match.params.id){
  //     this.getProducts(this.props.match.params.id,this.props.match.params.page);
  //   }
  //   console.log("ALERT" + this.props.match.params.page);

  // }

  scrollToTop() {
    window.scrollTo({
      top: this.scrollRef.current.offsetTop - 55,
      left: 0,
      behavior: 'smooth'
    });
  }

  // getBillboards(){
  //   this.serverApi.getBillboards()
  //   .then((res)=>{
  //     console.log(res)
  //     this.setState({
  //       ...this.state,
  //       billboards:res.billboards
  //     })
  //   })
  //   .catch((error)=>{
  //     console.log(error);
  //   })
  // }

  searchItems(categoryId, request, pageNumber) {
    let page;
    if (pageNumber < 1) {
      page = 1;
    } else {
      page = pageNumber
    }
    let covid = 0;
    let option = this.props.match.params.option;
    if (option === 'covid19') {
      covid = 1;
    }
    this.serverApi.search(categoryId, request, page, 40, null, covid)
      .then((res) => {
        this.setState({
          products: res.found_products,
          statistic: res.statistic,
          billboards: res.billboards
        });

        if (res.found_companies_items === 1 && parseInt(res.found_companies[0].company_rank_id) >= 2) {
          this.props.history.push(`/company/${res.found_companies[0].id}/${this.stringProcessingService.StringFormatUrl(res.found_companies[0].company_name)}/products`);
        }
        let categoryId = this.props.match.params.categoryId;
        let total_pages = res.statistic.total_pages;
        if (page == 1 && total_pages > 1) {
          this.configurationService.insertLinksToHead(undefined, `/search/${categoryId}/${request}/2`);
        } else if (page > 0 && page < total_pages) {
          this.configurationService.insertLinksToHead(`/search/${categoryId}/${request}/${(parseInt(page) - 1)}`, `/search/${categoryId}/${request}/${(parseInt(page) + 1)}`);
        } else if (page == total_pages && page != 1) {
          this.configurationService.insertLinksToHead(`/search/${categoryId}/${request}/${parseInt(page) - 1}`, undefined);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.history.replace(document.location.pathname, '/not_found')
      })
  }

  componentWillUnmount() {

  }

  render() {
    let language = this.props.language;
    let userData = this.props.userData;
    //covid,used

    let {option,categoryId,request} = this.props.match.params
    let addOption = '';
    if (option !== undefined) {
      addOption = `/${option}`
    }
    let page = parseInt(this.props.match.params.page) || 1;
    let paginationIndexArray = [];
    for (let counter = 1; counter <= this.state.statistic.total_pages; counter++) {
      paginationIndexArray.push(counter);
    }

    let pagination = paginationIndexArray.map((element, index) => {
      if (this.state.statistic.total_pages > 10) {
        //render before current

        if (((page - index) <= 6) && (page > index)) {
          if ((page - index) == 6) {
            return (
              <Link
                key={index}
                className="pagination page"
                to={`/search/${categoryId}/${request}/1`}
                onClick={this.scrollToTop}>{'<<'}</Link>
            );
          }
          if (page == element) {
            return (
              <Link
                key={index}
                className="pagination current"
                to={{
                  pathname: `/search/${categoryId}/${request}/${element}${addOption}`
                }}
                onClick={this.scrollToTop}>{element}</Link>
            );
          } else {
            return (
              <Link
                key={index}
                className="pagination page"
                to={{
                  pathname: `/search/${categoryId}/${request}/${element}${addOption}`
                }}
                onClick={this.scrollToTop}>{element}</Link>
            );
          }
        } else
          //redner after current 
          if (((page - index) >= -4) && (page <= index)) {
            if ((page - index) == -4) {
              return (
                <Link
                  key={index}
                  className="pagination page"
                  to={`/search/${categoryId}/${request}/${this.state.statistic.total_pages}${addOption}`}
                  onClick={this.scrollToTop}>{'>>'}</Link>
              );
            }
            if (page == element) {
              return (
                <Link
                  key={index}
                  className="pagination current"
                  to={{
                    pathname: `/search/${categoryId}/${request}/${element}${addOption}`
                  }}
                  onClick={this.scrollToTop}>{element}</Link>
              );
            } else {
              return (
                <Link
                  key={index}
                  className="pagination page"
                  to={{
                    pathname: `/search/${categoryId}/${request}/${element}${addOption}`
                  }}
                  onClick={this.scrollToTop}>{element}</Link>
              );
            }
          }

      } else if (this.state.statistic.total_pages != 1) {

        if (page == element) {
          return (
            <Link
              key={index}
              className="pagination current"
              to={{
                pathname: `/search/${categoryId}/${request}/${element}${addOption}`
              }}
              onClick={this.scrollToTop}>{element}</Link>
          );
        } else {
          return (
            <Link
              key={index}
              className="pagination page"
              to={{
                pathname: `/search/${categoryId}/${request}/${element}${addOption}`
              }}
              onClick={this.scrollToTop}>{element}</Link>
          );
        }

      }

    });
    let resultRender;
    if (this.state.statistic.found_items == 0) {
      resultRender = (
        <div className="productsLine">
          <div className="productsBody">
            {language.NO_RESULT}: {request}
          </div>
        </div>
      )
    } else {
      resultRender = (
        <ProductsGrid
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          nameOfList={`${language.RESULT_OF_SEARCH} <em><b>${request}</b></em>`}
          userData={userData}
          isFeatured={false}
          products={this.state.products}
          page={page}
          limit={40}
          language={language}
          handleOnLogin={this.props.handleOnLogin}
        />
      );
    }
    return (
      <motion.div
        variants={PAGE_TRANSITION}
        exit="out"
        animate="in"
        initial="out"
        className="backgroundStyle">
        <Helmet>
        {this.seoService.getCanonicalLink(`/search/${categoryId}/${request}/${page}`)}
          {this.seoService.insertHrefLangTags(
            {
              path: `/search/:categoryId/:request/:page`,
              params: {
                categoryId: categoryId,
                request: request,
                page: page,
              }

            })
          }
        </Helmet>
        {/* <Header userData={userData} language={language}/> */}
        <TopSlider
          billboards={this.state.billboards}
        />
        <Search
          currentCategory={categoryId}
          language={language}
          option={option}
        />
        {/*    */}
        <div ref={this.scrollRef}></div>
        {resultRender}


        {/* pagination */}
        <div className="paginationLine">
          <div className="paginationBody">
            <div className="pagination">
              {pagination}
            </div>
          </div>
        </div>
        <SuppliersBar language={language} />
      </motion.div>

    )
  }
}

export default withRouter(SearchPage);