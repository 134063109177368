import * as config from '../../configuration/config'
import * as servers from '../../configuration/servers'
import React from 'react';
import LoginModal from '../login-modal';
import RegisterModal from '../register-modal';
import CookiesManager from '../../services/cookies-manager';
import ServerApi from '../../services/server-api-service';
import ConfigurationService from '../../services/configuration-service';
import './login-style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'



const configurationService = new ConfigurationService();
let cookieNames = configurationService.getCurrentServer().cookieLogin;

class Login extends React.Component {
  serverApi = new ServerApi();
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      modalIsVisible: false,
      registerModalIsVisible: true,
      leftLoginMenuPosition: 0,
      country:[]
    }
    // this.closeModal = this.closeModal.bind(this);
    // this.showRegister = this.showRegister.bind(this);
  }
  componentDidMount() {
    if (this.state.debug) {
      console.log("componentDidMount: Login component...");
    }
  }

  getAllCountry = () =>{
    this.serverApi.getAllCountry()
    .then((data)=>{
      this.setState({
        ...this.state,
        country: data.country
      })
    })
    .catch((error)=>{
      console.log('Error loading country list ',error);
    })
  }

  getAllEstablishmentType = () =>{
    this.serverApi.getAllEstablishmentType()
    .then((data)=>{
      if(this.state.debug){
        console.log('Establishment type list: ',data);
      }
      this.setState({
        ...this.state,
        establishmentTypes:data.establishmentTypes,
      })
    })
    .catch((error)=>{
      console.log('Error loading establishment types',error);
    })
  }

  componentWillMount() {
    if (this.state.debug) {
      console.log("componentWillUnmount : Login component...");
    }
    this.getAllCountry();
    this.getAllEstablishmentType();
  }

  componentWillUnmount() {
    if (this.state.debug) {
      console.log("componentWillUnmount : Login component...");
    }
  }

  logOut() {
    (new CookiesManager).clearAll();
    window.location.reload(false);
  }

  // showRegister() {
  //   this.setState({
  //     registerModalIsVisible: !this.state.registerModalIsVisible,
  //     modalIsVisible: false,
  //   })
  // }

  // closeModal=()=>{
  //   this.setState({
  //     modalIsVisible: false,
  //     registerModalIsVisible: false,
  //   })
  // }

  // hideMenu = () => {
  //   this.setState({
  //     modalIsVisible: false
  //   })
  // }


  topLogin = React.createRef();

  getLoginMenuPosition = () => {
    const menuElement = this.topLogin.current.getBoundingClientRect();
    return menuElement.left - 155;
  }

  openLoginModal(){
    document.dispatchEvent(new CustomEvent('openModal',{detail:{name:"isVisibleLoginModal",value:true}}))
  }

  
  render() {
    let language = this.props.language;
    let cookiesManager = new CookiesManager();
    let flag = false;
    
    let headerLogin;
    // if (this.state.debug) {
    //   console.log('COOKIES: ' + cookies);
    // }
    if(cookieNames!==undefined&&Array.isArray(cookieNames)){
      cookieNames.map((cookie,index)=>{
        let item = cookiesManager.get(cookie);
        if(item!==undefined){
          flag=true;
        }
      })
    }
    headerLogin = (
        <div className="TopMenuMenuItem">
          <FontAwesomeIcon
              icon={faUserCircle}
              color="white"
              pull="right"
              inverse className="TopMenuIcons"
              //onClick={(() => this.setState({ modalIsVisible: !this.state.modalIsVisible }))}
              onClick={(() => this.openLoginModal())}
          />
        </div>
    )

    let userName;

    if (!flag) {
    
      userName = null;
    }
    else {
      userName = this.props.userData.gt_firstname;
    }


    // if (cookies === undefined) {
    //   headerLogin = (
    //   <div className="TopMenuMenuItem">
    //
    //   <FontAwesomeIcon
    //     icon={faUserCircle}
    //     color="white"
    //     pull="right"
    //     inverse className="TopMenuIcons"
    //     onClick={(() => this.setState({ modalIsVisible: !this.state.modalIsVisible }))}
    //   />
    //   </div>
    //   )
    // } else {
    //   headerLogin = (
    //     <div>
    //       <span>hello {this.props.userData.username} </span>
    //       <button onClick={(() => { this.logOut() })}>Log out</button>
    //     </div>)
    // }

    return (
      <div className="loginDiv" ref={this.topLogin}>
        {/* <button onClick={(() => this.setState({ modalIsVisible: !this.state.modalIsVisible }))}>Sign in</button> */}
        {headerLogin}
        <LoginModal
          userName = {userName}
          isVisible={this.props.isVisibleLoginModal}
          //showRegister = {this.showRegister}
          //hideMenu = {this.hideMenu}
          getLoginMenuPosition = {this.getLoginMenuPosition}
          logOut = {this.logOut}
          language={language}
         />
           <RegisterModal
              handleOnLogin={this.props.handleOnLogin}
              country = {this.state.country}
              establishmentTypes={this.state.establishmentTypes}
              isVisible={this.props.isVisibleRegisterModal}
              language={language}

          />
      </div>
    )
  }
}

export default Login;