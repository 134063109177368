import * as configurations from '../configuration/servers';
import * as currentConfig from '../configuration/config.json';
import * as languages from '../configuration/languages';

let debug = false;


let currentServer = configurations[currentConfig.currentServer];
let siteUrl = window.location.protocol + "//" + window.location.hostname + "/";
let siteTheme = 'lmi';
let fullSiteName ='Labmedica';


let languagePrefix;
if (siteUrl.includes('es')) {
    languagePrefix = 'es'
} else {
    languagePrefix = 'en';
}


let currentLanguage = languages[languagePrefix];
localStorage.setItem('lang', languagePrefix);

if (debug) {
    console.log(currentLanguage);
}

// if (siteUrl.includes('axis')) {
//     if (siteUrl.includes('.es')) {
//         currentServer = configurations["axisEs"];
//     } else {
//         currentServer = configurations["axis"];
//     }

// } else if (siteUrl.includes('onserv')) {
//     if (siteUrl.includes('.es')) {
//         currentServer = configurations["onservEs"];
//         siteUrl = configurations["onservEs"].serverApi;
//     } else {
//         currentServer = configurations["onserv"];
//         siteUrl = configurations["onserv"].serverApi;
//     }

// } else if (siteUrl.includes('track21')) {
//     currentServer = configurations["track"];

// }
// else {
//     if (siteUrl.includes('.es')) {
//         currentServer = configurations["prodEs"];
//     } else {
//         currentServer = configurations["prod"];
//     }
// }

console.log(configurations["onservLabmedica"].serverApi)
if(siteUrl.includes('hospimedica')){
    if (siteUrl.includes('axis')) {
        if (siteUrl.includes('.es')) {
            currentServer = configurations["axisEsHospimedica"];
            siteUrl = configurations["axisEsHospimedica"].serverApi;
        } else {
            currentServer = configurations["axisHospimedica"];
            siteUrl = configurations["axisHospimedica"].serverApi;
        }
    
    } else if (siteUrl.includes('onserv')) {
        if (siteUrl.includes('.es')) {
            currentServer = configurations["onservEsHospimedica"];
            siteUrl = configurations["onservEsHospimedica"].serverApi;
        } else {
            currentServer = configurations["onservHospimedica"];
            siteUrl = configurations["onservHospimedica"].serverApi;
        }
    
    } else if (siteUrl.includes('track21')) {
        currentServer = configurations["trackHospimedica"];
    
    }
    else {
        if (siteUrl.includes('.es')) {
            currentServer = configurations["prodEsHospimedica"];
        } else {
            currentServer = configurations["prodHospimedica"];
        }
    }
}else{
    if (siteUrl.includes('axis')) {
        if (siteUrl.includes('.es')) {
            currentServer = configurations["axisEsLabmedica"];
            siteUrl = configurations["axisEsLabmedica"].serverApi;
        } else {
            currentServer = configurations["axisLabmedica"];
            siteUrl = configurations["axisLabmedica"].serverApi;
        }
    
    } else if (siteUrl.includes('onserv')) {
        if (siteUrl.includes('.es')) {
            currentServer = configurations["onservEsLabmedica"];
            siteUrl = configurations["onservEsLabmedica"].serverApi;
        } else {
            currentServer = configurations["onservLabmedica"];
            siteUrl = configurations["onservLabmedica"].serverApi;
        }
    
    } else if (siteUrl.includes('track21')) {
        currentServer = configurations["trackLabmedica"];
    
    }
    else {
        if (siteUrl.includes('.es')) {
            currentServer = configurations["prodEsLabmedica"];
        } else {
            currentServer = configurations["prodLabmedica"];
        }
    }
    console.log( 'CurrentServer: ',currentServer)
}


export default class ConfigurationService {

    defineTheme(){
        if(document.location.href.includes('.hospimedica.')) {
            siteTheme = 'hmi';
            fullSiteName ='Hospimedica'
        }
        else siteTheme = 'lmi';
        return siteTheme;
    }   

    getSiteTheme(){
        return siteTheme;
    }

    getFullSiteName(){
        return fullSiteName;
    }

    getCurrentServer() {
        if (debug) {
            console.log(currentServer);
        }
        return currentServer;
    }

    getCurrentSite() {
        return siteUrl;
    }

    getCurrentLanguage() {
        return languages[localStorage.getItem('lang')];
    }

    switchLanguage(lang) {
        switch (lang) {
            case 'en': {
                localStorage.setItem('lang', 'en');
                break;
            }
            case 'es': {
                localStorage.setItem('lang', 'es');
                break;
            }
            default: {
                localStorage.setItem('lang', 'en');
                break;
            }
        }
    }

    getCurrentLanguagePrefix() {
        return languagePrefix;
    }

    insertLinksToHead(prev, next) {
        try {

            // if (document.getElementById("canonical") != null) {
            //     document.getElementById("canonical").remove();
            // }
            if (document.getElementById("alternate") != null) {
                document.getElementById("alternate").remove();
            }
            if (document.getElementById("alternate") == null 
            
                // || document.getElementById("canonical") == null
                ) {
                // const seoLinksCan = document.createElement('link');
                // seoLinksCan.setAttribute('rel', 'canonical');
                // seoLinksCan.setAttribute('id', 'canonical');
                // seoLinksCan.setAttribute('href', document.URL);
                // document.head.appendChild(seoLinksCan);

                const seoLinksAlt = document.createElement('link');
                seoLinksAlt.setAttribute('rel', 'alternate ');
                seoLinksAlt.setAttribute('id', 'alternate');
                seoLinksAlt.setAttribute('href', document.URL.replace('//www.', '//mobile.'));
                document.head.appendChild(seoLinksAlt);

            }
            if (document.getElementById("prev") != null) {
                document.getElementById("prev").remove();
            }
            if (document.getElementById("next") != null) {
                document.getElementById("next").remove();
            }
            if (prev) {
                const seoLinksPrev = document.createElement('link');
                seoLinksPrev.setAttribute('rel', 'prev');
                seoLinksPrev.setAttribute('id', 'prev');
                seoLinksPrev.setAttribute('href', prev);
                document.head.appendChild(seoLinksPrev);
            }
            if (next) {
                const seoLinksNext = document.createElement('link');
                seoLinksNext.setAttribute('rel', 'next')
                seoLinksNext.setAttribute('id', 'next');
                seoLinksNext.setAttribute('href', next);
                document.head.appendChild(seoLinksNext);
            }
            if (document.getElementById('productApplactionLd') !== null && !document.URL.includes('/product/')) {
                document.getElementById('productApplactionLd').remove();
            }
        } catch (error) {
            console.log(error);
        }
    }
}

