import React from 'react';
import ReactDOM from 'react-dom';
import './product-status-style.css';
//custom copmonents
//style
class ProductStatus extends React.Component {
    render() {
        // let {StatusNewDiv,StatusPreOwnedDiv}='';
        // if(this.props.isFeatured===true){
        //     StatusNewDiv = 'StatusNewDivFeatured';
        //     StatusPreOwnedDiv = 'StatusPreOwnedDivFeatured';
        // } else{
        //     StatusNewDiv = 'statusNew';
        //     StatusPreOwnedDiv = 'statusPreOwned';
        // }
        const conditions = this.props.conditions;
        const isNewlyCreated = this.props.isNewlyCreated;
        let conditionsRender = null;
        let isNewlyCreatedRender = null;
        let language = this.props.language;

        if (isNewlyCreated=='1') {
            isNewlyCreatedRender = (
                <div className="productStatus productIsNew">
                    {language.NEW||""}
                </div>
            )
        }
        

       if (conditions == 2) {
            //pre-owned
            conditionsRender = (
                <div className="productStatus productStatusPreOwned">
                    {language.PRE_OWNED ||""}
                </div>
            )
        } else {
            conditionsRender = (null);
        }
        // if(this.props.condition === 1){
        //     return(
        //         <div className="productStatus productStatusNew">
        //                 New
        //         </div>
        //     )
        // }else if(this.props.conditions == 2){
        //     return(
        //         <div className="productStatus productStatusPreOwned">
        //                 pre-owned
        //         </div>
        //     )
        // } else{
        //     return null;
        // }
        return (
            <div className="productStatusDiv">
                {isNewlyCreatedRender}
                {conditionsRender}
            </div>
        )
    }

}


export default ProductStatus;