import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  useParams
} from "react-router-dom";
import ProductStatus from '../product-status';
import RequestInformation from '../request-information';
import RequestPrice from '../request-price';
import parse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faVideo, faBolt } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';

import './product-page-description-style.css';
import FirstClickInquiry from '../first-click-inquiry/first-click-inquiry';
class ProductPageDescription extends React.Component {
  constructor(props) {
    super(props);
    this.сhangeIsVisible = this.сhangeIsVisible.bind(this);
    this.state = {
      debug: false,
      currentImgIndex: 0,
      currentImgUrl: null,
      modalInfoIsVisible: false,
      modalPriceIsVisible: false,
      currentVideoIndex: null,
      currentVideo: null,
    }
  }



  componentDidMount() {
    if (this.state.debug) {
      console.log("componentDidMount: ProductPageDescription component...");
    }

    if (this.props.productData.images !== undefined) {
      this.setState({
        images: this.props.productData.images,
        currentImgUrl: this.props.productData.images[0],
        currentImgIndex: 0,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.debug) {
      console.log("componentDidUpdate: ProductPageDescription component...");
    }
    if (this.props.productData.images !== this.state.images) {
      this.setState({
        images: this.props.productData.images,
        currentImgUrl: this.props.productData.images[0] || null,
        currentImgIndex: 0,
      })
    }

  }



  parseCategoryName(name) {
    var pattern = new RegExp("[^a-zA-Z0-9]+", "g");
    let parsedName = (name.replace(pattern, '-')).toLowerCase();
    return parsedName;
  }

  сhangeIsVisible() {
    this.setState({
      modalInfoIsVisible: false,
      modalPriceIsVisible: false
    })
  }

  switchImage(index, image) {
    this.setState({
      currentImgIndex: index,
      currentImgUrl: image,
      currentVideoIndex: null,
      currentVideo: null,
    })
  }

  switchVideo(video) {
    this.setState({
      currentImgIndex: null,
      currentImgUrl: null,
      currentVideoIndex: video.id,
      currentVideo: video,
    })
  }
  // ShowModalLogin(){
  //   this.setState({
  //     modalInfoIsVisible:false,
  //     modalPriceIsVisible: false
  //   })
  // }

  // ShoeModalRegister(){
  //   this.setState({
  //     modalInfoIsVisible:false,
  //     modalPriceIsVisible: false
  //   })
  // }

  render() {
    let language = this.props.language;
    const { id, specifications, name, model, product_model, is_newly_created,
      conditions, features, product_description, description,
      images, documents, product_categories, certificates, title, videos } = this.props.productData;

    const { userData, productData } = this.props;
    // Parse HTML from text data:

    let parsed_description = "",
      parsed_name = "",
      parsed_specification = "",
      parsed_model = "",
      parsed_features = "";

    const parse = require('html-react-parser');

    if (product_description || description) {
      parsed_description = parse(product_description || description);
    }

    if (name || title) {
      parsed_name = parse(name || title);
    }

    if (specifications) {
      parsed_specification = parse(specifications);
    }

    if (model || product_model) {
      parsed_model = parse(model || product_model);
    }

    if (features) {
      parsed_features = parse(features);
    }

    let tags_list;
    if (product_categories !== undefined) {
      tags_list = product_categories.map((tag, index) => {
        let name = this.parseCategoryName(tag.category_name);
        if (tag.category_id == 7) {
          return (
            <span key={tag.category_id} >[
              <Link className="descriptionTags" key={tag.category_id} to={`/`} >{tag.category_name}</Link>
              ],
            </span>
          )
        }

        if (index !== product_categories.length - 1) {
          return (
            <span key={tag.category_id} >[
              <Link className="descriptionTags" key={tag.category_id} to={`/category/` + tag.category_id + "/" + name} >{tag.category_name}</Link>
              ],
            </span>
          );
        } else {
          return (
            <span key={tag.category_id}>[
              <Link className="descriptionTags" key={tag.category_id} to={`/category/` + tag.category_id + "/" + name} >{tag.category_name}</Link>
              ]
            </span>
          );
        }
      });
    }

    let list_for_switch;
    //render switcher with image and videos
    if (images !== undefined && videos !== false) {
      list_for_switch = images.map((image, index) => {
        if (index == 0) {
          //render first image and others videos
          if (index === this.state.currentImgIndex) {
            return (
              <React.Fragment key={index}>
                <div key={index}>
                  <img className="currentImageSwitch" src={image}></img>
                </div>
                {videos.map((video, indexVideo) => {
                  if (video.id === this.state.currentVideoIndex) {
                    return (
                      <div key={video.id}>
                        <img className="currentImageSwitch" src={`http://img.youtube.com/vi/${video.youtube_id}/mqdefault.jpg`}></img>
                      </div>
                    )
                  } else {
                    return (
                      <div key={video.id} onClick={() => { this.switchVideo(video) }}>
                        <img className="imageSwitch" src={`http://img.youtube.com/vi/${video.youtube_id}/mqdefault.jpg`}></img>
                      </div>
                    )
                  }
                })}
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={index}>
                <div key={index} onClick={() => { this.switchImage(index, image) }}>
                  <img className="imageSwitch" src={image}></img>
                </div>
                {videos.map((video, indexVideo) => {
                  if (video.id === this.state.currentVideoIndex) {
                    return (
                      <div key={video.id}>
                        <img className="currentImageSwitch" src={`http://img.youtube.com/vi/${video.youtube_id}/mqdefault.jpg`}></img>
                      </div>
                    )
                  } else {
                    return (
                      <div key={video.id} onClick={() => { this.switchVideo(video) }}>
                        <img className="imageSwitch" src={`http://img.youtube.com/vi/${video.youtube_id}/mqdefault.jpg`}></img>
                      </div>
                    )
                  }
                })}
              </React.Fragment>
            )
          }
        } else {
          //render others images
          if (index === this.state.currentImgIndex) {
            return (
              <div key={index}>
                <img className="currentImageSwitch" src={image}></img>
              </div>
            )
          } else {
            return (
              <div key={index} onClick={() => { this.switchImage(index, image) }}>
                <img className="imageSwitch" src={image}></img>
              </div>
            )
          }
        }
      })


    } else

      //switcher only images
      if (images !== undefined && images.length >= 2) {
        list_for_switch = images.map((image, index) => {
          if (index === this.state.currentImgIndex) {
            return (
              <div key={index}>
                <img className="currentImageSwitch" src={image}></img>
              </div>
            )
          } else {
            return (
              <div key={index} onClick={() => { this.switchImage(index, image) }}>
                <img className="imageSwitch" src={image}></img>
              </div>
            )
          }
        })
      }

    let documentsRender;
    if (documents) {
      documentsRender = (
        <div className="descriptionFeatures">
          <div className="descriptionBookmark">
            {language.DOCUMENTS}
          </div>
          <div className="descriptionDescrText">
            {
              documents.map((doc, index) => {
                let fileExtension;
                if (doc.file.includes('.pdf')) {
                  fileExtension = (
                    <FontAwesomeIcon
                      className="descriptionNameIcon"
                      icon={faFilePdf}
                      color="#494949"
                    />
                  );
                } else {
                  fileExtension = (
                    <FontAwesomeIcon
                      className="descriptionNameIcon"
                      icon={faFileImage}
                      color="#494949"
                    />
                  );
                }
                return (
                  <div key={index} className="descriptionDocumentsItem">
                    {doc.title}
                    {fileExtension} <a href={doc.url_to_file} target="_blank">{language.VIEW_DOWNLOAD}</a>
                  </div>
                );
              })
            }
          </div>
        </div>
      )
    }

    let specificationRender;

    if (parsed_specification && (parsed_specification !== '') && (parsed_specification !== ' ')) {
      specificationRender = (
        <div className="descriptionFeatures">
          <div className="descriptionBookmark">
            {language.SPECIFICATION}
          </div>
          <div className="descriptionDescrText">
            {parsed_specification}
          </div>
        </div>
      )
    }

    let featuresRender;
    if (parsed_features && (parsed_features !== '') && (parsed_features !== ' ')) {
      featuresRender = (
        <div className="descriptionFeatures">
          <div className="descriptionBookmark">
            {language.FEATURES}
          </div>
          <div className="descriptionDescrText">
            {parsed_features}
          </div>
        </div>
      )
    }

    let certificationRender;
    const expoLogo = require('../../elements/images/cert_bgr.gif');
    if (certificates) {
      certificationRender = (<div className="descriptionFeatures">
        <div className="descriptionBookmark">
          {language.CERTIFICATES}
        </div>
        <div className="descriptionCertificateList">
          {
            certificates.map((cert, index) => {
              let urlToFile = null;
              if (cert.url_to_file) {
                urlToFile = (
                  <a href={cert.url_to_file} target="_blank">View</a>
                )
              }
              return (
                <div className="certBorder" style={{ backgroundImage: "url(" + expoLogo + ")", backgroundRepeat: 'no-repeat' }}>
                  <img src={cert.icon_url} />
                  {urlToFile}
                </div>
              )
            })
          }
        </div>
      </div>
      )
    }

    let describeRender;
    if (parsed_description && (parsed_description !== '') && (parsed_description !== ' ')) {
      describeRender = (
        <div className={parsed_description == "" ? "hidden" : "descriptionFeatures"}>
          <div className="descriptionBookmark">
            {language.DESCRIPTION}
          </div>
          <div className="descriptionDescrText">
            {parsed_description}
          </div>
        </div>
      )
    }

    let renderMainVideoOrImage;
    if (this.state.currentImgIndex !== null) {
      renderMainVideoOrImage = (
        <img className="descriptionImage" src={this.state.currentImgUrl} />
      )
    } else if (this.state.currentVideo !== null) {
      renderMainVideoOrImage = (
        <iframe width="100%" height="400px" src={`https://www.youtube.com/embed/${this.state.currentVideo.youtube_id}`}>
        </iframe>
      )
    }


    return (
      <div className="productDescriptionLine">
        <div className="productDescription" id="productDescription">

          {/* Product status */}
          <ProductStatus
            conditions={conditions}
            isFeatured={false}
            isNewlyCreated={is_newly_created}
            language={language}
          />
          {/* end Product status */}
          {/* productNameDescription */}
          <div className="descriptioNameBody">
            <div className="descriptionName">
              {parsed_name}	&nbsp;
            </div>
            <div className={parsed_model != '' ? "descriptionModel" : 'hidden'}>
              {language.MODEL}: {parsed_model}
              {/* vidoe icon */}
              {videos ?
                <FontAwesomeIcon
                  className="descriptionNameIcon"
                  icon={faVideo}
                  color="#494949"
                />
                :
                null
              }

              {/* documents icon */}
              {
                documents || certificates ?
                  <FontAwesomeIcon
                    className="descriptionNameIcon"
                    icon={faFile}
                    color="#494949"
                  />
                  :
                  null
              }
            </div>

            {/* <div className="contactUsbutton" onClick={(() => { this.setState({ modalInfoIsVisible: !this.state.modalInfoIsVisible }) })}>
              {this.props.language.QUICK_INQUIRY}
            </div> */}
            {userData.id == null ?
              (
                <div className="descriptionReqButton contactUsbutton" onClick={(() => { this.setState({ modalInfoIsVisible: !this.state.modalInfoIsVisible }) })}>
                  <div className="leftLine"></div>

                  <FontAwesomeIcon
                    className="descriptionReqIcon"
                    icon={faBolt}
                    color="white"
                  />  {language.QUICK_INQUIRY}
                </div>

              ) : (
                <div className='firstClickBlock'> 
                  <FirstClickInquiry
                    productData={productData}
                    userData={userData}
                    language={language}
                  />
                </div>)}



          </div>

          {/* End productNameDescription */}

          {/* Image and descriprion */}
          <div className="descriptionImageAndDescr">
            <div className="descriptionImages">
              <div className="descriptionImageBox">

                {/* image or video */}
                {renderMainVideoOrImage}
              </div>
              {/* Image and video switch */}
              <div className="descriptionImageSwitch">
                {list_for_switch}
              </div>
              {/* end Image switch */}
            </div>
            <div className="descriptionDescrBody">
              {/* <div className="descriptionBookmark">
                {language.DESCRIPTION}
              </div>
              <div className="descriptionDescrText">
                {parsed_description}
              </div> */}
              {describeRender}
              {/* request buttons */}
              <br />


              <FirstClickInquiry
                productData={productData}
                userData={userData}
                language={language}
              />

              <div className="descriptionReqButton" onClick={(() => { this.setState({ modalInfoIsVisible: !this.state.modalInfoIsVisible }) })}>
                <div className="leftLine"></div>

                <FontAwesomeIcon
                  className="descriptionReqIcon"
                  icon={faInfo}
                  color="white"
                />  {language.REQUEST_INFORMATION}
              </div>
              <div className="descriptionReqButton" onClick={(() => { this.setState({ modalPriceIsVisible: !this.state.modalPriceIsVisible }) })}>
                <div className="leftLine"></div>
                <FontAwesomeIcon
                  className="descriptionReqIcon"
                  icon={faDollarSign}
                  color="white"
                />
                {language.REQUEST_PRICE}
              </div>
              <br />
              {/* end request buttons */}
            </div>
          </div>
          {/* end Image and descriprion */}


          {/* Features */}
          {featuresRender}
          {/* end Features */}

          {/* specification */}
          {specificationRender}
          {/* end specification */}
          {certificationRender}
          {documentsRender}
          {/* tags */}
          <br />
          {
            ((parsed_features !== "" && parsed_features !== " ") || (parsed_specification !== "" && parsed_specification !== " ")) ? (
              <>
                <FirstClickInquiry
                  productData={productData}
                  userData={userData}
                  language={language}
                />
                <div className="descriptionReqButton" onClick={(() => { this.setState({ modalInfoIsVisible: !this.state.modalInfoIsVisible }) })}>
                  <div className="leftLine"></div>
                  <FontAwesomeIcon
                    className="descriptionReqIcon"
                    icon={faInfo}
                    color="white"
                  />  {language.REQUEST_INFORMATION}
                </div>
                <div className="descriptionReqButton" onClick={(() => { this.setState({ modalPriceIsVisible: !this.state.modalPriceIsVisible }) })}>
                  <div className="leftLine"></div>
                  <FontAwesomeIcon
                    className="descriptionReqIcon"
                    icon={faDollarSign}
                    color="white"
                  />
                  {language.REQUEST_PRICE}
                </div>
              </>
            ) : (
              <>
              </>
            )

          }
          <br />
          <div className="descriptionTagsList">
            {language.TAGS}: {tags_list}
          </div>
          {/* end tags */}
        </div>
        <RequestInformation
          quickInquiry={false}
          productData={this.props.productData}
          userData={this.props.userData}
          companyData={this.props.companyData}
          modalIsVisible={this.state.modalInfoIsVisible}
          сhangeIsVisible={this.сhangeIsVisible}
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          language={language}
          handleOnLogin={this.props.handleOnLogin}
        />

        <RequestPrice
          companyData={this.props.companyData}
          productData={this.props.productData}
          userData={this.props.userData}
          modalIsVisible={this.state.modalPriceIsVisible}
          сhangeIsVisible={this.сhangeIsVisible}
          country={this.props.country}
          establishmentTypes={this.props.establishmentTypes}
          language={language}
          handleOnLogin={this.props.handleOnLogin}
        />

      </div>

    )
  }
}

export default ProductPageDescription;