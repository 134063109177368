
const PAGE_TRANSITION = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    }
}
export default PAGE_TRANSITION;
  