import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Search from '../search';
import ConfigurationService from '../../services/configuration-service';
import './not-found-page-style.css';
import { motion } from 'framer-motion';
import PAGE_TRANSITION from '../../configuration/framer-configs/page-transition';

function NotFoundPage(props) {
    let { language } = props;
    const configurationService = new ConfigurationService();
    let mainCategoryId = 7;
    if (configurationService.getSiteTheme() === 'hmi') mainCategoryId = 0;

    return (
        <motion.div
            variants={PAGE_TRANSITION}
            exit="out"
            animate="in"
            initial="out"
            className='PageNotFound'>
            <h1 className='Title'> {language.PAGE_NOT_FOUND}</h1>

            <Search
                currentCategory={mainCategoryId}
                language={language}
            />
            {/* <Link className='BackButton' to={'/'}>
                &#8592; {language.MAIN_WEBSITE.toUpperCase()}
            </Link> */}

        </motion.div>
    )
}

export default NotFoundPage