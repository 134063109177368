import React from 'react';
//custom copmonents
import './suppliers-bar-style.css';
import Supplier from '../supplier';
import ServerApi from '../../services/server-api-service';
import Slider from "react-slick";
import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';

//style
class SuppliersBar extends React.Component {
  serverApi = new ServerApi();
  state = {
    debug:false,
    suppliers: []
  }

  constructor(props) {
    super(props);

  }

  // getProducts() {
  //   const suppliers_url = "http://www.labmedica.com.onserv/expo/test_json/suppliers.json";
  //   //const suppliers_url = "https://www.labmedica.com.axis1.net/expo/test_json/suppliers.json";
  //   axios.get(suppliers_url)
  //     .then(res => {
  //       const suppliers = res.data;
  //       this.setState({ suppliers });
  //     })

  // }

  getPremiumSupliers=()=>{
    this.serverApi.getPremiumSupliers(8)
    .then((res)=>{
      if(this.state.debug){
        console.log("getPremiumSupliers: ",res);

      }
      this.setState({
        suppliers:res.premium_suppliers
      })
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  componentDidMount() {
    //this.getProducts();
    this.getPremiumSupliers();
  }




  render() {

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };

    let counter = 0;
    let suppliers_list = null;
    if(this.state.suppliers!=undefined){
      suppliers_list= this.state.suppliers.map((supplier) => {
      if(counter<5){
      counter++;
      return (
        <Supplier supplier={supplier}  key={supplier.id} />
      );
      }
    });
    }else{
    }
    return (

        <div className = "suppliersBarLine">
          <div className = "suppliersBarBody" id="suppliersBarBody">
          <h2 className ="suppliersBarLogo">
            {this.props.language.PREMIUMSUPPLIERS}
          </h2>
          <div className = "suppliersBarItems">
          <Slider {...settings}>
            {suppliers_list}
           </Slider>
          </div>
          </div>
        </div>
    )
  }

}


export default SuppliersBar;