import React from 'react';
import './supplier-status-style.css';

class SupplierStatus extends React.Component {
    render(){
        let language = this.props.language;
        const { rang, origin } = this.props;

        if (origin !== "company") {
            if(rang==4){
                return(
                    // <div className="productBorder">
                        <div className ="supplierStatus statusPlatinum">
                            {language.PLATINUM_SUPPLIER||""}
                        </div>
                    // </div>
                )
            }
            else if (rang==3) {
                return(
                    // <div className="productBorder">
                        <div className="supplierStatus satusGold">
                            {language.GOLD_SUPPLIER||""}
                        </div>
                    // </div>
                )
            }else if(rang == 2 ){
                return(
                        <div className="supplierStatus satusSilver">
                            {language.SILVER_SUPPLIER||""}
                        </div>
                )
                // return(
                //     <div className="productBorder productBorderNoStatus">
                //     </div>
                // )
            }else return null;
        }

        else {
            if(rang==4){
                return(
                        <div className ="supplierStatus statusPlatinum">
                            {language.PLATINUM_SUPPLIER||""}
                        </div>
                )
            }
            else if (rang==3) {
                return(
                        <div className="supplierStatus satusGold">
                            {language.GOLD_SUPPLIER||"" }
                        </div>
                )
            }else if (rang==2) {
                return(
                        <div className="supplierStatus satusSilver">
                            {language.SILVER_SUPPLIER||"" }
                        </div>
                )
            }else return null;
        }
    }
}

export default SupplierStatus;