import React from 'react';
import ServerApi from '../../services/server-api-service';
import { withRouter } from 'react-router-dom';
import Product from '../product';
import './company-products-style.css';
class CompanyProducts extends React.Component {
  serverApi = new ServerApi();
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
    }
    this.onClickMoreProducts = this.onClickMoreProducts.bind(this)
  }

  // getProducts(){
  //   const category_url = "http://www.labmedica.com.onserv/expo/test_json/companyProducts.json";
  //   axios.get(category_url)
  //     .then(res => {
  //       const products = res.data;
  //       this.setState({products});
  //     })

  // }

  componentDidMount() {
    // console.log(this.props);
    //   if(this.state.debug){
    //     console.log("componentDidMount: CompanyProducts component...");
    //   }
    //   console.log(this.props.companyData);
    //   if(this.props.morePproducts!=undefined && this.state.products==undefined){
    //     // this.requestProducts(this.props.companyData.id);
    //     this.setState({
    //       products:this.props.morePproducts
    //     })
    //   }
  }

  componentDidUpdate() {
    if (this.state.debug) {
      console.log("componentDidUpdate: CompanyProducts component...");
    }
    ////console.log(this.state);
    // if(this.props.morePproducts!=undefined && this.state.products==undefined){
    //   // this.requestProducts(this.props.companyData.id);
    //   this.setState({
    //     products:this.props.morePproducts
    //   })
    // }
  }

  // requestProducts(id){
  //   //console.log(id);
  //   this.serverApi.getCompanyProducts(id)
  //     .then((res)=>{
  //     //console.log(res);
  //     this.setState({
  //       products:res.company_products
  //     })
  //   })
  //   .catch((error)=>{
  //     console.log(error);
  //   })
  // }
  onClickMoreProducts(event) {
    this.props.history.push(`/company/${this.props.companyData.id}/products`);
  }

  render() {
    let counter = 0;
    let products_list;
    let id;
    if (this.props.companyData) {
      id = this.props.companyData.id;
    }
    if (this.props.products) {
      products_list = this.props.products.map((product, index) => {
        if (counter++ < 8) {
          return (
            <Product
              companyData={this.props.companyData}
              userData={this.props.userData}
              isFeatured={false}
              key={index}
              data={product}
              showLogo={false}
              language={this.props.language}
              handleOnLogin={this.props.handleOnLogin}
            />
          );
        } else {
          return null
        }
      });
    }

    let body = null;
    if (this.props.products != undefined && this.props.products.length > 0) {
      body = (
        <div className="productsLine">
          <div className="productsBodyBoxed">
            <div className="productsGrid">
              {products_list}
              <div className="empProduct"></div>
              <div className="empProduct"></div>
              <div className="empProduct"></div>
            </div>
            <div onClick={this.onClickMoreProducts} className="moreProductsButton">
              {this.props.language.MORE_PRODUCTS}
            </div>
          </div>
        </div>
      );
    } else {
      body = null;
    }
    return (
      <>
        {body}
      </>
    )
  }

}

export default withRouter(CompanyProducts);