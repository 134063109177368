import * as config from '../../configuration/config.json'
import * as servers from '../../configuration/servers'
import React from 'react';

import ReactDOM from 'react-dom';
import './login-modal-style.css';
import ServerApi from '../../services/server-api-service'
import ConfigurationService from '../../services/configuration-service';
import RegisterModal from '../register-modal';
import {Link} from "react-router-dom";
import {motion } from 'framer-motion';
import HEADER_TRANSITION from '../../configuration/framer-configs/header-transitions'

const siteUrl = servers[config.currentServer].serverApi;

class
    LoginModal extends React.Component {
    serverApi = new ServerApi();
    configurationService = new ConfigurationService();

    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            user: {
                login: '',
                passw: ''
            },
            leftLoginMenuPosition: 0
        }
        this.handleChangePass = this.handleChangePass.bind(this);
        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.state.debug) {
            console.log("componentDidMount: LoginModal component...");
        }
        window.addEventListener('resize', this.updateLoginMenuPositionOnResize);
    }

    componentWillUnmount() {
        if (this.state.debug) {
            console.log("componentWillUnmount : LoginModal component...");
        }
        window.removeEventListener('resize', this.updateLoginMenuPositionOnResize);
    }


    componentDidUpdate() {
        if (this.state.debug) {
            console.log("componentDidUpdate: LoginModal component...");
        }
        if (this.props.getLoginMenuPosition ){
            if ((this.state.leftLoginMenuPosition !== this.props.getLoginMenuPosition())) {
                this.setState({
                    leftLoginMenuPosition: this.props.getLoginMenuPosition()
                })
            }
        }
    }


    handleChangeLogin(event) {
        this.setState({
            user: {
                ...this.state.user,
                login: event.target.value
            }
        });
    }


    handleChangePass(event) {
        this.setState({
            user: {
                ...this.state.user,
                passw: event.target.value
            }
        });
    }
    handleSubmit(event) {
        this.serverApi.loginUser(this.state.user)
            .then((response) =>
                response.json()
            )
            .then((result) => {
                console.log(result);
                if (result.state) {
                    alert('Hello ' + result.data.username);
                    window.location.reload(false);
                } else {
                    alert(result.error);
                }
            });
        event.preventDefault();
    }


    updateLoginMenuPositionOnResize = () => {
        this.setState({ leftLoginMenuPosition: this.props.getLoginMenuPosition() });
    };

    closeModal(){
        document.dispatchEvent(new CustomEvent('closeAllModals'))
    }

    openRegisterModal(){
        document.dispatchEvent(new CustomEvent('openModal',{detail:{name:"isVisibleRegisterModal",value:true}}))
    }

    render() {
        let language = this.props.language;
        let { hideMenu, userName, logOut } = this.props;
        let { leftLoginMenuPosition } = this.state;

        let dropDown, dropDownContent;

        const facebookBtn = require('../../elements/images/login-with-facebook-big.png');
        const linkedinBtn = require('../../elements/images/login-with-linkedin-big.png');

        if (userName!== null&&userName!==undefined) {
            dropDownContent = (
                    <div id ="login">
                      <div className="helloText">{language.HELLO} {userName} </div>
                      <div className="buttonsDiv">
                        <a href={`${this.configurationService.getCurrentSite()}index.php?option=com_settings`} className="loginInputButton loginInputButtonRegister">{language.MY_DETAILS}</a>
                        <a className="loginInputButton loginInputButtonRegister" onClick={(() => { logOut() })}>{language.LOGOUT}</a>
                        </div>
                    </div>
            );
        }
        else {
            dropDownContent = (
                <div id="register">
                        <form onSubmit={this.handleSubmit}>
                            <h3>{language.SIGNIN}:</h3>
                            <input className="loginInput" type="text" placeholder={language.LOGIN} value={this.state.user.login} onChange={this.handleChangeLogin} />
                            <input className="loginInput" type="password" placeholder={language.PASSWORD} value={this.state.user.passw} onChange={this.handleChangePass} />
                            <input className="loginInputButton" type="submit" value={language.ENTER} />
                            <button className="loginInputButton loginInputButtonRegister" onClick={() => { 
                                this.openRegisterModal();
                                
                                }}>{language.REGISTER_FOR_FREE}</button>
                        </form>

                        <h3 className="or">{language.OR}</h3>                    <a href={`/fb-login?to_social_network=1`}><img src={facebookBtn} className="socialButton" /></a>
                        <a href={`/linkedin-login?to_social_network=1`}><img src={linkedinBtn} className="socialButton" /></a>
                    </div>
            );
        }

        dropDown = (
            <motion.div 
             variants={HEADER_TRANSITION}
            exit="out"
            animate="in"
            initial="out"
            className="dropDawnMenuBgr"
                 onClick={() => this.closeModal()}
            >
                <div
                    className="loginModal"
                    id="loginModal"
                    style={{ left: leftLoginMenuPosition }}
                    onClick={(e) => { e.stopPropagation(); }}
                >
                    {dropDownContent}
                </div>
            </motion.div>
        );

        if (this.props.isVisible) {
            return dropDown
        } else {
            return null
        }
    }
}

export default LoginModal;