import React from 'react';
import { withRouter } from 'react-router-dom';
import ServerApi from '../../services/server-api-service';
import ConfigurationService from '../../services/configuration-service';
import './search-style.css';
import SelectAutosize from '../select-autosize';
import { ThemeContext } from '../../elements/themes/themeContext';

// import Select from 'react-select';
class Search extends React.Component {

  configurationService = new ConfigurationService();
  state = {
    debug: false,
    search: '',
    categories: [],
    selected_category: 0,
    selected_category_name: 'All',
    category_selector_width: 200,
    covid:false
  }

  elementRef = React.createRef();
  catSelectRef = React.createRef();


  handleChange = this.handleChange.bind(this);
  handleSubmit = this.handleSubmit.bind(this);

  handleCategoryChange = this.handleCategoryChange.bind(this);

  serverApi = new ServerApi();

  handleChange(event) {
    this.setState({ search: event.target.value });
  }

  

  handleSubmit(event) {
    let covid=0;
    if(document.location.pathname.includes('/covid-19')||document.location.pathname.includes('/covid19')){
      covid=1;
    }
    console.log(covid);
    if (this.state.search != null && this.state.search != "" && this.state.search != undefined) {
      if(covid==0){
        this.props.history.push(`/search/${this.state.selected_category}/${this.state.search}/1`);
      }else{
        this.props.history.push(`/search/${this.state.selected_category}/${this.state.search}/1/covid19`);
      }
    } else {
      event.preventDefault();
    }
  }

  parseCategoryName(name) {
    var pattern = new RegExp("[^a-zA-Z0-9]+", "g");
    let parsedName = (name.replace(pattern, '-')).toLowerCase();
    return parsedName;
  }

  handleCategoryChange(event) {
    this.setState({ selected_category: event.target.value });
    this.setState({ selected_category_name: event.target.options[event.target.selectedIndex].text })
    let name = this.parseCategoryName(event.target.options[event.target.selectedIndex].text);
    //main page 0 (hmi 7)
    console.log(event.target.value)
    if(event.target.value == 0 || event.target.value == 7) this.props.history.push(`/`)
    else this.props.history.push(`/category/${event.target.value}/${name}`);
  
  }

  getCategories = () =>{
    let categoriesId = 7;
    if(this.configurationService.getSiteTheme()==='hmi') categoriesId = 0;
    this.serverApi.getCategories(categoriesId).
      then((res) => {
        //console.log(res);
        this.setState({
          categories: res.categories,
          selected_category: this.props.currentCategory
        })

      })
      .catch((error) => {
        console.log(error);
      })

    if (this.state.debug) {
      console.log('------ Search componentDidMount End -----: ');
    }
  }

  componentDidMount() {

    if (this.state.debug) {
      console.log('------ Search componentDidMount Begin -----: ');
    }

    if(this.props.currentCategory == 0 || this.props.currentCategory == 7) this.setState({selected_category_name:this.context.language.ALL})

    this.getCategories();
    // const category_url = "https://testtrademed.s3.eu-central-1.amazonaws.com/cat.json";
    // axios.get(category_url)
    //   .then(res => {
    //     const categories = res.data;
    //     this.setState({ categories });
    //   })

  }

  componentDidUpdate(prevProps) {
    if (this.state.debug) {
      console.log('------ Search componentDidUpdate Begin -----: ');
    }


    if (this.props.currentCategory !== prevProps.currentCategory) {
      this.setState({
        selected_category: this.props.currentCategory
        //category_selector_width: this.elementRef.current.offsetWidth + 20
      });
    }

    if(this.props.currentCategory !== prevProps.currentCategory ){
      
    }

    if (this.elementRef.current !== null) {
      if (this.state.category_selector_width !== this.elementRef.current.offsetWidth + 36) {
        this.setState({
          category_selector_width: this.elementRef.current.offsetWidth + 36
        });
      }
    }


    if (this.state.debug) {
      console.log('------ Search componentDidUpdate End -----: ');
    }

  }
  renderCategoriesList = () => {
    const context = this.context;
    let {categories,selected_category_name,selected_category,category_selector_width} = this.state;
    let {language,option=null,currentCategory} = this.props;

    if(currentCategory &&categories && categories.length != 0){
      if((document.location.pathname!=='/expo/' && document.location.pathname!=='/expo/not_found')
      // !(document.location.pathname.includes('/expo/search/7/')&&
      // !document.location.pathname.includes('/covid19'))
      ){
        if(((currentCategory && (this.state.categories.length != 0))||document.location.pathname.includes('/covid19'))){
          if (this.state.debug) {
            console.log('Categories array');
            console.log(categories);
          }
    
          let categories_list = null;
          if(categories){

            categories_list= categories.map((category, index) => {
              if (currentCategory == category.category_id) {
                
                if (selected_category_name !== category.category_name) {
                  
                  this.setState({
                    selected_category_name: category.category_name
                  });
                }
              }
              return (
                <>
                  {/* add search list covid-10 category... */}
                  {/* {index == 0 && context.siteTheme !== 'hmi' ? (
                    <option className="category" key={7} value={7}>
                      {language.COVID}
                    </option>
                  ) : (null)} */}
                  

                  {index == 0 ? (
                    <option className="category" key={context.siteTheme !== 'hmi' ? 7 : 0} value={context.siteTheme !== 'hmi' ? 7 : 0}>
                      {language.ALL}
                    </option>
                  ) : (null)} 

                  <option className="category" key={index + category.category_name} value={category.category_id}>
                    {category.category_name}
                  </option>
                  {category.subcategories && Array.isArray(category.subcategories)?(
                      category.subcategories.map((subcategory, subIndex)=>{

                          if (currentCategory == subcategory.category_id) {
                            if (selected_category_name !== subcategory.category_name) {
                              this.setState({
                                selected_category_name: subcategory.category_name
                              });
                            }
                          }

                        return(
                          <option className="subcategory" key={subIndex + subcategory.category_name} value={subcategory.category_id}>
                          &nbsp;&nbsp;&nbsp;&nbsp;{subcategory.category_name}
                          </option>
                        )
                      })
                    ):(null)}
                </>
              );
            });


          }
    
          return (
            <React.Fragment>
              <div
                ref={this.elementRef}
                className="findCategorySelectorSize"
              >{selected_category_name}
              </div>
              <select
                ref={this.catSelectRef}
                className="categorySelector"
                //defaultValue={selected_id}
                value={selected_category}
                onChange={this.handleCategoryChange}
                style={{ width: category_selector_width }}
              // autoSize = "true"
              >
                {/* <div>123123123</div> */}
                {categories_list}
              </select>
            </React.Fragment>
            // <SelectAutosize items={categories_list} />
          );
        }
      }
    }
    // if ((((this.props.currentCategory && (this.state.categories.length != 0))||
    // (document.location.pathname.includes('/covid19')))&&document.location.pathname!=='/expo/'&&(
    // (document.location.pathname.includes('/expo/search/7/'))))) {
    //   if (this.state.debug) {
    //     console.log('Categories array');
    //     console.log(this.state.categories);
    //   }

    //   let categories_list = this.state.categories.map((category, index) => {

    //     if (this.props.currentCategory === category.category_id) {
    //       if (this.state.selected_category_name !== category.category_name) {
    //         this.setState({
    //           selected_category_name: category.category_name
    //         });
    //       }
    //     }
    //     return (
    //       <>
    //         {/* add search list covid-10 category... */}
    //         {index == 0 ? (
    //           <option className="category" key={7} value={7}>
    //             {language.COVID}
    //           </option>
    //         ) : (null)}

    //         <option className="category" key={index} value={category.category_id}>
    //           {category.category_name}
    //         </option>
    //       </>
    //     );
    //   });

    //   return (
    //     <React.Fragment>
    //       <div
    //         ref={this.elementRef}
    //         className="findCategorySelectorSize"
    //       >{this.state.selected_category_name}
    //       </div>
    //       <select
    //         ref={this.catSelectRef}
    //         className="categorySelector"
    //         //defaultValue={selected_id}
    //         value={this.state.selected_category}
    //         onChange={this.handleCategoryChange}
    //         style={{ width: this.state.category_selector_width }}
    //       // autoSize = "true"
    //       >
    //         {/* <div>123123123</div> */}
    //         {categories_list}
    //       </select>
    //     </React.Fragment>
    //     // <SelectAutosize items={categories_list} />
    //   );
    // }
    // else {
    //   return null;
    // }

  }

  renderSlogan = () => {

    
    if (document.location.pathname !== '/expo/') {
      return (
        <div className="slogan">{this.props.language[`MAIN_PAGE_SEARCH_LOGO_${this.configurationService.getSiteTheme().toUpperCase()}`]}</div>
      );
    }
    else return null;
  }

  renderCategoryClass = () => {
    if (this.props.currentCategory !== undefined) {
      return 'searchBoxWithCategory';
    }
    else return null;
  }

  static contextType = ThemeContext;

  render() {

    let language = this.props.language;
    if (this.state.debug) {
      console.log("this.props.currentCategory:: " + this.props.currentCategory);
      console.log("this.state.selected_category:: " + this.state.selected_category);
      console.log("this.state.selected_category_name:: " + this.state.selected_category_name);
    }

    return (
      <div className={'searchBoxLine ' + this.renderCategoryClass()}>
        <div className="searchBody">
          <div className="searchForm">
            {this.renderSlogan()}
            <form className="searchBox" onSubmit={this.handleSubmit}>
              <div className="searchInput">
                {this.renderCategoriesList()}
                <div className="inputAndButton">
                  <input onChange={this.handleChange} type="text" placeholder={language.SEARCH_PLACEHOLDER} />
                  <div className="searchButton"><button>{language.SEARCH}</button></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Search);