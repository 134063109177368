import React from 'react';
import ReactDOM from 'react-dom';
import './categories-list-style.css';
import ServerApi from '../../services/server-api-service';
import axios from 'axios';
import ConfigurationService from '../../services/configuration-service';
import { ThemeContext } from '../../elements/themes/themeContext';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { withRouter } from "react-router";


class CategoriesList extends React.Component {
  serverApi = new ServerApi();
  configurationService = new ConfigurationService();
  state = {
    categories: [],
    currentCategoryList: [],
    currentCategoryId: 0
  }

  parseCategoryName(name) {
    var pattern = new RegExp("[^a-zA-Z0-9]+", "g");
    let parsedName = (name.replace(pattern, '-')).toLowerCase();
    return parsedName;
  }

  getCategoriesList = () => {
    let categoriesId = 7;
    if (this.configurationService.getSiteTheme() === 'hmi') categoriesId = 0;
    if(this.props.currentCategory) categoriesId=this.props.currentCategory;
    this.serverApi.getCategories(categoriesId).
      then((res) => {
        //console.log(res);

        this.setState({
          categories: res.categories,
          currentCategoryList: res.categories
        })
      })
      .catch((error) => {
        console.log(error);
      })

  }

  componentDidUpdate(prevProps){
    if(this.props.currentCategory != prevProps.currentCategory){
      this.getCategoriesList();
    }
  }
  componentDidMount() {
    this.getCategoriesList();
    // const category_url = "https://testtrademed.s3.eu-central-1.amazonaws.com/cat.json";
    // axios.get(category_url)
    //   .then(res => {
    //     const categories = res.data;
    //     this.setState({ categories });
    //   })
  }

  static contextType = ThemeContext;
  render() {
    let {language,width} = this.props;
    const context = this.context;
    const covidImage = require('../../elements/images/covid_19_ch.jpg');
    const topImage = require('../../elements/images/top.svg');

    let { categories, currentCategoryId, currentCategoryList } = this.state;

    let categories_list = '';

    //context.siteTheme !== 'hmi'
      //LMI VERSION
      categories_list = categories.map((category, index) => {
        let category_name = this.parseCategoryName(category.category_name);
        let covid = this.parseCategoryName(language.COVID);
        // if (this.props.currentCategory!==category.category_id) {
        //   return (

        //     <div className="categoryDiv" key={index}>
        //       <Link className="cat egoryText" to={`/category/` + category.category_id + "/" + category_name} >{category.category_name}</Link>
        //     </d>
        //   );
        // } else {
        //   return (
        //     <div className="categoryCurrentDiv" key={index}>
        //       <Link className="categoryCurrentText" to={`/category/` + category.category_id + "/" + category_name} >{category.category_name}</Link>
        //     </div>
        //   );
        // }
        let charLimit = 33;
        if(width < 1130)  charLimit = 30;
        if(width < 1030)  charLimit = 27;
        if(width < 950)  charLimit = 24;
        let parsedCategoryName = category.category_name;
        if(category.category_name.length >charLimit) parsedCategoryName = category.category_name.substring(0,charLimit) +' ...'

        return (
          <>
            {/* {index === 0 && context.siteTheme !== 'hmi' ? (
              //covid-19 category
              <Link className="categoryDiv" to={`/category/` + 7 + "/" + covid} key={index * 100} style={{
                backgroundImage: `url(${covidImage})`
              }}>
                <Link className="categoryText" to={`/category/` + 7 + "/" + covid} >{language.COVID}</Link>
              </Link>

            ) : (null)} */}

            { index === 0 &&this.props.currentCategory != null && context.siteTheme === 'hmi' ? (
              
              <Link className="categoryDiv categoryTop" to={`/`} key={index * 100} style={{
                backgroundImage: `url(${topImage})`
              }}>
                <Link className="categoryText" to={`/`} >
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8947 16C6.97263 16 3.78947 12.8376 3.78947 8.94118L3.78947 6.58824L-4.11401e-07 6.58824L5.68421 1.42327e-06L11.3684 6.58824L7.57895 6.58824L7.57895 8.94118C7.57895 10.7576 9.06632 12.2353 10.8947 12.2353L18 12.2353L18 16L10.8947 16Z" fill="#606062"/>
                  </svg>
                </Link>
              </Link>

            ) : (null)}
            <Link className="categoryDiv" to={`/category/` + category.category_id + "/" + category_name} key={category.category_id} style={{
              backgroundImage: `url(${category.category_box_image})`
            }}>
              <Link className="categoryText" to={`/category/` + category.category_id + "/" + category_name} >{parsedCategoryName}</Link>
            </Link>
          </>
        );
      });
    

    const category_header = () => {
      if(categories_list != ''){
        if (this.props.categoryName) {
          return (<h2>Category: {this.props.categoryName}</h2>);
        }
        else {
          return (<h2>{language.BROWSE_BY_CATEGORY}:</h2>);
        }
      }else return null;
     
    }
    
    if((context.siteTheme ==='hmi' &&  categories != null && Array.isArray(categories) && categories.length > 0  && categories[0].category_id.length>3)||
    categories.length == 0 || document.location.pathname.includes('/covid-19') )
     return null;
    else{
      return (
        <div className="CategoriesListLine">
          <div className="CategoriesList">
            {category_header()}
            <div className="CategoriesListBlock">
              {categories_list}
              <div className="empCat"></div>
              <div className="empCat"></div>
            </div>
          </div>
        </div>
      )
    }
   
  }
}

export default withRouter(CategoriesList);