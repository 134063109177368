import React from 'react';
import './register-modal-style.css';
import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { motion } from 'framer-motion';
import HEADER_TRANSITION from '../../configuration/framer-configs/header-transitions'

const QUESTIONS = require('./qestions.json');

class RegisterModal extends React.Component {
  formCheckService = new FormCheckService();
  serverApi = new ServerApi();
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      emailExist: false,
      currentStep: 1,
      emailCopy: '',
      registerForm: {
        task: "subscribe",
        'subs[print_or_digial_version]': 0,
        user_gt_firstname: '',
        user_gt_lastname: ' ',
        user_gt_company: '',
        user_gt_country: 'Afghanistan',
        user_email: '',
        user_email_repeat: '',
        subs_establishment_type: 'a',
        user_username: '',
        user_password: '',
        password_repeat: '',
        user_newsletters: false,
        //user_newsletters_switch: true,
      },
      user: {
        login: '',
        passw: ''
      }
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.debug) {
      console.log("componentDidMount: Register component...");
    }
  }

  componentWillMount() {
    this.setState({
      registerForm: {
        ...this.state.registerForm,
        user_email: this.props.email,
        user_email_repeat: this.props.email,
        user_username: this.props.email
      }
    })
  }

  componentWillUnmount() {
    if (this.state.debug) {
      console.log("componentWillUnmount : Register component...");
    }
  }

  componentWillUpdate() {
    // if ((this.props.email && this.state.registerForm.user_email === '')) {
    //   this.setState({
    //     registerForm: {
    //       ...this.state.registerForm,
    //       user_email: this.props.email,
    //       user_email_repeat: this.props.email,
    //       user_username: this.props.email
    //     }
    //   })
    // }
  }

  checkFields() {
    let language = this.props.language;
    const { user_gt_firstname, user_gt_lastname, user_gt_company, user_gt_country, user_email, user_email_repeat,
      subs_establishment_type, user_username, user_password, password_repeat, user_newsletters, user_newsletters_switch } = this.state.registerForm;

    if (this.formCheckService.checkEmptyField(user_email, 'email')) {
      return false
    }
    if (this.formCheckService.checkEmptyField(user_email_repeat, 'repeat email')) {
      return false
    }
    if (this.formCheckService.checkEmail(user_email, 'valid email')) {
      return false
    }
    if (this.formCheckService.checkEqualFields(user_email, user_email_repeat, 'Emails do not match')) {
      return false
    }

    if (this.formCheckService.checkEmptyField(user_gt_firstname, 'first name')) {
      return false
    }

    if (this.formCheckService.checkEmptyField(user_gt_company, 'company')) {
      return false
    }

    if (this.formCheckService.checkEmptyField(user_gt_country, 'country')) {
      return false
    }


    if (this.formCheckService.checkEmptyField(user_username, 'username')) {
      return false
    }

    if (this.formCheckService.checkEmptyField(user_password, 'password')) {
      return false
    }

    if (this.formCheckService.checkEmptyField(password_repeat, 'password repeat')) {
      return false
    }

    if (this.formCheckService.checkEqualFields(user_password, password_repeat, 'Passwords do not match')) {
      return false
    }

    if (this.formCheckService.checkEmptyField(subs_establishment_type, 'establishment type')) {
      return false
    }
    if (this.formCheckService.checkSwith(user_newsletters, 'newsletters')) {
      return false
    }
    return true;
  }

  closeModal = () => {
    if (this.state.currentStep !== 99) {
      if (this.props.closeModal) {
        this.props.closeModal();
      }
      document.dispatchEvent(new CustomEvent('closeAllModals', {}))
    }
  }

  handleSubmit(event) {
    if (this.checkFields()) {
      this.setState({
        currentStep: 99
      })
      this.serverApi.sendSubscriptionData(this.state.registerForm)
        .then((response) => {
          if (response.status != "error") {
            const user = {
              login: this.state.registerForm.user_username,
              passw: this.state.registerForm.user_password
            }

            this.serverApi.loginUser(user)
              .then((response) =>
                response.json()
              )
              .then((result) => {
                this.props.handleOnLogin();
                if (result.state) {
                  this.setState({
                    currentStep: 2
                  })

                } else {
                  alert(result.error);
                }
              })
              .catch((error) => {
                console.log('Error fetch with loginUser: ', error)
              })
          } else {
            alert(response.reason);
          }
          //window.location.reload(false);
        }
        );
      event.preventDefault();
    }
    event.preventDefault();
  }

  handleSubmitSecond = (event) => {
    event.preventDefault();
    if (1) {
      this.serverApi.sendSubscriptionSecondStep(this.state.registerForm)
        .then((response) => {
          if (response.status != "error") {
            if (this.props.funcAtferRegister) {
              this.props.funcAtferRegister();
            }
            if (!this.props.request) {
              this.setState({
                currentStep: 98
              })
            }
            //window.location.reload(false);
          }
        }
        );

      event.preventDefault();
    }
    event.preventDefault();
  }

  handleInputChange(event) {
    const target = event.target;
    console.log(target.value)
    const value = target.name === 'user_newsletters' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      registerForm: {
        ...this.state.registerForm,
        [name]: value
      }
    });
    if (event.target.name == 'user_email') {
      this.serverApi.checkDbEmail(event.target.value)
        .then((res) => {
          if (!res.status) {
            this.setState({
              emailExist: true
            })
          } else {
            this.setState({
              emailExist: false
            })
          }
        })
        .catch((error) => {
          console.log('Error fetch with checkDbEmail: ', error)
        })
    }
  }

  renderThank() {
    let { language, title } = this.props;
    return (
      <div className="modalShadow modalShadowLarge" onClick={this.closeModal}>
        <div className="modalWindow" onClick={((event) => { event.stopPropagation() })}>
          <div className="modalClose" onClick={this.closeModal}><span><FontAwesomeIcon icon={faWindowClose} color="white" /></span></div>

          <h2 className="modalHeader">{language.THANK}</h2>
          <div>
            {language.THANK_DESC}
          </div>
        </div>
      </div>
    )
  }

  renderFirstStep() {
    let { language, title } = this.props;
    let countryList;
    if (this.props.country) {
      countryList = this.props.country.map((element, index) => {
        return (
          <option key={element.name}>{element.name}</option>
        )
      })
    }
    let establishmentTypes
    if (this.props.establishmentTypes) {
      establishmentTypes = this.props.establishmentTypes.map((element, index) => {
        return (
          <option value={element.index} key={element.index}>{element.desc}</option>
        )
      })
    }

    return (<div className="modalShadow modalShadowLarge" onClick={this.closeModal}>
      <motion.div
        variants={HEADER_TRANSITION}
        exit="out"
        animate="in"
        initial="out"
        className="modalWindow"
        onClick={((event) => { event.stopPropagation() })}>
        <div className="modalClose" onClick={this.closeModal}><span><FontAwesomeIcon icon={faWindowClose} color="white" /></span></div>

        <h2 className="modalHeader">{title ? (title) : (language.REGISTER_FORM_HEADER)}</h2>
        <form onSubmit={this.handleSubmit} id="register">
          <div className="formBlock">
            <div className="registerFormLine">
              <label>{language.EMAIL}</label>
              <input required name="user_email" onBlur={() => {
                this.setState({
                  registerForm: {
                    ...this.state.registerForm,
                    user_username: this.state.registerForm.user_email
                  }
                })
              }
              } onChange={this.handleInputChange} type="text" value={this.state.registerForm.user_email} />
              <span className={this.state.emailExist ? '' : 'inputHidden'}>{language.EMAIL_EXIST}</span>
            </div>
            <div className={this.state.registerForm.user_email ? '' : 'inputHidden'}>
              <label>{language.RE_EMAIL}</label>
              <input required name="user_email_repeat" className={this.state.registerForm.user_email ? '' : 'inputHidden'} onChange={this.handleInputChange} type="text" onChange={this.handleInputChange} value={this.state.registerForm.user_email_repeat} />
            </div>
            <div>
              <label>{language.NAME}</label>
              <input required name="user_gt_firstname" onChange={this.handleInputChange} type="text" value={this.state.registerForm.user_gt_firstname} />
            </div>
            {/* <tr>
            <td>Last name *</td>
            <td><input name="user_gt_lastname" onChange={this.handleInputChange} type="text" value={this.state.registerForm.user_gt_lastname} /></td>
          </tr> */}
            <div>
              <label>{language.COMPANYNAME}</label>
              <input required name="user_gt_company" onChange={this.handleInputChange} type="text" value={this.state.registerForm.user_gt_company} />
            </div>
            <div>
              <label>{language.COUNTRY}</label>
              <select name="user_gt_country" onChange={this.handleInputChange}>
                {countryList}
                {/*
                <option>Belarus</option>
                <option>United States</option>
                */}
              </select>
            </div>
            <div>
              <label>{language.USERNAME}</label>
              <input required name="user_username" onChange={this.handleInputChange} type="text" value={this.state.registerForm.user_username} />
            </div>
            <div>
              <label>{language.PASSWORD_REG} </label>
              <input required name="user_password" onChange={this.handleInputChange} type="password" value={this.state.registerForm.user_password} />
            </div>
            <div className={this.state.registerForm.user_password ? '' : 'inputHidden'}>
              <label>{language.RE_PASSWORD}</label>
              <input required name="password_repeat" onChange={this.handleInputChange} type="password" value={this.state.registerForm.password_repeat} />
            </div>
            <div>
              <label>{language.SPECIFY}</label>
              <select name="subs_establishment_type" onChange={this.handleInputChange}>
                {establishmentTypes}
              </select>

              {/* <input name="subs_establishment_type" onChange={this.handleInputChange} type="text" value={this.state.registerForm.subs_establishment_type} /> */}
            </div>
            <div className="modalCheck">
              <input name="user_newsletters" onChange={this.handleInputChange} type="checkbox" value={this.state.registerForm.user_newsletters} />
              <label>{language.I_READ} <a href="https://www.labmedica.com/?option=privacy" target="_blank">&nbsp;{language.PRIVACE_POLICY}</a> {language.WANT_TO_RECEIVE}</label>
            </div>
            <input className="sendButton" type="submit" value="Register" form="register" />
          </div>
        </form>
      </motion.div>
    </div>)
  }

  renderItem = (item) => {
    switch (item.type) {
      case "input": {
        return (
          <div key={item.name}>
            <label>{item.title}</label>
            <input required={item.require} name={item.name} onChange={this.handleInputChange} type="text" value={this.state[item.name]} />
          </div>
        )
      }
      case "select": {
        if (this.state.registerForm[item.name] === undefined) {
          this.setState({
            registerForm: {
              ...this.state.registerForm,
              [item.name]: item.options[0].value
            }
          })
        }
        return (
          <div key={item.name} >
            <label>{item.title}</label>
            <select name={item.name} onChange={this.handleInputChange} value={this.state[item.name]} >
              {item.options.map((option, index) => {
                return (
                  <option key={option.text + option.value} value={option.value}>{option.text}</option>
                )
              })}
            </select>
          </div>)

      }
      case "radio": {
        return (
          <div key={item.name}>
            <label>{item.title}</label>
            {item.replies.map((option, index) => {
              return (
                <div key={item.name + option.value}>
                  <input id="radio" type="radio" value={option.value} name={item.name} />{option.text}
                </div>
              )
            })}
          </div>)
      }
    }
  }

  renderLoading() {
    let processingIcon = require('../../elements/images/processing.png');

    return (

      <div className="modalShadow modalShadowLarge" onClick={this.closeModal}>
        <div className="modalWindow" onClick={((event) => { event.stopPropagation() })}>
          <div>
            <img src={processingIcon} alt='process' className="Process" />
          </div>
        </div>
      </div>

    )
  }



  renderSecondStep() {
    let { language, title } = this.props;
    let countryList;
    if (this.props.country) {
      countryList = this.props.country.map((element, index) => {
        return (
          <option key={index}>{element.name}</option>
        )
      })
    }
    let establishmentTypes
    if (this.props.establishmentTypes) {
      establishmentTypes = this.props.establishmentTypes.map((element, index) => {
        return (
          <option value={element.index} key={index}>{element.desc}</option>
        )
      })
    }




    return (
      <div className="modalShadow modalShadowLarge" onClick={this.closeModal}>
        <div className="modalWindow" onClick={((event) => { event.stopPropagation() })}>
          <div className="modalClose" onClick={this.closeModal}><span><FontAwesomeIcon icon={faWindowClose} color="white" /></span></div>

          <h2 className="modalHeader">{title ? (title) : (language.REGISTER_FORM_HEADER)}</h2>
          <form onSubmit={this.handleSubmitSecond} id="register" onChange={this.handleInputChange}>
            <div className="formBlock">

{/* CHECK */}
              <div>
              <label>{language.PHONE}</label>
              <input required name="user_gt_phone" onChange={this.handleInputChange} type="text" value={this.state.registerForm.user_gt_phone} />
            </div>


              <div className="firstPart">
                {QUESTIONS.firstPart.map((item) => {
                  return (this.renderItem(item));
                })}
              </div>
              <div className="secondPart">
                {QUESTIONS.secondPart.map((item) => {
                  return (this.renderItem(item));
                })}
              </div>
              <input className="sendButton" type="submit" value="Register" form="register" />
            </div>
          </form>
        </div>
      </div>
    )
  }

  render() {
    let language = this.props.language;
    let countryList;
    if (this.props.country) {
      countryList = this.props.country.map((element, index) => {
        return (
          <option key={index}>{element.name}</option>
        )
      })
    }
    let establishmentTypes
    if (this.props.establishmentTypes) {
      establishmentTypes = this.props.establishmentTypes.map((element, index) => {
        return (
          <option key={element.index}>{element.desc}</option>
        )
      })
    }

    if (this.props.isVisible) {
      switch (this.state.currentStep) {
        case 1: {
          return (this.renderFirstStep());
        }
        case 2: {
          return (this.renderSecondStep());
        }
        case 98: {
          return (this.renderThank());
        }
        case 99: {
          return (this.renderLoading());
        }
        default: {
          return null;
        }
      }

    } else {
      return null;
    }

  }
}

export default RegisterModal;