import ConfigurationService from '../services/configuration-service';

const configurationService = new ConfigurationService();
let siteUrl =configurationService.getCurrentSite();
let lang  = configurationService.getCurrentLanguagePrefix();
// let siteUrl = servers[config.currentServer].serverApi;
// console.log(config.currentServer);
let journal_code = 'lmi';
if(lang=='es'){
  journal_code="lme"
}
// if(config.currentServer!="onserv"){
//   siteUrl = window.location.protocol+"//"+window.location.hostname+"/";
// }
const debug = false;
// const siteUrl = 'http://www.labmedica.com.onserv/';
// const siteUrl = 'https://www.labmedica.com.axis1.net/';
function transformDataForAPI(dataIn, dataType = 'loginForm', updateData = false) {
  if (dataType === 'loginForm' && dataIn) {
    if (!updateData) {
      let dataOut = {
        "task": "subscribe",
        "user": {
          "gt_firstname": dataIn.user_gt_firstname,
          "gt_lastname": dataIn.user_gt_lastname,
          "gt_company": dataIn.user_gt_company,
          "gt_country": dataIn.user_gt_country,
          "email": dataIn.user_email,
          "username": dataIn.user_username,
          "password": dataIn.user_password,
          "newsletters": dataIn.user_newsletters,
        },
        "subs": {
          "establishment_type": dataIn.subs_establishment_type
        }
      }
      return dataOut;
    }
    else {
      let dataOut = {
        "task": "fbupdate",
        "time_stamp": dataIn.time_stamp,
        "code": dataIn.code,
        "user": {
          "gt_company": dataIn.gt_company,
          "gt_country": dataIn.gt_country,
        },
        "subs": {
          "establishment_type": dataIn.establishment_type
        }
      }

      return dataOut;
    }
  }else if(dataType==='loginSecondStep'){
    let dataOut = {
      "user": {
        "gt_company":dataIn['user[gt_company]'],
        "gt_department":dataIn['user[gt_department]'],
        "gt_address":dataIn['user[gt_address]'],
        "gt_city":dataIn['user[gt_city]'],
        "gt_postcode":dataIn['user[gt_postcode]'],
        "gt_phone":dataIn['user[gt_phone]'],
      },
      "subs": {
        "function":dataIn['subs[function]'],
        "is_dr_med":dataIn['subs[is_dr_med]'],
        "field":dataIn['subs[field]'],
        "print_or_digial_version" :dataIn['subs[print_or_digial_version]']
      }
    }
    return dataOut;
  }

  
}


export default class ServerApi {

  //_apiBase = 'https://www.hospimedica.com/index.php?option=com_mobile_app&task=';
  // _apiBase = '/index2.php?option=login&ajax=';
  _apiBase = 'index2.php?option=';
  _optionLogin = 'login';
  _optionBase = 'com_mobile_app';
  _option = '?option='
  _apiV3 = 'api/v3/';
  _apiV4 = 'api/v4/';
  _task = 'task=';
  _lang = `&lang=${lang}`;

  async getData(task_name,param='') {

    if (debug) {
      console.log('Featching url - >> ', `${siteUrl}${this._apiBase}${this._optionBase}${task_name}${this._lang}${param}`);
    }

    const res = await fetch(`${siteUrl}${this._apiBase}${this._optionBase}&task=${task_name}${this._lang}${param}`, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Credentials': '*'
      },
      credentials: 'include',
    });

    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}${task_name}${this._lang}` +
        `, received ${res.status}`)
    }
    return await res.json();
  }

  async sendData(task_name, objectWithData) {

    if (this.debug) {
      console.log('JSON to send: ');
      console.log(JSON.stringify(objectWithData));
      alert(`${siteUrl}${this._apiBase}${this._optionBase}&task=${task_name}${this._lang}`);
    }
    let res;
    res = await fetch(`${siteUrl}${this._apiBase}${this._optionBase}&task=${task_name}${this._lang}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': '*'
      },
      body: JSON.stringify(objectWithData),
    });



    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}${this._optionBase}$task=${task_name}${this._lang}` +
        `, received ${res.status}`)
    }
    return await res.json();
  }



  async loginUser(userData) {
    const ajax = 'true'
    const loginForExpo = 'true';
    if (debug) {
      console.log('JSON to send: ');
      console.log(JSON.stringify(userData));
    }

    const res = await fetch(`${siteUrl}${this._apiBase}${this._optionLogin}&ajax=${ajax}&login_for_expo=${loginForExpo}${this._lang}`, {

      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: `username=${userData.login||userData.email}&passwd=${userData.passw}`,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      credentials: 'same-origin',
    });

    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}${ajax}` +
        `, received ${res.status}`)
    } 
    return await res;
  }

  async getUserData(data) {

    // const res = await this.getData('get_user_info',`${data}`);

    const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_user_info${this._lang}${data}`, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        // 'Access-Control-Allow-Credentials': '*'
      },
      // credentials: 'include',
    });

    if (!res.ok) {
      throw new Error(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_user_info${this._lang}${data}` +
        `, received ${res.status}`)
    }
    return await res.json();

    return res;
  }


  async requestInformation(tm_request) {
    
    var res;
      res = await fetch(`${siteUrl}${this._apiV3}${this._task}make_tm_request${this._lang}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({tm_request})
      });


    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiV3}make_tm_request${this._lang}` +
        `, received ${res.status}`)
    }
    return await res;
  }

  //    

  async sendSubscriptionData(subscriptionData) {
    // Article API sample 
    //http://www.hospimedica.com.onserv/index.php?option=com_mobile_app&task=make_subscription


    if (debug) {
      console.log('Got subscription data to send to server: ', subscriptionData);
    }

    let dataToSend = transformDataForAPI(subscriptionData);

    if (debug) {
      console.log('Data transformed to: ', dataToSend);
    }
    //const res = await this.sendData(`make_subscription`, dataToSend);
    
    var res;
    res = await fetch(`${siteUrl}${this._apiV3}${this._task}make_subscription`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(dataToSend)
    });
    // res = true;
    //let res;

    // res = await fetch(`http://www.labmedica.com.onserv/?option=com_mobile_app&task=return_data`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Credentials':'*'
    //   },
    //   body: JSON.stringify(dataToSend),
    // });
    return res;
  }

  async sendSubscriptionSecondStep(data){
    //update_user_and_subs

    let dataToSend = transformDataForAPI(data,'loginSecondStep');

    if (debug) {
      console.log('Data transformed to: ', JSON.stringify(dataToSend));
    }
    //const res = await this.sendData(`make_subscription`, dataToSend);
    
    let res;
    res = await fetch(`${siteUrl}${this._apiV3}${this._task}update_user_and_subs`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(dataToSend)
    });

    return res.json();
  }

  async getCategories(categoryId,exclude) {
    //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=get_tm_categories&lang=en&parent_id=0
    let excludeString = '';
    if(exclude != null){
      excludeString = `&exclude=${exclude}`
    }
    const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_tm_categories&lang=en&parent_id=${categoryId}${this._lang}${excludeString}`, {
      // credentials: 'include',
    });

    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}get_tm_categories${this._lang}` +
        `, received ${res.status}`)
    }
    return await res.json();
  }


  async getCategoryProducts(categoryId = 0,page,limit=40,exclude=null,covid=0){
    let res;
    if(exclude!=null){
      if(exclude.companyRank==4||exclude.companyRank==3||exclude.companyRank==2){
        res = await fetch(`${siteUrl}${this._option}${this._optionBase}&task=get_tm_categories&parent_id=${categoryId}&type=products&page=${page}&limit=${limit}${this._lang}&exclude_ids[]=${exclude.productId}&only_covid_19=${covid}`,{});
      }else{
        res = await fetch(`${siteUrl}${this._option}${this._optionBase}&task=get_tm_categories&parent_id=${categoryId}&type=products&page=${page}&limit=${limit}${this._lang}&exclude_company_ids[]=${exclude.companyId}&only_covid_19=${covid}`,{});
      }
     
    }else{
      res = await fetch(`${siteUrl}${this._option}${this._optionBase}&task=get_tm_categories&parent_id=${categoryId}&type=products&page=${page}&limit=${limit}${this._lang}&only_covid_19=${covid}`,{});
    }
  
    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}get_tm_categories${this._lang}` +
        `, received ${res.status}`)
    }
    return await res.json();
  }

  async getProductInfo(productId){
    const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_tm_product&tm_product_id=${productId}${this._lang}`,{

    });
    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}get_tm_categories${this._lang}` +
        `, received ${res.status}`)
    }
    return await res.json();
  }
  async getCompanyProducts(companyId){
    const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_tm_company&tm_company_id=${companyId}&type=products${this._lang}`,{

    });
    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}get_tm_categories` +
        `, received ${res.status}`)
    }
    return await res.json();
  }
  async checkDbEmail(email){
    const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=check_med_email&email=${email}${this._lang}`,{

    });
    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}get_tm_categories${this._lang}` +
        `, received ${res.status}`)
    }
    return await res.json();
  }

  async getPremiumSupliers(limit){
    //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=get_tm_premium_suppliers&limit=8
    const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_tm_premium_suppliers&limit=${limit}${this._lang}`,{

    });
    return await res.json();
  }

  async getProductsForMain(){
    //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=get_tm_products_for_main_page
    const res = await fetch(`${siteUrl}${this._apiV4}${this._option}${this._optionBase}&task=get_tm_products_for_main_page${this._lang}&need_trending_products=16`,{

    });
    return await res.json();
  }

  async search(categoryId,request,page,limit,exclude=null,covid=0){
    let res;

    if(exclude!=null){
      if(exclude.companyRank==4||exclude.companyRank==3||exclude.companyRank==2){
        res = await fetch(`${siteUrl}${this._apiV4}${this._option}${this._optionBase}&task=search_tm_products&parent_id=${categoryId}&search=${encodeURIComponent(request)}&limit=${limit}&page=${page}${this._lang}&exclude_ids[]=${exclude.productId}&only_covid_19=${covid}`,{});
      }else{
        res = await fetch(`${siteUrl}${this._apiV4}${this._option}${this._optionBase}&task=search_tm_products&parent_id=${categoryId}&search=${encodeURIComponent(request)}&limit=${limit}&page=${page}${this._lang}&exclude_company_ids[]=${exclude.companyId}&only_covid_19=${covid}`,{});
      }
     
    }else{
      res = await fetch(`${siteUrl}${this._apiV4}${this._option}${this._optionBase}&task=search_tm_products&parent_id=${categoryId}&search=${encodeURIComponent(request)}&limit=${limit}&page=${page}${this._lang}&only_covid_19=${covid}`,{

      });
    }
    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}get_tm_categories${this._lang}` +
        `, received ${res.status}`)
    }
    //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=search_tm_products&lang=en&parent_id=7&search=erba med&limit=10&page=1
   
    return await res.json();
  }

  async getAllCountry(){
    //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=get_countries
    const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_countries${this._lang}`,{});
    
    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_countries${this._lang}` +
        `, received ${res.status}`)
    }

    return await res.json();
  }

  async getAllEstablishmentType(){
    //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=get_establishment_types&journal_code=lmi
    const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_establishment_types&journal_code=${journal_code}`,{});
    
    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_establishment_types&journal_code=${journal_code}` +
        `, received ${res.status}`)
    }
    return await res.json();
  }
  async getBillboards(companyId = null,rand=1,limit=6){
    //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=get_tm_billboards&order_rand=1&limit=6
    var res;
    console.log(companyId);
    if(companyId==null){
      res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_tm_billboards&order_rand=1&limit=6${this._lang}`,{});
    }else{
      //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=get_tm_billboards&company_ids=8373&limit=6
      res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_tm_billboards&company_ids=${companyId}&order_rand=${rand}&limit=${limit}${this._lang}`,{});
    }

    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_tm_billboards&company_ids=${companyId}&order_rand=${rand}&limit=${limit}${this._lang}` +
        `, received ${res.status}`)
    }

    return await res.json();
  }

  async getRecentlyViewed(){
    //http://www.labmedica.com.onserv/api/v3/?option=com_mobile_app&task=get_recently_viewed
     const res = await fetch(`${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_recently_viewed${this._lang}&need_recently_viewed=3`,{});
    
     if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiV3}${this._option}${this._optionBase}&task=get_recently_viewed${this._lang}&need_recently_viewed=3` +
        `, received ${res.status}`)
    }

     return await res.json();
  }

  async getNewsData(id){
    //http://www.hospimedica.com.onserv/index.php?option=com_mobile_app&task=get_article&article_id=294775348
  //const res  = await fetch(`http://www.hospimedica.com/index.php?option=com_mobile_app&task=get_article&article_id=${id}`);
    const res  = await fetch(`${siteUrl}${this._apiBase}${this._optionBase}&task=get_article&article_id=${id}&response=html`);
    if (!res.ok) {
      throw new Error(`Could not fetch ${siteUrl}${this._apiBase}${this._optionBase}&task=get_article&article_id=${id}&response=html` +
        `, received ${res.status}`)
    }
    
    return await res.json();
  }

  async getVersion(){
    //https://www.labmedica.com.axis1.net/expo/
    let url = siteUrl;
    if(window.location.href.includes('onserv')) url ='https://www.labmedica.com.axis1.net/';
    const res = await fetch(`${url}expo/version.json?timestamp=${Date.now()}`)
    if (!res.ok) {
        throw new Error(`Could not fetch getVersion ${res.status}`)
    }
    let result = await res.json()
    return result;
}
}