import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

  import './footer-style.css';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
  import ConfigurationService from '../../services/configuration-service';
  import { ThemeContext } from '../../elements/themes/themeContext';

class Footer extends React.Component {
    configurationService = new ConfigurationService();
    static contextType = ThemeContext;
    render() {
      const context = this.context;
      let year = new Date().getFullYear();
      let siteName = 'labmedica.com';

      let siteUrlLabmedica = 'https://www.labmedica.com/';
        if(this.configurationService.getCurrentLanguagePrefix()==='es'){
          siteUrlLabmedica = 'https://www.labmedica.es/';
          siteName = 'labmedica.es'
        }
        if(context.siteTheme ==='hmi'){
          siteUrlLabmedica = 'https://www.hospimedica.com/';
          siteName = 'hospimedica.com'
          if(this.configurationService.getCurrentLanguagePrefix()==='es'){
            siteUrlLabmedica = 'https://www.hospimedica.es/';
            siteName = 'hospimedica.es'
          }
        }
      
        let additionalClass ='';
        if(document.location.pathname.includes('/not_found')){
          additionalClass += 'FooterNotFound'
        }

        return (
            <div className={`Footer ${additionalClass}`}>
              <div className="FooterCont">
                <div className="linkBack">
                  <FontAwesomeIcon icon={faArrowCircleLeft} color="white" /> {this.props.language.BACK_TO} <a href={siteUrlLabmedica}>{siteName}</a> {this.props.language.MAIN_WEBSITE}
                  {/* <FontAwesomeIcon icon={faArrowCircleLeft} color="white" /> Back to  <Link to={`/`}>{back}</Link> main website */}
                </div>
                <div className="copyR">
                  {/* Copyright &copy; 2000-{year} Globetech Media. All rights reserved. */}
                  {this.props.language.COPYRIGHT}
                  <br></br>
                  <a href={`${siteUrlLabmedica}advertising`}>{this.props.language.ADVERTISE}</a>
                </div>
               
            </div>
            </div>
          )
    }
}

export default Footer;