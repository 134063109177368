import React from 'react';
import Slider from "react-slick";
import ServerApi from '../../services/server-api-service';
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";

import './top-slider-style.css';
import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';
class TopSlider extends React.Component {
  state = {
    debug: false,
    loaded: false
  }
  serverApi = new ServerApi();

  componentDidMount() {
    this.setState({
      loaded: true
    })
  }

  render() {
    const BillbordEmpty = require('../../elements/images/billbord_empty.jpg');

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
    let billboardsRender;
    if (this.props.billboards) {
      billboardsRender = this.props.billboards.map((element, index) => {
        return (
          <div key={element.company_id} className="sliderSlide">
            <Link to={`/company/${element.company_id}/products`} href="#"><img className="bannerImg" src={element.billboard_image_url}></img></Link>
          </div>
        );
      })
    }
    return (
      <div className="SliderLine" >
        <div className="SliderBody" id="TopSlider">
        <Slider {...settings}>
            {this.state.loaded && billboardsRender != null ? (
               billboardsRender 
            ) : (
              <div key={'Billbord MEpty'} className="sliderSlide">
                <img className="bannerImg" src={BillbordEmpty}></img>
              </div>
            )}
          </Slider>


        </div>
      </div>
    );
  }
}

export default TopSlider;