import React from 'react';
import Product from '../product';
import parse from 'html-react-parser';
import './product-grid-style.css';
import { faWineGlass } from '@fortawesome/free-solid-svg-icons';

class ProductsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: true,
    }
    this.scrollRef = React.createRef();
    this.focusScrollRef = this.focusScrollRef.bind(this);
  }
  // componentDidMount(){
  //   this.getProducts(this.props.categoryId,this.props.page);
  // }

  // componentWillReceiveProps(){
  //   this.getProducts(this.props.categoryId,this.props.page);
  // }
  // getProducts(categoryId,page) {
  //   this.serverApi.getCategoryProducts(categoryId,page,this.props.limit)
  //     .then((res) => {
  //       this.setState({ products: res.category_products });
  //     })
  //     .catch((error)=>{
  //       console.log(error);
  //     })
  // }

  focusScrollRef() {
    this.scrollRef.current.focus();
  }

  render() {

    const { showLogo,nameOfList,lmProducts = false } = this.props;
    let parsedNameOfList;
    if(nameOfList){
      if(typeof nameOfList =='string'){
        parsedNameOfList = parse(nameOfList);
      }else{
        parsedNameOfList = nameOfList;
      }
    }

    let products_list;
    if (this.props.products != null && this.props.products != undefined) {

      products_list = this.props.products.map((product) => {
        if (product !== undefined) {
          return (
            <Product
              lmProduct={lmProducts}
              country={this.props.country}
              establishmentTypes={this.props.establishmentTypes}
              companyData={product.company}
              userData={this.props.userData}
              isFeatured={this.props.isFeatured}
              key={product.product_id}
              data={product}
              showLogo={showLogo}
              language={this.props.language}
              handleOnLogin = {this.props.handleOnLogin}
            />
          );
        }
      });

    } else {
      return null;
    }

    let titleRender;
    if(window.location.pathname.includes('/thank_you')){
      titleRender=(
        <>
        <div className="ThankPageTitle">
          {parsedNameOfList}
        </div>
        <br />
        </>
      );
    }else{
      titleRender=(
        <h2>{parsedNameOfList}<br /></h2>
      );
    }

    if(products_list&& Array.isArray(products_list) && products_list.length==0) return null;
  
    return (
      <div className="productsLine" ref={this.scrollRef}>
        <div className="productsBody">
          {titleRender}
          <div className="productsGrid">
            {products_list}
            <div className="empProduct"></div>
            <div className="empProduct"></div>
            <div className="empProduct"></div>
          </div>
        </div>
      </div>
    )
  }

}

export default ProductsGrid;